import React from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import "./auth.css";
import loginimg from "../Assets/Img/loginsideimg.png";
import mobile from "../Assets/Img/mail-signup.png";
import mail from "../Assets/Img/mobile-signup.png";

const clientId =
  "316679652217-44dun9ch1kjuhpmqpdda6ha5t1s3shsn.apps.googleusercontent.com";

function SignUpWith() {
  const navigate = useNavigate();

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      if (!credential) throw new Error("Google login failed");

      // Decode Google Token
      const decoded = jwtDecode(credential);
      console.log("Decoded Google User Data:", decoded);

      // Send Token to Backend API
      const response = await axios.post(
        "http://localhost:6000/api/auth/callback",
        { token: credential }, // Send token in body
        { withCredentials: true }
      );

      console.log("Full Backend Response:", response); // Log entire API response
      console.log("API Response Data:", response.data); // Log just the data

      // Check if login was successful
      if (response.status === 200 && response?.data?.status) {
        console.log("Authentication Successful:", response.data);

        // Store user data
        localStorage.setItem("user", JSON.stringify(response.data));

        // Check user role and redirect accordingly
        const userRole = response?.user?.role; // Assuming role is in response data
        if (userRole === "brand") {
          navigate("/brand-home");
        } else {
          navigate("/brand-home"); // Change this to the default home page for other roles
        }
      } else {
        console.error("Authentication Failed:", response?.data?.message);
      }
    } catch (error) {
      console.error(
        "Google Login Error:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="container-signup-with-links p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            <div className="col-12 col-md-6 col-sm-12 left">
              <img src={loginimg} alt="login-side-img" className="img" />
            </div>
            <div className="col-12 col-md-6 col-sm-12 right">
              <div className="heading">
                <div className="title">Welcome to InSocial Orbit</div>
              </div>
              <div className="buttons">
                <div className="signup-container">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() => console.error("Google login failed")}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-lg mb-2 d-flex align-items-center btn-social"
                  onClick={() => navigate("/phone")}
                >
                  <img
                    src={mobile}
                    alt="Phone Icon"
                    className="icon mr-2"
                    style={{
                      width: "17px",
                      height: "25px",
                      marginRight: "16px",
                    }}
                  />
                  Continue with Phone Number
                </button>
                <button
                  type="button"
                  className="btn btn-lg d-flex align-items-center btn-social"
                  onClick={() => alert("Email Signup Coming Soon!")}
                >
                  <img
                    src={mail}
                    alt="Email Icon"
                    className="icon mr-2"
                    style={{
                      width: "25px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Continue with Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default SignUpWith;
