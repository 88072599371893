import {
  CREATE_BRAND_PROFILE_REQUEST,
  CREATE_BRAND_PROFILE_SUCCESS,
  CREATE_BRAND_PROFILE_FAIL,
  UPDATE_PROFILE_PHOTO_REQUEST,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
  GET_BRAND_PROFILE_REQUEST,
  GET_BRAND_PROFILE_SUCCESS,
  GET_BRAND_PROFILE_FAIL,
  GET_BRAND_CONTACT_INFO_REQUEST,
  UPDATE_BRAND_PROFILE_INFO_REQUEST,
  UPDATE_BRAND_PROFILE_INFO_SUCCESS,
  UPDATE_BRAND_PROFILE_INFO_FAIL,
  UPDATE_BRAND_CONTACT_INFO_REQUEST,
  UPDATE_BRAND_CONTACT_INFO_SUCCESS,
  UPDATE_BRAND_CONTACT_INFO_FAIL,
  GET_PROFILE_DETAILS_FOR_BRAND_REQUEST,
  GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS,
  GET_PROFILE_DETAILS_FOR_BRAND_FAIL,
  UPDATE_BRAND_PROFILE_INFO_RESET,
  UPDATE_BRAND_CONTACT_INFO_RESET,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "../constants/profileConstants";

export const brandProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BRAND_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        isProfileCreated: false,
      };
    
    case GET_PROFILE_DETAILS_FOR_BRAND_REQUEST:
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case CREATE_BRAND_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfileCreated: true,
      };

    case GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS:
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        brandProfile: action.payload,
      };
    
    case CREATE_BRAND_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isProfileCreated: false,
        error: action.payload,
      };

      case GET_PROFILE_DETAILS_FOR_BRAND_FAIL:
      case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    
    case GET_BRAND_CONTACT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case UPDATE_BRAND_PROFILE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };

    case UPDATE_BRAND_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        brandInfo: action.payload,
      };

    case UPDATE_BRAND_PROFILE_INFO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case UPDATE_BRAND_PROFILE_INFO_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case UPDATE_BRAND_CONTACT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };

    case UPDATE_BRAND_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        brandContactInfo: action.payload,
      };

    case UPDATE_BRAND_CONTACT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        error: action.payload,
      };

    case UPDATE_BRAND_CONTACT_INFO_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    default:
      return state;
  }
};

export const uploadProfilePhotoReducer = (
  state = {
    // profilePhoto: state.profilePhoto || null,
    // loading: state.loading || false,
    // success: state.success || false,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PROFILE_PHOTO_REQUEST:
    case GET_BRAND_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        profilePhoto: null,
      };
    case UPDATE_PROFILE_PHOTO_SUCCESS:
    case GET_BRAND_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        profilePhoto: action.payload,
      };
    case UPDATE_PROFILE_PHOTO_FAIL:
    case GET_BRAND_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        profilePhoto: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

// export const getBrandInfoReducer = (state = {}, action) => {
//   switch (action.type) {

//     default:
//       return state;
//   }
// }

// export const getBrandContactInfoReducer = (state = {}, action) => {
//   switch (action.type) {

//     default:
//       return state;
//   }
// }
