import {
  GET_ALL_INFLUENCERS_REQUEST,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  TOGGLE_LIKE_REQUEST,
  TOGGLE_LIKE_SUCCESS,
  TOGGLE_LIKE_FAILURE,
  CLEAR_ERROR,
  GET_SEARCH_INFLUENCERS_REQUEST,
  GET_SEARCH_INFLUENCERS_SUCCESS,
  GET_SEARCH_INFLUENCERS_FAIL,
  GET_ALL_LIKED_INFLUENCERS_REQUEST,
  GET_ALL_LIKED_INFLUENCERS_SUCCESS,
  GET_ALL_LIKED_INFLUENCERS_FAIL,
  SETUP_INFLUENCER_BANK_DETAILS_REQUEST,
  SETUP_INFLUENCER_BANK_DETAILS_SUCCESS,
  SETUP_INFLUENCER_BANK_DETAILS_FAIL,
  SET_UP_INFLUENCER_PROFILE_REQUEST,
  SET_UP_INFLUENCER_PROFILE_SUCCESS,
  SET_UP_INFLUENCER_PROFILE_FAIL,
  GET_INFLUENCER_BANK_DETAILS_REQUEST,
  GET_INFLUENCER_BANK_DETAILS_SUCCESS,
  GET_INFLUENCER_BANK_DETAILS_FAIL,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL,
  SETUP_INFLUENCER_BANK_DETAILS_RESET,
  GET_INFLUENCER_PAYMENTS_REQUEST,
  GET_INFLUENCER_PAYMENTS_SUCCESS,
  GET_INFLUENCER_PAYMENTS_FAIL,
} from "../constants/influencersConstants";

export const influencersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_INFLUENCERS_REQUEST:
    case GET_SEARCH_INFLUENCERS_REQUEST:
    case GET_ALL_LIKED_INFLUENCERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_ALL_INFLUENCERS_SUCCESS:
    case GET_SEARCH_INFLUENCERS_SUCCESS:
    case GET_ALL_LIKED_INFLUENCERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        influencers: action.payload.influencers,
      };
    case GET_ALL_INFLUENCERS_FAIL:
    case GET_SEARCH_INFLUENCERS_FAIL:
    case GET_ALL_LIKED_INFLUENCERS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case TOGGLE_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        clickedInfluencer: action.payload.influencer_id,
      };
    case TOGGLE_LIKE_SUCCESS: {
      let updatedInfluencers = state.influencers.map((influencer) => {
        if (influencer.influencer_id === state.clickedInfluencer) {
          return {
            ...influencer,
            isliked: action.payload.liked,
          };
        } else return influencer;
      });
      return {
        ...state,
        loading: false,
        success: true,
        influencers: updatedInfluencers,
      };
    }
    // return {
    //   ...state,
    //   loading: false,
    //   success: true,
    //   isliked: action.payload.liked,
    // };
    case TOGGLE_LIKE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateInfluencerLikesReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case TOGGLE_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        isliked: action.payload.liked,
      };
    case TOGGLE_LIKE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const setUpInfluencerProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case SETUP_INFLUENCER_BANK_DETAILS_REQUEST:
    case SET_UP_INFLUENCER_PROFILE_REQUEST:
    case GET_INFLUENCER_BANK_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case SETUP_INFLUENCER_BANK_DETAILS_SUCCESS:
    case SET_UP_INFLUENCER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_INFLUENCER_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        bankDetails: action.payload.bankDetails,
      };
    case SETUP_INFLUENCER_BANK_DETAILS_FAIL:
    case SET_UP_INFLUENCER_PROFILE_FAIL:
    case GET_INFLUENCER_BANK_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        error: action.payload,
      };
    case SETUP_INFLUENCER_BANK_DETAILS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCampaignRequest = (state = {}, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        requests: action.payload.request,
      };
    case GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export const getInfluencerPayments = (state = {}, action) => {
  switch (action.type) {
    case GET_INFLUENCER_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_INFLUENCER_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        payments: action.payload.payments,
      };
    case GET_INFLUENCER_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}