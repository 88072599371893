export const GET_ALL_INFLUENCERS_REQUEST = "GET_ALL_INFLUENCERS_REQUEST";
export const GET_ALL_INFLUENCERS_SUCCESS = "GET_ALL_INFLUENCERS_SUCCESS";
export const GET_ALL_INFLUENCERS_FAIL = "GET_ALL_INFLUENCERS_FAIL";

export const TOGGLE_LIKE_REQUEST = 'TOGGLE_LIKE_REQUEST';
export const TOGGLE_LIKE_SUCCESS = 'TOGGLE_LIKE_SUCCESS';
export const TOGGLE_LIKE_FAILURE = 'TOGGLE_LIKE_FAILURE';

export const GET_SEARCH_INFLUENCERS_REQUEST = "GET_SEARCH_INFLUENCERS_REQUEST";
export const GET_SEARCH_INFLUENCERS_SUCCESS = "GET_SEARCH_INFLUENCERS_SUCCESS";
export const GET_SEARCH_INFLUENCERS_FAIL = "GET_SEARCH_INFLUENCERS_FAIL";

export const GET_ALL_LIKED_INFLUENCERS_REQUEST = "GET_ALL_LIKED_INFLUENCERS_REQUEST";
export const GET_ALL_LIKED_INFLUENCERS_SUCCESS = "GET_ALL_LIKED_INFLUENCERS_SUCCESS";
export const GET_ALL_LIKED_INFLUENCERS_FAIL = "GET_ALL_LIKED_INFLUENCERS_FAIL";

export const GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST= "GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST";
export const GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS = "GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS";
export const GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL = "GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const SEND_CONTRACT_TO_BRAND_REQUEST = "SEND_CONTRACT_TO_BRAND_REQUEST";
export const SEND_CONTRACT_TO_BRAND_SUCCESS = "SEND_CONTRACT_TO_BRAND_SUCCESS";
export const SEND_CONTRACT_TO_BRAND_FAIL = "SEND_CONTRACT_TO_BRAND_FAIL";
export const SEND_CONTRACT_TO_BRAND_RESET = "SEND_CONTRACT_TO_BRAND_RESET";

export const SETUP_INFLUENCER_BANK_DETAILS_REQUEST = "SETUP_INFLUENCER_BANK_DETAILS_REQUEST";
export const SETUP_INFLUENCER_BANK_DETAILS_SUCCESS = "SETUP_INFLUENCER_BANK_DETAILS_SUCCESS";
export const SETUP_INFLUENCER_BANK_DETAILS_FAIL = "SETUP_INFLUENCER_BANK_DETAILS_FAIL";
export const SETUP_INFLUENCER_BANK_DETAILS_RESET = "SETUP_INFLUENCER_BANK_DETAILS_RESET";

export const SET_UP_INFLUENCER_PROFILE_REQUEST = "SET_UP_INFLUENCER_PROFILE_REQUEST";
export const SET_UP_INFLUENCER_PROFILE_SUCCESS = "SET_UP_INFLUENCER_PROFILE_SUCCESS";
export const SET_UP_INFLUENCER_PROFILE_FAIL = "SET_UP_INFLUENCER_PROFILE_FAIL";

export const GET_INFLUENCER_BANK_DETAILS_REQUEST = "GET_INFLUENCER_BANK_DETAILS_REQUEST";
export const GET_INFLUENCER_BANK_DETAILS_SUCCESS = "GET_INFLUENCER_BANK_DETAILS_SUCCESS";
export const GET_INFLUENCER_BANK_DETAILS_FAIL = "GET_INFLUENCER_BANK_DETAILS_FAIL";

export const GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST = "GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST";
export const GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS = "GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS";
export const GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL = "GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL";

export const GET_INFLUENCER_PAYMENTS_REQUEST = "GET_INFLUENCER_PAYMENTS_REQUEST";
export const GET_INFLUENCER_PAYMENTS_SUCCESS = "GET_INFLUENCER_PAYMENTS_SUCCESS";
export const GET_INFLUENCER_PAYMENTS_FAIL = "GET_INFLUENCER_PAYMENTS_FAIL";
