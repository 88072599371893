// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-dashboard {
  display: flex;
  overflow: hidden;
}
.main-dashboard .content {
  margin-left: 7%;
  margin-top: 4.425rem;
}

body {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,oBAAA;AACJ;;AAIA;EACI,SAAA;AADJ","sourcesContent":[".main-dashboard {\n  display: flex;\n  overflow: hidden;\n\n  .content{\n    margin-left: 7%;\n    margin-top: 4.425rem;\n  }\n    \n}\n\nbody {\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
