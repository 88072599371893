import React from "react";
import "../Assets/css/influencer.css";
import user from "../Assets/Img/message1.png";
import Saveicon from "../Assets/Img/Saveicon.png";
import collaborateicon from "../Assets/Img/collaborateicon.png";
import { Link } from "react-router-dom";

function InfluencerDetail() {
  return (
    <div className="container-detail">
      <div className="card-detail">
        <div className="header">
          <div className="brand">
            <img src={user} alt="Puma logo" className="logo" />
            <span className="brand-name">Puma</span>
          </div>
          <Link to="#" className="view-profile">
            View Brand's Profile ↗
          </Link>
        </div>

        <section className="overview">
          <h2 className="section-title">Overview</h2>
          <div className="name mb-3 mt-2">
            <h3 className="campaign-name">Secret Santa</h3>
          </div>
          <p className="campaign-subtitle">Increasing Brand Awareness</p>
          <p className="campaign-description">
            This holiday season, let's celebrate what truly matters: the spirit
            of generosity, thoughtful surprises, and the joy of spreading
            smiles. It's about appreciating each other, not the price tag. Every
            gift, big or small, holds the power to connect us, making this
            Secret Santa campaign an ode to our amazing work fam!
          </p>
          <ul className="campaign-points">
            <li>
              Optional Twist: Introduce a "Mystery Matchmaker" element where
              team leaders anonymously pair Santas, adding a touch of playful
              intrigue
            </li>
            <li>
              Wishlists & Budgets: Encourage employees to create wishlists and
              set comfortable spending limits to ensure everyone feels included.
            </li>
            <li>
              Gift-Giving Inspiration: Provide curated gift guides and exclusive
              discounts from local businesses to support our community.
            </li>
          </ul>
        </section>

        <section className="compensation">
          <h2 className="section-title">Compensation</h2>
          <div className="compensation-details">
            <span className="compensation-type">Flat fee and Product</span>
            <span className="compensation-amount">$ 5000</span>
          </div>
        </section>

        <section className="target-audience">
          <h2 className="section-title">Target Audience</h2>
          <div className="info-row">
            <span className="info-label">Location</span>
            <span className="info-value">
              Delhi, Mumbai, Bangalore, Pune, Goa
            </span>
          </div>
          <div className="info-row">
            <span className="info-label">Gender</span>
            <span className="info-value">All Genders</span>
          </div>
          <div className="info-row">
            <span className="info-label">Language</span>
            <span className="info-value">English, Hindi, Gujrati, Punjabi</span>
          </div>
        </section>

        <section className="deliverables">
          <h2 className="section-title">Expected Deliverables</h2>
          <div className="info-row">
            <span className="info-label">Schedule</span>
            <span className="info-value">20th Dec 2023 to 19th Jan 2024</span>
          </div>
          <div className="timeline">
            <h3 className="timeline-title">Weekly Timeline</h3>
            <div className="timeline-week">
              <div className="week-header">
                <h4 className="week-title">Week 1</h4>
                <span className="week-line"></span>
              </div>
              <ul className="week-tasks">
                <li>Instagram - 1 Post & 2 Stories</li>
                <li>YouTube - 2 Shorts & 1 Video</li>
              </ul>
            </div>
            <div className="timeline-week">
              <div className="week-header">
                <h4 className="week-title">Week 4</h4>
                <span className="week-line"></span>
              </div>
              <ul className="week-tasks">
                <li>Instagram - 1 Reels, 1 Post</li>
                <li>YouTube - 1 Video</li>
              </ul>
            </div>
          </div>
        </section>

        <div className="actions">
          <button className="btn btn-save">
            <img src={Saveicon} alt="Save Campaign" className="icon" />
            Save Campaign
          </button>

          <button className="btn btn-secondary">
            <img src={collaborateicon} alt="Save Campaign" className="icon" />{" "}
            Collaborate
          </button>
        </div>
      </div>
    </div>
  );
}

export default InfluencerDetail;
