import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../assets/BackArrow.svg";
import "./ContractsPage.scss";
import Sort from "../assets/Sort.svg";
import User from "../assets/User.png";
import People from "../assets/People.svg";
import SendPayment from "../SendPayment/SendPayment";

const ContractsPage = () => {
  const location = useLocation();
  const { requests } = location.state || {};
  console.log("requests", requests);
  const columnWidths = [15, 10, 10, 10, 20];

  const [activeTab, setActiveTab] = useState(0);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0)];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleContractView = (request) => {
    console.log("request", request);
    const contractLink = request.contract_link;
    if (contractLink) {
      window.open(contractLink, "_blank");
    } else {
      console.log("Contract link is not available");
    }
  };

  const [isPaymentPopup, setPaymentPopup] = useState(false);

  const handlePaymentPopup = () => {
    setPaymentPopup(true);
  };

  const handlePaymentPopupClose = () => {
    setPaymentPopup(false);
  };

  return (
    <div className="contracts-page">
      <Link to={`/campaign`} className="back-to-campaign-home-button">
        <img src={BackArrow} alt="back" />
        Back
      </Link>
      <div className="contracts-page-header">
        <span className="heading">Sent Contracts ({requests.length})</span>
      </div>

      <div className="tabs">
        <div
          className={activeTab === 0 ? "tab active" : "tab"}
          onClick={() => handleTabClick(0)}
        >
          Pending Contracts
        </div>
        <div
          className={activeTab === 1 ? "tab active" : "tab"}
          onClick={() => handleTabClick(1)}
        >
          Accepted Contracts
        </div>
      </div>

      <div className="influencers-list">
        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ width: `${columnWidths[0]}%` }}>Influencers</th>
              <th style={{ width: `${columnWidths[1]}%` }}>Genre</th>
              <th style={{ width: `${columnWidths[2]}%` }}>
                Followers
                <img src={Sort} alt="Sort Icon" className="sort-icon" />
              </th>
              <th
                style={{ width: `${columnWidths[3]}%` }}
                className="audience-gender"
              >
                Social Presence
              </th>
              <th style={{ width: `${columnWidths[4]}%` }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requests?.map((request, index) =>
              activeTab === 0
                ? request?.request_status === "Accepted" &&
                  request?.contract_status === "Sent" && (
                    <tr key={index}>
                      <td className="profile-info">
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${User})`,
                          }}
                        />
                        <span className="influencer-name">{rows[0].name}</span>
                      </td>
                      <td className="genre">{rows[0].calories}</td>
                      <td className="followers">
                        <div className="followers-image">
                          <img src={People} alt="People Icon" />
                          <span>{rows[0].carbs}</span>
                        </div>
                      </td>

                      <td>
                        <div className="social-presence">
                          <span className="content-percentages">
                            Reach: <span>10%</span>
                          </span>
                          <span className="content-percentages">
                            Engage:<span>60%</span>
                          </span>
                          <span className="content-percentages">
                            CPP: <span>30%</span>
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="actions">
                          <div className="pending">
                            <span>Pending</span>
                            <img src={Sort} alt="clock" className="sort-icon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                : request?.request_status === "Accepted" &&
                  request?.contract_status === "Accepted" && (
                    <tr key={index}>
                      <td className="profile-info">
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${User})`,
                          }}
                        />
                        <span className="influencer-name">{rows[0].name}</span>
                      </td>
                      <td className="genre">{rows[0].calories}</td>
                      <td className="followers">
                        <div className="followers-image">
                          <img src={People} alt="People Icon" />
                          <span>{rows[0].carbs}</span>
                        </div>
                      </td>

                      <td>
                        <div className="social-presence">
                          <span className="content-percentages">
                            Reach: <span>10%</span>
                          </span>
                          <span className="content-percentages">
                            Engage:<span>60%</span>
                          </span>
                          <span className="content-percentages">
                            CPP: <span>30%</span>
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="actions">
                          {request.payment_done == 0 && (
                            <div
                              className="pay"
                              onClick={() => handlePaymentPopup(request)}
                            >
                              <span>Proceed to Pay</span>
                            </div>
                          )}
                          {request.payment_done == 1 && (
                            <div
                              className="pay"
                            >
                              <span>Payment done</span>
                            </div>
                          )}
                          <div className="view">
                            <span onClick={() => handleContractView(request)}>
                              View contract
                            </span>
                          </div>
                        </div>
                        {isPaymentPopup && (
                          <SendPayment
                            onClose={handlePaymentPopupClose}
                            open={isPaymentPopup}
                            request={request}
                          />
                        )}
                      </td>
                    </tr>
                  )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContractsPage;
