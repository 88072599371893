import { useState } from "react";
import { ArrowLeft } from "lucide-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Assets/css/influencer.css";

export default function BankAccountSetup() {
  const [currentStep, setCurrentStep] = useState("selection");
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    ifscCode: "",
    branchName: "",
  });
  const [upiHandle, setUpiHandle] = useState("");

  return (
    <div className="min-vh-100 bg-light p-4">
      <div className="container max-w-2xl mx-auto">
        {/* Back Button */}
        <button
          onClick={() => setCurrentStep("selection")}
          className="btn btn-light mb-3 d-flex align-items-center"
        >
          <ArrowLeft size={20} className="me-2" />
          Back
        </button>

        <div className="text-center">
          <div className="row">
            {/* Full Width - Add Bank Account */}
            <div className="col-12">
              {currentStep !== "bank-details" ? (
                <div
                  onClick={() => setCurrentStep("bank-details")}
                  className="card p-4 mb-3 shadow-sm cursor-pointer"
                >
                  <div className="payment-method-card-body">
                    <div className="border border-danger d-inline-flex align-items-center justify-content-center p-3 mb-2">
                      <span className="text-danger fs-4">+</span>
                    </div>
                    <p className="mb-0">Add Bank Account</p>
                  </div>
                </div>
              ) : (
                <form className="p-4 payment-method-card">
                  <h2 className="text-danger mb-4 text-start">Bank Details</h2>
                  <div className="row g-3 text-start">
                    <div className="col-md-6 ">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Bank Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Bank Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Acc. Number"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Confirm Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Acc. Number"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        IFSC Code (India)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="11 Digit IFSC Code"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Branch Name (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Branch Name"
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>

            {/* OR Divider with styling */}
            <div className="col-12 d-flex align-items-center my-3">
              <div className="flex-grow-1 border-bottom"></div>
              <span className="mx-2 text-danger fw-bold">OR</span>
              <div className="flex-grow-1 border-bottom"></div>
            </div>

            {/* Full Width - Add UPI Handle */}
            <div className="col-12">
              {currentStep !== "upi-handle" ? (
                <div
                  onClick={() => setCurrentStep("upi-handle")}
                  className="card p-4 shadow-sm cursor-pointer"
                >
                  <div className="payment-method-card-body">
                    <div className="border border-danger d-inline-flex align-items-center justify-content-center p-3 mb-2">
                      <span className="text-danger fs-4">+</span>
                    </div>
                    <p className="mb-0">Add UPI Handle</p>
                  </div>
                </div>
              ) : (
                <form className="p-4 payment-method-card">
                  <h2 className="text-danger mb-4 text-start">UPI Handle</h2>
                  <div className="d-flex mb-3">
                    <input
                      type="text"
                      className="form-control me-2"
                      placeholder="Give a catchy and suitable title"
                      value={upiHandle}
                      onChange={(e) => setUpiHandle(e.target.value)}
                    />
                    <button type="button" className="btn btn-danger">
                      Verify
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-outline-secondary me-2">
              Save
            </button>
            <button type="submit" className="btn btn-primary">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
