import React from "react";
import "../Assets/css/index.css";
import StrategicExcellence from "../Assets/Img/StrategicExcellence.png";
import InnovativeSolutions from "../Assets/Img/InnovativeSolutions.png";
import GenuineConnections from "../Assets/Img/GenuineConnections.png";
import DataDrivenSuccess from "../Assets/Img/Data-Driven Success.png";
import YourPartner from "../Assets/Img/Your Partner.png";
import EmpoweringGrowth from "../Assets/Img/Empowering Growth.png";
import signup from "../Assets/Img/signup.png";
import userprofile from "../Assets/Img/userprofile.png";
import searchbar from "../Assets/Img/searchbar.png";
import apply from "../Assets/Img/apply.png";
import collaborate from "../Assets/Img/collaborate.png";
import trackpayment from "../Assets/Img/trackpayment.png";
import report from "../Assets/Img/report.png";
import engagement from "../Assets/Img/engagement.png";
import mainimg from "../Assets/Img/main-img.png";
import arrow from "../Assets/Img/arrow_left.png";
import Testimonials from "./Testimonials";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { Link } from "react-router-dom";

function Home() {
  const StepBlock = ({ icon, title, mainImage, titleWidth }) => (
    <div>
      <div className="image-row">
        <div className="main-img-container">
          <img className="main-img" src={mainImage} alt={`${title} main`} />
          <img className="signup-img" src={icon} alt={`${title} icon`} />
        </div>
      </div>
      <div className="text-title" style={{ width: titleWidth }}>
        {title}
      </div>
    </div>
  );
  const steps = [
    {
      icon: signup,
      title: "Sign Up Log In",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: userprofile,
      title: "Create Profile",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: searchbar,
      title: "Search for Campaigns or Influencers",
      mainImage: mainimg,
      titleWidth: "138px",
    },
    {
      icon: apply,
      title: "Create & Apply to Campaigns",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: collaborate,
      title: "Collaboration",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: trackpayment,
      title: "Track Performance & Payment",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: report,
      title: "Report Results & Return",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: engagement,
      title: "Continued Engagement",
      mainImage: mainimg,
      titleWidth: "100px",
    },
  ];
  const whywe = [
    {
      title: "Strategic Excellence",
      img: StrategicExcellence, // Replace with an actual img or image
      description:
        "We don’t just create strategies; we craft tailored roadmaps that align with your brand’s unique goals, audience, and vision. Every campaign is designed to deliver measurable results.",
    },
    {
      title: "Innovative Solutions",
      img: InnovativeSolutions, // Replace with an actual img or image
      description:
        "From trend-driven insights to cutting-edge tools, we ensure your brand stays ahead of the curve. Innovation fuels our approach to amplify your online presence.",
    },
    {
      title: "Genuine Connections",
      img: GenuineConnections, // Replace with an actual img or image
      description:
        "Building meaningful relationships is at the heart of what we do. Whether it’s engaging your audience or fostering collaborations, we help you create connections that matter.",
    },
    {
      title: "Data-Driven Success",
      img: DataDrivenSuccess, // Replace with an actual img or image
      description:
        "Our decisions are guided by analytics, ensuring that every step is backed by insights. This approach guarantees your resources are invested where they create the most value.",
    },
    {
      title: "Your Partner",
      img: YourPartner, // Replace with an actual img or image
      description:
        "We work alongside you, understanding your needs and challenges. Together, we turn your aspirations into achievements.",
    },
    {
      title: "Empowering Growth",
      img: EmpoweringGrowth, // Replace with an actual img or image
      description:
        "Unlock your potential with tailored strategies designed to drive results and foster meaningful connections. Together, we pave the way for lasting success, empowering your journey to new heights.",
    },
  ];
  return (
    <>
      <div>
        <div className="hero-section-banner">
          <div className="overlay">
            <div className="animation-text">
              <h1>Where Brands and Influencers Orbit Together</h1>
              <Link to="/login">
                {" "}
                <button className="connect-button">Let’s Connect</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section class="why-choose-section">
        <div class="container-fluid">
          <div>
            <div className="d-flex justify-content-start">
              <div className="why-we-title Title">
                <h2>Why Choose InSocial Orbit?</h2>
              </div>
              <div className="why-we">
                <p>
                  In today’s fast-paced digital landscape, standing out requires
                  more than just presence; it demands impact. InSocial Orbit is
                  your partner in navigating the vast universe of social media
                  and digital connections. Here’s why we’re the perfect choice
                  for your growth:
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid my-5 w-100 ">
          {/* <div className="MainContainerPadding"> */}
          <div className="row d-flex justify-content-center">
            {/* First Row of Cards */}
            {whywe.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-xs-12 col-md-4 col-sm-6 d-flex mb-4"
                style={{
                  height: "380px",
                  width: "410px",
                }}
              >
                <div className="card-body position-relative rounded-3">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="card-img-top img-fluid"
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                      marginLeft: "3%",
                      borderRadius: "2%",
                    }}
                  />
                  <h5 className="card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                    {item.title}
                  </h5>
                  <p className="card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Space Between Rows */}
          <div className="row d-flex justify-content-center">
            {/* Second Row of Cards */}
            {whywe.slice(3, 6).map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-xs-12 col-md-4 col-sm-6 d-flex mb-4"
                style={{
                  height: "380px",
                  width: "410px",
                }}
              >
                <div className="card-body position-relative rounded-3">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="card-img-top img-fluid "
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                      marginLeft: "3%",
                      borderRadius: "2%",
                    }}
                  />
                  <h5 className="card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                    {item.title}
                  </h5>
                  <p className="card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description w3-container w3-center w3-animate-left">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
      </section>
      <div className="container-Path-to-Excellence">
        {/* Heading Section */}
        <div className="heading">
          <div className="">
            <div className="heading-text mb-4">Path to Excellence</div>
          </div>
          <p className="mb-5">
            InSocial Orbit simplifies collaboration between brands and
            influencers, making it easy to create, connect, and succeed. Here’s
            a stepbystep look at the journey:
          </p>
        </div>

        {/* Image Row Section */}

        <div className="design-images img-fluid">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <img className="arrow-img" src={arrow} alt="arrow" />
              )}
              <StepBlock
                icon={step.icon}
                title={step.title}
                mainImage={step.mainImage}
                titleWidth={step.titleWidth} // passing titleWidth as a prop
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      <Testimonials />
    </>
  );
}

export default Home;
