import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Assets/css/index.css";
import Diagnoeasy from ".././Assets/Img/Diagnoeasy.png";
import FortifAi from ".././Assets/Img/FortifAi.png";
import RatelIceCream from ".././Assets/Img/RatelIceCream.png";
import star5 from ".././Assets/Img/star5.png";
import testimonialborder from ".././Assets/Img/testimonial border.png";

function Testimonials() {
  const testimonials = [
    {
      image: Diagnoeasy,
      text: `Partnering with InSocialOrbit transformed our outreach efforts. Their expertise in connecting us with the right influencers brought incredible awareness to DiagnoEasy, driving trust and engagement with our home health care services.`,
      img: star5,
      imges: testimonialborder,
      role: "DiagnoEasy",
    },
    {
      image: FortifAi,
      text: `InSocialOrbit helped us connect with the perfect influencers to showcase FortifAI’s capabilities. Their expertise amplified our reach and drove meaningful engagement with our target audience.`,
      img: star5,
      imges: testimonialborder,
      role: "Fortif Ai",
    },
    {
      image: RatelIceCream,
      text: `The campaign with InSocial Orbit exceeded expectations! They connected us with influencers who resonated with our audience, showcasing Rate Ice Cream’s unique flavors. The phenomenal engagement drove buzz, real sales, and lasting brand love.`,
      img: star5,
      imges: testimonialborder,
      role: "Ratel Icecream",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablets
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Mobile screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-section">
      <div className="Title">User Reviews</div>
      <div className="MainContainerPadding">
        <Slider {...settings} className="testimonial-carousel">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card-wrapper" key={index}>
              <div className="testimonial-card">
                <div className="client-image">
                  <img src={testimonial.image} alt={testimonial.role} />
                </div>
                <p className="testimonial-text">{testimonial.text}</p>
                <div className="star-rating">
                  <img src={testimonial.img} alt="5-star rating" />
                </div>
                <div className="borderr mt-3">
                  <img src={testimonial.imges} alt="Border" className="m-auto" />
                </div>
                <p className="client-role">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonials;
