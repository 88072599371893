import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Sampleimg from "../assets/sampleimg.png";
import Ongoing from "../assets/Ongoing.svg";
import Calender from "../assets/Calender.svg";
import CompletedIcon from "../assets/Completed.svg";
import "./ContractPopup.scss";
import { useDispatch, useSelector } from "react-redux";
import Nextarrow from "../assets/Nextarrow.svg";
import { sendContractToInfluencer } from "../../actions/brandAction";
import {
  getRequestById,
  sendContractToBrand,
} from "../../actions/influencersAction";
import { getCampaignOverview } from "../../actions/campaignAction";
import { getUser } from "../../actions/profileAction";

const ContractPopup = ({ open, onClose, request, isFromInfluencer }) => {
  const { isContractSent } = useSelector((state) => state?.contract || false);
  const { requests } = useSelector((state) => state?.request || {});
  const { user: currentUser } = useSelector((state) => state?.user || {});

  const { user, categories } = useSelector(
    (state) => state?.profile?.brandProfile || {}
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFromInfluencer) {
      dispatch(getCampaignOverview(request?.campaign_id));
    } else {
      dispatch(getRequestById(request?.request_id));
      dispatch(getCampaignOverview(request?.campaign_id));
      dispatch(getUser(request?.user_id));
    }
  }, [isFromInfluencer]);

  const { campaign } = useSelector((state) => state?.campaign?.campaign || {});

  useEffect(() => {
    if (isContractSent) {
      onClose();
    }
  }, [isContractSent]);

  const [sendContractDetails, setsendContractDetails] = useState({
    contract: "",
    request_id: isFromInfluencer ? request?.request_id : request?.request_id,
    campaign_id: request?.campaign_id,
    influencer_id: currentUser?.user_id,
    brand_id: request?.user_id,
  });

  const handleInputChange = (field, value) => {
    setsendContractDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSendContract = () => {
    if (isFromInfluencer) {
      dispatch(sendContractToBrand(sendContractDetails));
    } else {
      dispatch(sendContractToInfluencer(sendContractDetails));
    }
  };

  const truncateTitle = (title, maxLength) => {
    if (!title || title.length === 0) return "";
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    }
    return title;
  };

  const getCurrentDate = () => new Date();

  const isOngoing = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    let isOngoing =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate >= new Date(campaign.start_date) &&
      currentDate <= new Date(campaign.end_date);

    return isOngoing;
  };

  const isCompleted = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    const completed =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate > new Date(campaign.end_date);

    return completed;
  };

  function formatDateRange(startDate, endDate) {
    const options = { day: "numeric", month: "long", year: "numeric" };

    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const countInstagramTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.Instagram?.reels || 0) +
        (weekData.Instagram?.posts || 0) +
        (weekData.Instagram?.story || 0);
      return acc;
    }, 0);
  };

  const countYouTubeTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.YouTube?.videos || 0) + (weekData.YouTube?.live_stream || 0);
      return acc;
    }, 0);
  };
  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray = JSON.parse(languagesJson);

      return languagesArray.map((language, index) => (
        <span
          key={index}
          style={{
            width: "40px",
            height: "23px",
            borderRadius: "5px",
            border: "0.5px solid rgba(0, 0, 0, 0.5)",
            background: "#fff",
            color: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            marginRight: "5px",
          }}
        >
          {language.slice(0, 3)}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="95%" maxHeight="95%">
      <DialogContent>
        <div className="contract-popup">
          <div className="contract-popup-header">
            <span>Contract for Collaboration</span>
          </div>
          <div className="influencer-profile-summary-heading">
            Influencer Profile Summary
          </div>
          {isFromInfluencer ? (
            <div className="brand-profile">
              <div className="profile-info">
                <div className="brand-photo">
                  <img
                    className="brand-logo"
                    src={user?.user_photo}
                    alt="profile-pic"
                  />
                </div>
                <div className="brand-details">
                  <span className="brand-name">{user?.brand_name}</span>
                  <span className="brand-category">
                    {categories || "Category missing"}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="profile-summary">
              <div className="profile">
                <span className="influe">John Doe</span>
                <div>
                  <img src={Sampleimg} alt="img" />
                </div>
                <span className="type">Fashion</span>
              </div>

              <div className="social-interaction">
                <span className="media-name">Instagram</span>
                <div className="interaction-stats">
                  <div className="stats">
                    <span className="stats-number">6k</span>
                    <span>Avg. Likes</span>
                  </div>
                  <div className="stats">
                    <span className="stats-number">4k</span>
                    <span>Followers</span>
                  </div>
                  <div className="stats">
                    <span className="stats-number">6k</span>
                    <span>Engagement</span>
                  </div>
                </div>
              </div>

              <div className="social-interaction last">
                <span className="media-name">Youtube</span>
                <div className="interaction-stats">
                  <div className="stats">
                    <span className="stats-number">6k</span>
                    <span>Avg. Likes</span>
                  </div>
                  <div className="stats">
                    <span className="stats-number">4k</span>
                    <span>Followers</span>
                  </div>
                  <div className="stats">
                    <span className="stats-number">6k</span>
                    <span>Engagement</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="campaign-summary">
            <span className="campaign-summary-header">Campaign Summary</span>

            <div className="campaign-content">
              <div className="campaign-status">
                <span>{truncateTitle(campaign?.campaign_title, 16)}</span>
                {isOngoing(campaign) && (
                  <div className="status">
                    <img src={Ongoing} alt="Ongoing Icon" />
                    <span className="ongoing">Ongoing</span>
                  </div>
                )}
                {isCompleted(campaign) && (
                  <div className="status">
                    <img src={CompletedIcon} alt="Completed Icon" />
                    <span className="completed">Completed</span>
                  </div>
                )}
              </div>
              <div className="aim">
                {truncateTitle(campaign?.campaign_obj, 30)}
              </div>
              <div className="location-calendar-info">
                <div className="calender-info">
                  <img src={Calender} alt="Calender Icon" />
                  <span>
                    {(campaign?.start_date && campaign?.end_date && (
                      <span>
                        {formatDateRange(
                          campaign.start_date,
                          campaign.end_date
                        )}
                      </span>
                    )) || <span className="missing-info">Date missing</span>}
                  </span>
                </div>
              </div>
              <span className="deliverables-title">Deliverables</span>
              <span>
                {(campaign && campaign.timeline && (
                  <div className="required-content">
                    {campaign.timeline && (
                      <div className="instagram-total">
                        <span>{`${countInstagramTotal(
                          campaign.timeline
                        )} from Insta `}</span>
                      </div>
                    )}
                    <span>•</span>
                    {campaign.timeline && (
                      <div className="youtube-total">
                        <span>{`${countYouTubeTotal(
                          campaign.timeline
                        )} from YT`}</span>
                      </div>
                    )}
                    {campaign.language && <span>•</span>}
                    {campaign.language && (
                      <div className="languages">
                        {formatLanguages(campaign.language)}
                      </div>
                    )}
                  </div>
                )) || (
                  <div className="required-content">
                    <span>Deliverables missing</span>
                  </div>
                )}
              </span>
            </div>
          </div>

          <div className="file-input">
            <label htmlFor="contract">Contract Document</label>

            <div className="image-input-box">
              <input
                type="file"
                id="contract"
                accept=".jpg, .png"
                onChange={(e) =>
                  handleInputChange("contract", e.target.files[0])
                }
              />

              <span>
                Drag & Drop or <span className="browse">Browse</span>
              </span>
              <small>
                Supported files: <span className="jpg-png">JPG, PNG</span>
              </small>
              <small>File size should not exceed 1MB</small>
            </div>
          </div>
          <div className="btn">
            <div className="cancel" onClick={() => onClose()}>
              Cancel
            </div>
            <div
              className={`send ${!sendContractDetails.contract && "disabled"}`}
              onClick={() => handleSendContract()}
              disabled={!sendContractDetails.contract}
            >
              Send
              <img src={Nextarrow} alt="Nextarrow Icon" />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContractPopup;
