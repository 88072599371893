import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginimg from "../Assets/Img/loginsideimg.png";
import axiosMain from "../http/axiosMain";
import { GlobalContext } from "../../utils/GlobalContext";

function OtpVerification({ setIsLoggedIn }) {
  const { setisLogdin } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const email = location.state?.email || localStorage.getItem("email") || "";

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    if (value.length > 1) return; // Restrict to one digit per box

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus(); // Move to next input
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").trim();
    if (/^\d{6}$/.test(pasteData)) {
      setOtp([...pasteData]);
      pasteData.split("").forEach((char, i) => {
        if (inputRefs.current[i]) {
          inputRefs.current[i].value = char;
        }
      });
      inputRefs.current[5].focus(); // Focus last field after pasting
    }
  };

  const handleSubmitOtp = async () => {
    const otpString = otp.join("");
    if (otpString.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const response = await axiosMain.post(
        "/api/v1/email-login-verify",
        { email, otp: otpString },
        { withCredentials: true }
      );

      if (response.status === 200) {
        const meResponse = await axiosMain.get("/api/v1/me", {
          withCredentials: true,
        });

        if (meResponse.status === 200) {
          localStorage.setItem("user", JSON.stringify(meResponse.data));
          const token = response.data.token;
          if (token) {
            localStorage.setItem("authToken", token);
            setisLogdin(true);
          }

          const role = meResponse.data?.user?.role;
          if (role) localStorage.setItem("role", role);

          role === "brand"
            ? navigate("/brand-home")
            : role === "influencer"
            ? navigate("/influencer")
            : navigate("/home");
        } else {
          setError(meResponse.data?.error || "Failed to fetch user data.");
        }
      } else {
        setError(response.data?.error || "Failed to verify OTP. Try again.");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to verify OTP. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-mobileotp p-0 mt-5 mb-5">
      <div className="card">
        <div className="row no-gutters">
          <div className="col-xs-12 col-sm-12 col-md-12 left">
            <img src={loginimg} alt="Login visual" className="img" />
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 right">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                <h2>
                  Collaborate.
                  <br /> Create. Connect.
                </h2>
                <div className="otp-input-fields" onPaste={handlePaste}>
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="tel"
                      className="otp__digit"
                      value={value}
                      maxLength={1}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      aria-label={`OTP digit ${index + 1}`}
                    />
                  ))}
                </div>
                {error && <p className="error-text text-danger">{error}</p>}
                <button
                  type="button"
                  className="btn btn-outline-success btn-lg mt-4"
                  onClick={handleSubmitOtp}
                  disabled={loading || otp.join("").length !== 6}
                >
                  {loading ? "Verifying..." : "Validate and Log in"}
                </button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 footer">
                <Link to="/login" className="btn-link">
                  Log In with Email
                </Link>
                <div className="signup-instruction">
                  By continuing, you agree to our terms and conditions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpVerification;
