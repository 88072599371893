import React, { useEffect } from "react";
import "./Payment.scss";
import SearchIcon from "../assets/Search.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../../actions/influencersAction";

const Payment = () => {

  const dispatch = useDispatch();
  const {payments}=useSelector((state)=>state.payments || []);

  useEffect(() => {
    dispatch(getPayments());
  }, [dispatch]);

  const handleSearch = (event) => {
    console.log(event.target.value);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate;
}

  const columnWidths = [15, 20, 20, 15, 15, 10];

  return (
    <div className="payment-container">
      <div className="payment-header">
        <div className="payment-header-left">
          <span className="payment-heading">Payments</span>
          <span className="payment-subheading">
            Manage your billing and payment details{" "}
            <span className="payment-method">View payment Method</span>
          </span>
        </div>
        <div className="payment-header-right">
          <div className="search">
            <img src={SearchIcon} alt="Search Icon" className="search-icon" />
            <input
              className="search-bar"
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div className="download">
            <img
              src={SearchIcon}
              alt="download Icon"
              className="download-icon"
            />
            <span className="download-text">Download PDF Report</span>
          </div>
        </div>
      </div>
      <hr className="vertical-line" />
      <div className="payment-table">
        <table>
          <thead>
            <tr className="payment-table-heading">
              <th style={{ width: `${columnWidths[0]}%` }}>INVOICE NUMBER</th>
              <th style={{ width: `${columnWidths[1]}%` }}>Brand Name</th>
              <th style={{ width: `${columnWidths[2]}%` }}>Campaign Name</th>
              <th style={{ width: `${columnWidths[3]}%` }}>BILLING DATE</th>
              <th style={{ width: `${columnWidths[4]}%` }}>AMOUNT</th>
              <th style={{ width: `${columnWidths[5]}%` }}>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {payments&&payments.map((data, index) => {
              return (
                <tr className="payment-table-content" key={index}>
                  <td>{data.id}</td>
                  <td>{data.brand_name}</td>
                  <td>{data.campaign_name}</td>
                  <td>{formatDate(data.billing_date)}</td>
                  <td>{data.amount}</td>
                  <td>
                    <div className="payment-status">
                      <span
                        className={
                          data.status === "Paid"
                            ? "status-paid"
                            : "status-unpaid"
                        }
                      >
                        {data.status}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payment;
