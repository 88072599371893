import React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import './UpiHandlePopUp.scss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function UpiHandlePopUp({ open, onClose }) {

  const {user}=useSelector(state=>state.user || {});
  const navigate = useNavigate();

    return (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="95%"
          maxHeight="95%"
        >
          <DialogContent>
            <div className="upiHandle-popup">
              <div className="upiHandle-popup-header">
                <span>UPI Handle</span>
              </div>
              <div className="upiHandle-container">
                <div className='upi'>
                    <span>{user?.upi_id}</span>
                    <span>Verified  ✅</span>
                </div>
              </div>
              <div className="btn">
                <button  className="edit-btn" onClick={() => navigate("/paymentverification") }>Edit</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
}

export default UpiHandlePopUp