import { useState } from "react";
import { Search, Download, ChevronDown } from "lucide-react";
import "../Assets/css/influencer.css";

export default function PaymentsDashboard() {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const payments = [
    {
      id: "51468",
      brandName: "Puma",
      campaignName: "Secret Santa",
      billingDate: "2/19/23",
      amount: 5000,
      status: "Paid",
    },
    {
      id: "54673",
      brandName: "Nike",
      campaignName: "BBD Sale",
      billingDate: "5/7/23",
      amount: 10000,
      status: "Paid",
    },
    {
      id: "13457",
      brandName: "Uncle Max Pan Cakes",
      campaignName: "Amazon Sale",
      billingDate: "9/18/23",
      amount: 2000,
      status: "Unpaid",
    },
    {
      id: "54407",
      brandName: "Samsung",
      campaignName: "Galaxy S24",
      billingDate: "2/11/23",
      amount: 15000,
      status: "Paid",
    },
    {
      id: "12434",
      brandName: "Realme",
      campaignName: "Narzo Series",
      billingDate: "9/18/16",
      amount: 12000,
      status: "Unpaid",
    },
    {
      id: "84541",
      brandName: "Mama Earth",
      campaignName: "Onion Shampoo",
      billingDate: "1/28/17",
      amount: 8000,
      status: "Unpaid",
    },
    {
      id: "21301",
      brandName: "Wow Life Science co. Ltd",
      campaignName: "Vitamin C Face Wash",
      billingDate: "5/27/15",
      amount: 1000,
      status: "Paid",
    },
    {
      id: "04391",
      brandName: "Guy Hawkins",
      campaignName: "Guy Hawkins",
      billingDate: "8/2/19",
      amount: 17000,
      status: "Paid",
    },
    {
      id: "04392",
      brandName: "Guy Hawkins",
      campaignName: "Guy Hawkins",
      billingDate: "8/2/19",
      amount: 15000,
      status: "Paid",
    },
    {
      id: "04398",
      brandName: "Guy Hawkins",
      campaignName: "Guy Hawkins",
      billingDate: "8/2/19",
      amount: 7000,
      status: "Paid",
    },
    {
      id: "04393",
      brandName: "Guy Hawkins",
      campaignName: "Guy Hawkins",
      billingDate: "8/2/19",
      amount: 9000,
      status: "Unpaid",
    },
    {
      id: "04397",
      brandName: "Guy Hawkins",
      campaignName: "Guy Hawkins",
      billingDate: "8/2/19",
      amount: 19000,
      status: "Paid",
    },
  ];

  const filteredPayments = payments.filter(
    (payment) =>
      payment.brandName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payment.campaignName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleSort = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const sortedPayments = [...filteredPayments].sort((a, b) => {
    const dateA = new Date(a.billingDate);
    const dateB = new Date(b.billingDate);
    return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="payments-dashboard">
      <div className="container-fluid py-4">
        <div className="header mb-4">
          <div className="mb-3">
            <h1 className="payments-title">Payments</h1>
            <div className="d-flex align-items-center subtitle-container">
              <p className="subtitle mb-0">
                Manage your billing and payment details
              </p>
              <a href="#" className="view-payment-link ms-3">
                View Payment Method
              </a>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="search-container">
              <Search className="search-icon" size={20} />
              <input
                type="text"
                placeholder="Search for Brand, Campaign"
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button className="download-btn">
              <Download size={20} />
              Download PDF Report
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Payment ID</th>
                <th>Brand Name</th>
                <th>Campaign Name</th>
                <th className="billing-date" onClick={toggleSort}>
                  BILLING DATE
                  <ChevronDown
                    size={16}
                    className={`ms-1 ${
                      sortDirection === "desc" ? "rotate-180" : ""
                    }`}
                  />
                </th>
                <th>AMOUNT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {sortedPayments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.id}</td>
                  <td>{payment.brandName}</td>
                  <td>{payment.campaignName}</td>
                  <td>{payment.billingDate}</td>
                  <td>{payment.amount}</td>
                  <td>
                    <span
                      className={`status-badge ${payment.status.toLowerCase()}`}
                    >
                      {payment.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
