export const LOGIN_SEND_OTP_EMAIL_REQUEST = "LOGIN_SEND_OTP_EMAIL_REQUEST";
export const LOGIN_SEND_OTP_EMAIL_SUCCESS = "LOGIN_SEND_OTP_EMAIL_SUCCESS";
export const LOGIN_SEND_OTP_EMAIL_FAIL = "LOGIN_SEND_OTP_EMAIL_FAIL";
export const LOGIN_SEND_OTP_EMAIL_RESET = "LOGIN_SEND_OTP_EMAIL_RESET";

export const LOGIN_VERIFY_OTP_EMAIL_REQUEST = "LOGIN_VERIFY_OTP_EMAIL_REQUEST";
export const LOGIN_VERIFY_OTP_EMAIL_SUCCESS = "LOGIN_VERIFY_OTP_EMAIL_SUCCESS";
export const LOGIN_VERIFY_OTP_EMAIL_FAIL = "LOGIN_VERIFY_OTP_EMAIL_FAIL";

export const LOGIN_SEND_OTP_PHONE_REQUEST = "LOGIN_SEND_OTP_PHONE_REQUEST";
export const LOGIN_SEND_OTP_PHONE_SUCCESS = "LOGIN_SEND_OTP_PHONE_SUCCESS";
export const LOGIN_SEND_OTP_PHONE_FAIL = "LOGIN_SEND_OTP_PHONE_FAIL";

export const LOGIN_VERIFY_OTP_PHONE_REQUEST = "LOGIN_VERIFY_OTP_PHONE_REQUEST";
export const LOGIN_VERIFY_OTP_PHONE_SUCCESS = "LOGIN_VERIFY_OTP_PHONE_SUCCESS";
export const LOGIN_VERIFY_OTP_PHONE_FAIL = "LOGIN_VERIFY_OTP_PHONE_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const REGISTER_EMAIL_REQUEST = "REGISTER_EMAIL_REQUEST";
export const REGISTER_EMAIL_SUCCESS = "REGISTER_EMAIL_SUCCESS";
export const REGISTER_EMAIL_FAIL = "REGISTER_EMAIL_FAIL";
export const REGISTER_EMAIL_RESET = "REGISTER_EMAIL_RESET";

export const VERIFY_EMAIL_OTP_REQUEST = "VERIFY_EMAIL_OTP_REQUEST";
export const VERIFY_EMAIL_OTP_SUCCESS = "VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_FAIL = "VERIFY_EMAIL_OTP_FAIL";
export const VERIFY_EMAIL_OTP_RESET = "VERIFY_EMAIL_OTP_RESET";

export const REGISTER_PHONE_REQUEST = "REGISTER_PHONE_REQUEST";
export const REGISTER_PHONE_SUCCESS = "REGISTER_PHONE_SUCCESS";
export const REGISTER_PHONE_FAIL = "REGISTER_PHONE_FAIL";
export const REGISTER_PHONE_RESET = "REGISTER_PHONE_RESET";

export const VERIFY_PHONE_OTP_REQUEST = "VERIFY_PHONE_OTP_REQUEST";
export const VERIFY_PHONE_OTP_SUCCESS = "VERIFY_PHONE_OTP_SUCCESS";
export const VERIFY_PHONE_OTP_FAIL = "VERIFY_PHONE_OTP_FAIL";
export const VERIFY_PHONE_OTP_RESET = "VERIFY_PHONE_OTP_RESET";

export const SET_EMAIL_IN_REDUX = 'SET_EMAIL_IN_REDUX';

export const STORE_ROLE_IN_REDUX = 'STORE_PHONE_IN_REDUX';

export const CLEAR_ERROR = "CLEAR_ERROR";



