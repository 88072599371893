import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axiosMain from "./../http/axiosMain";
import loginimg from "../Assets/Img/loginsideimg.png";

function LoginWithEmail({ onClick }) {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();
  

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setServerError(""); // Clear any previous server error

      try {
        const response = await axiosMain.post("/api/v1/email-login", {
          email: values.email,
        });

        if (response.status === 200) {
          localStorage.setItem("email", values.email);
          navigate("/otp");
        } else {
          setServerError(response.data?.error || "Failed to send OTP. Try again.");
        }
      } catch (err) {
        setServerError(err.response?.data?.error || "Failed to send OTP. Try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="container-email p-0 mt-5 mb-5">
      <div className="card">
        <div className="row no-gutters">
          {/* Left Side (Image Section) */}
          <div className="col-xs-12 col-sm-12 col-md-6 left">
            <img src={loginimg} alt="sideimg" className="img" />
          </div>

          {/* Right Side (Login Section) */}
          <div className="col-xs-12 col-sm-12 col-md-6 right">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Collaborate.<br /> Create. Connect.</h2>
                <form onSubmit={formik.handleSubmit}>
                  {/* Email Input Field */}
                  <div className="inputForm">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      className={`input text-center ${
                        formik.errors.email && formik.touched.email ? "is-invalid" : ""
                      }`}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ color: "rgba(107, 142, 118, 1)" }}
                    />
                  </div>

                  {/* Email Error Message */}
                  {formik.errors.email && formik.touched.email && (
                    <p className="error-text">{formik.errors.email}</p>
                  )}

                  {/* Server Error Message */}
                  {serverError && <p className="error-text">{serverError}</p>}

                  {/* Remember Me Checkbox */}
                  <div className="flex-row d-flex justify-content-between align-items-center mt-3">
                    <div>
                      <input type="checkbox" />
                      <label className="ms-2">Remember me</label>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="button-submit btn-outline-success"
                    disabled={loading || !formik.isValid}
                  >
                    {loading ? "Sending OTP..." : "Login With Email"}
                  </button>
                </form>
              </div>

              {/* Footer Links */}
              <div className="col-12 footer">
                <div className="sign-link mt-auto">
                  <Link to="/phone-sign-up" className="btn-link btn-outline-success">
                    Login With Phone
                  </Link>
                </div>
                <div className="signup-instruction">
                  By continuing, you agree to our terms and conditions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginWithEmail;
