import axios from "axios";
import {
  GET_ALL_INFLUENCERS_REQUEST,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  GET_SEARCH_INFLUENCERS_REQUEST,
  GET_SEARCH_INFLUENCERS_SUCCESS,
  GET_SEARCH_INFLUENCERS_FAIL,
  TOGGLE_LIKE_REQUEST,
  TOGGLE_LIKE_SUCCESS,
  TOGGLE_LIKE_FAILURE,
  GET_ALL_LIKED_INFLUENCERS_REQUEST,
  GET_ALL_LIKED_INFLUENCERS_SUCCESS,
  GET_ALL_LIKED_INFLUENCERS_FAIL,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL,
  SEND_CONTRACT_TO_BRAND_REQUEST,
  SEND_CONTRACT_TO_BRAND_SUCCESS,
  SEND_CONTRACT_TO_BRAND_FAIL,
  SETUP_INFLUENCER_BANK_DETAILS_REQUEST,
  SETUP_INFLUENCER_BANK_DETAILS_SUCCESS,
  SETUP_INFLUENCER_BANK_DETAILS_FAIL,
  SET_UP_INFLUENCER_PROFILE_REQUEST,
  GET_INFLUENCER_BANK_DETAILS_REQUEST,
  GET_INFLUENCER_BANK_DETAILS_SUCCESS,
  GET_INFLUENCER_BANK_DETAILS_FAIL,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS,
  GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL,
  GET_INFLUENCER_PAYMENTS_REQUEST,
  GET_INFLUENCER_PAYMENTS_SUCCESS,
  GET_INFLUENCER_PAYMENTS_FAIL,
} from "../constants/influencersConstants";
import { GET_CAMPAIGNS_FOR_INFLUENCER_FAIL, GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST, GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS } from "../constants/campaignConstants";

const getQueryString = (queryParams) => {
  if (!queryParams) {
    return ""; // Return an empty string if queryParams is undefined or null
  }

  const queryString = Object.entries(queryParams)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0; // Filter out keys with no selected options
      } else {
        return value !== undefined && value !== null; // Filter out keys with undefined or null values
      }
    })
    .map(([key, value]) => {
      const formattedKey = key.toLowerCase();
      if (Array.isArray(value)) {
        const formattedValue = Array.from(new Set(value)).join(",");
        return `${formattedKey}=${formattedValue}`;
      } else {
        return `${formattedKey}=${value}`;
      }
    })
    .join("&");

  return queryString ? `${queryString}` : "";
};

export const getAllInfluencers = (queryParams) => async (dispatch) => {
  dispatch({ type: GET_ALL_INFLUENCERS_REQUEST });
  try {
    const queryString = getQueryString(queryParams);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/influencers?${queryString}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_ALL_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_ALL_INFLUENCERS_FAIL,
      payload:error.response.data.error,
    });
  }
};

export const updateInfluencerLikes = (influencerId) => async (dispatch) => {
  try {
    dispatch({ type: TOGGLE_LIKE_REQUEST,payload: influencerId, });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/liked-influencer`,
      influencerId,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: TOGGLE_LIKE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error("Error updating influencer likes:", error);
    dispatch({
      type: TOGGLE_LIKE_FAILURE,
      payload: error.response.data.error,
    });
  }
};

export const getSearchedInfluencers = (searchTerm) => async (dispatch) => {
  dispatch({ type: GET_SEARCH_INFLUENCERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/search-influencer?search=${searchTerm}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: GET_SEARCH_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_SEARCH_INFLUENCERS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const getFavoriteInfluencers = () => async (dispatch) => {
  dispatch({ type: GET_ALL_LIKED_INFLUENCERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/all-liked-influencer`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_ALL_LIKED_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_ALL_LIKED_INFLUENCERS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const getInfluencerAppliedCampaigns = () => async (dispatch) => {
  dispatch({ type: GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/requests`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const sendContractToBrand = (contractData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_CONTRACT_TO_BRAND_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/accept-contract`,
      contractData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: SEND_CONTRACT_TO_BRAND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error("Error sending contract to brand:", error);
    dispatch({
      type: SEND_CONTRACT_TO_BRAND_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const setUpInfluencerBankDetails = (bankDetails) => async (dispatch) => {
  dispatch({ type: SETUP_INFLUENCER_BANK_DETAILS_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/bank-details`,
      bankDetails,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: SETUP_INFLUENCER_BANK_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error setting up influencer bank details:", error);
    dispatch({
      type: SETUP_INFLUENCER_BANK_DETAILS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const setUpInfluerncerProfile = (profileData) => async (dispatch) => {
  dispatch({ type: SET_UP_INFLUENCER_PROFILE_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/profile`,
      profileData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: SETUP_INFLUENCER_BANK_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error setting up influencer profile:", error);
    dispatch({
      type: SETUP_INFLUENCER_BANK_DETAILS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const getInfluencerBankDetails = (influencer_id) => async (dispatch) => {
  dispatch({ type: GET_INFLUENCER_BANK_DETAILS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/influencer-bank-details?influencer_id=${influencer_id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_INFLUENCER_BANK_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencer bank details:", error);
    dispatch({
      type: GET_INFLUENCER_BANK_DETAILS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const getRequestById = (request_id) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGN_REQUEST_BY_INFLUENCER_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/request?request_id=${request_id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_CAMPAIGN_REQUEST_BY_INFLUENCER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching campaign request:", error);
    dispatch({
      type: GET_CAMPAIGN_REQUEST_BY_INFLUENCER_FAIL,
      payload: error.response.data.error,
    });
  }
}

export const getPayments = () => async (dispatch) => {
  dispatch({ type: GET_INFLUENCER_PAYMENTS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/payments`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_INFLUENCER_PAYMENTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencer payments:", error);
    dispatch({
      type: GET_INFLUENCER_PAYMENTS_FAIL,
      payload: error.response.data.error,
    });
  }
}
