import { useEffect, useState } from "react";
import "./Campaign.css";
import CampaignDetails from "../../Assets/Img/Form.png";
import Audience from "../../Assets/Img/People.png";
import Deliverables from "../../Assets/Img/Social Media.png";
import Overview from "../../Assets/Img/solar_checklist-outline.png";
import Compensationimg from "../../Assets/Img/Compensationimg.png";
import TargetAudienceimg from "../../Assets/Img/TargetAudienceimg.png";
import ExpectedDeliverablesimg from "../../Assets/Img/ExpectedDeliverablesimg.png";
import fileicon from "../../Assets/Img/fileicon.png";
import loadingGIF from "../../Assets/Img/loadingGIF.gif";
import Search from "../../Assets/Img/search.png";
import Heart from "../../Assets/Img/Heart.png";
import HeartDark from "../../Assets/Img/HeartDark.png";
import Filter from "../../Assets/Img/Filter.png";
import create from "../../Assets/Img/create dashboard.png";
import NoCamp from "../../Assets/Img/amico.svg";
import { useNavigate } from "react-router-dom";
import axiosMain from "../../http/axiosMain";

export default function CampaignCreate() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contentTypes, setContentTypes] = useState([
    {
      platform: "Instagram",
      items: [
        { id: "reels", label: "Reels", count: 1, checked: false },
        { id: "posts", label: "Posts", count: 1, checked: false },
        { id: "story", label: "Story", count: 1, checked: false },
      ],
    },
    {
      platform: "YouTube",
      items: [
        { id: "shorts", label: "Shorts", count: 1, checked: false },
        { id: "video", label: "Video", count: 1, checked: false },
        { id: "liveStream", label: "Live Stream", count: 1, checked: false },
      ],
    },
  ]);

  const handleIncrement = (platformIndex, itemIndex) => {
    const newContentTypes = [...contentTypes];
    newContentTypes[platformIndex].items[itemIndex].count += 1;
    setContentTypes(newContentTypes);
  };

  const handleDecrement = (platformIndex, itemIndex) => {
    const newContentTypes = [...contentTypes];
    const currentCount = newContentTypes[platformIndex].items[itemIndex].count;
    if (currentCount > 0) {
      newContentTypes[platformIndex].items[itemIndex].count -= 1;
      setContentTypes(newContentTypes);
    }
  };

  const handleCheckboxChange = (platformIndex, itemIndex) => {
    const newContentTypes = [...contentTypes];
    newContentTypes[platformIndex].items[itemIndex].checked =
      !newContentTypes[platformIndex].items[itemIndex].checked;
    setContentTypes(newContentTypes);
  };

  const handleNextbtn = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevbtn = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setEndDate(""); // Reset end date when start date changes
    setShowContent(false);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setLoading(true);
    setShowContent(false);

    setTimeout(() => {
      setLoading(false);
      setShowContent(true);
    }, 2000); // Show loader for 2 seconds
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const totalSteps = 4;

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };
  const [selectedAge, setSelectedAge] = useState(null);

  const ageGroups = [
    "13-17",
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65+",
  ];

  const handleAgeSelect = (age) => {
    setSelectedAge(age);
  };

  const handleSaveDraft = () => {
    // Log save draft response here
    console.log("Draft Saved:", {
      startDate,
      endDate,
      contentTypes,
      selectedAge,
    });
  };

  const handlePublish = () => {
    // Log publish response here
    console.log("Campaign Published:", {
      startDate,
      endDate,
      contentTypes,
      selectedAge,
    });
  };

  const navigate = useNavigate();
  const createNavigate = () => {
    navigate("/campaign-create");
  };

  const ViewDetails = (id) => {
    navigate(`/campaign-details/${id}`);
  };

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosMain.get("/api/v1/all");
        console.log("Response Data:", response.data);
        setData(response.data.campaigns); // Save the data to state
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function formatSingleDate(dateString) {
    const date = new Date(dateString);

    const options = { day: "2-digit", month: "short" };
    const formattedDate = date.toLocaleString("en-GB", options);

    // Adding "th" for the day (you can add specific logic for "st", "nd", "rd" if needed)
    const dayWithSuffix = formattedDate.replace(/(\d{1,2})(?=\s)/, (match) => {
      const day = parseInt(match);
      if (day === 1 || day === 21 || day === 31) {
        return `${match}st`;
      } else if (day === 2 || day === 22) {
        return `${match}nd`;
      } else if (day === 3 || day === 23) {
        return `${match}rd`;
      }
      return `${match}th`;
    });

    return dayWithSuffix;
  }

  return (
    <div className="campaignCreate">
      <div className="SearchFilterSection">
        <div className=" d-flex justify-content-between align-items-center w-100">
          <div className="SearchInput">
            <img src={Search} className="img-fluid" alt="Search Icon" />
            <input
              type="text"
              className="form-control  m-0"
              placeholder="Search for Campaigns................."
            />
          </div>
          <div>
            <button onClick={() => createNavigate()}>
              Create new Campaign
              <img src={create} className="img-fluid" alt="Heart Icon" />
            </button>
            <button>
              Filters{" "}
              <img src={Filter} className="img-fluid" alt="Filter Icon" />
            </button>
            <button>Reset Filters</button>
          </div>
        </div>
      </div>
      <div className="row g-0 mt-4">
        <h2 className="Title">Recently Created Campaigns</h2>
      </div>

      {/* <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center px-4 max-w-md mx-auto">
          <div className="">
            <img
              src={NoCamp}
              alt="Campaign illustration"
              fill
              className="object-contain"
              priority
            />
          </div>

          <h2 className="Title">Welcome to the Campaign!</h2>

          <p className="text-gray-600 mb-8">
            It looks like you haven't created any campaigns yet. Ready to launch
            your first one?
          </p>
          <button onClick={() => createNavigate()} className="CreateGreenBtn">
            Create new Campaign
            <img src={create} className="img-fluid" alt="Heart Icon" />
          </button>
        </div>
      </div> */}
      {data?.length > 0 ? (
        <div className="CreatedCampaigns">
          <div className="row g-4">
            {data?.map((data) => (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div class="campaign-card">
                    <img
                      src={data.campaign_poster}
                      alt="Christmas Campaign"
                      class="campaign-image w-100"
                    />
                    <div className="p-3">
                      <div class="d-flex justify-content-between align-items-start mb-2">
                        <div>
                          <h5 class="mb-1">{data.campaign_title}</h5>
                          <span class="status-badge">Ongoing</span>
                        </div>
                      </div>
                      <p class="mb-2">Increasing Brand Awareness</p>
                      <div class="d-flex align-items-center mb-2">
                        <span class="date-text">
                          {formatSingleDate(data.start_date)} to{" "}
                          {formatSingleDate(data.end_date)}
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <span class="location-text">Mumbai, Maharashtra</span>
                      </div>
                      <div class="mb-3">
                        <p class="mb-2">Deliverables</p>
                        <div>
                          <span class="language-tag">Bn</span>
                          <span class="language-tag">Eng</span>
                          <span class="language-tag">gujj</span>
                          <span class="language-tag">Eng</span>
                        </div>
                      </div>
                      <button onClick={() => ViewDetails(data.campaign_id)}>
                        Campaign Details
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="text-center px-4 max-w-md mx-auto">
            <div className="">
              <img
                src={NoCamp}
                alt="Campaign illustration"
                fill
                className="object-contain"
                priority
              />
            </div>

            <h2 className="Title">Welcome to the Campaign!</h2>

            <p className="text-gray-600 mb-8">
              It looks like you haven't created any campaigns yet. Ready to
              launch your first one?
            </p>
            <button onClick={() => createNavigate()} className="CreateGreenBtn">
              Create new Campaign
              <img src={create} className="img-fluid" alt="Heart Icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
