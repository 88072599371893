import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../assets/BackArrow.svg";
import "./AcceptedRequest.scss";
import { useDispatch, useSelector } from "react-redux";
import Sort from "../assets/Sort.svg";
import User from "../assets/User.png";
import People from "../assets/People.svg";
import ContractPopup from "../Contract/ContractPopup";
import { getCampaignRequestsForBrand } from "../../actions/campaignAction";

const AcceptedRequest = () => {
  const location = useLocation();
  const { requests: req } = location.state || {};
  const columnWidths = [15, 13, 10, 10, 15];

  const { requests } = useSelector(
    (state) => state?.brandCampaignRequests || []
  );
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0)];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [campaignID, setCampaignID] = useState("");

  const openContracts = () => {
    navigate("/contractspage", { state: { requests } });
  };

  const { isContractSent } = useSelector((state) => state?.contract || false);

  useEffect(() => {
    if (isContractSent) {
      dispatch(getCampaignRequestsForBrand(campaignID));
    }
  }, [isContractSent]);

  useEffect(() => {
    dispatch(getCampaignRequestsForBrand(req[0]?.campaign_id));
  }, [req]);

  const [iscontractPopup, setContractPopup] = useState(false);

  const handleContractPopup = (request) => {
    setCampaignID(request?.campaign_id);
    setContractPopup(true);
  };

  const handleContractPopupClose = () => {
    setContractPopup(false);
  };

  return (
    <div className="accepted-request">
      <Link to={`/campaign`} className="back-to-campaign-home-button">
        <img src={BackArrow} alt="back" />
        Back
      </Link>

      <div className="influencers-list">
        <div className="list-header">
          <span className="heading">
            Accepted req for this Campaign {`(${requests?.length})`}
          </span>
          <span className="sub-heading" onClick={openContracts}>
            View Sent Contracts
          </span>
        </div>

        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ width: `${columnWidths[0]}%` }}>Influencers</th>
              <th style={{ width: `${columnWidths[1]}%` }}>Genre</th>
              <th style={{ width: `${columnWidths[2]}%` }}>
                Followers
                <img src={Sort} alt="Sort Icon" className="sort-icon" />
              </th>
              <th
                style={{ width: `${columnWidths[3]}%` }}
                className="audience-gender"
              >
                Social Presence
              </th>
              <th style={{ width: `${columnWidths[4]}%` }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requests?.map(
              (request, index) =>
                request?.request_status === "Accepted" && (
                  <tr key={index}>
                    <td className="profile-info">
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage: `url(${User})`,
                        }}
                      />
                      <span className="influencer-name">{rows[0].name}</span>
                    </td>
                    <td className="genre">{rows[0].calories}</td>
                    <td className="followers">
                      <div className="followers-image">
                        <img src={People} alt="People Icon" />
                        <span>{rows[0].carbs}</span>
                      </div>
                    </td>

                    <td>
                      <div className="social-presence">
                        <span className="content-percentages">
                          Reach: <span>10%</span>
                        </span>
                        <span className="content-percentages">
                          Engage:<span>60%</span>
                        </span>
                        <span className="content-percentages">
                          CPP: <span>30%</span>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className="actions">
                        {request?.contract_status === "Pending" ? (
                          <div
                            className="accept"
                            onClick={() => handleContractPopup(request)}
                          >
                            <span>Send Contract</span>
                          </div>
                        ) : (
                          <div className="accept">
                            <span>Contract Sent</span>
                          </div>
                        )}
                        {iscontractPopup && (
                          <ContractPopup
                            onClose={handleContractPopupClose}
                            open={iscontractPopup}
                            request={request}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AcceptedRequest;
