import React, { useEffect, useState } from "react";
import "./InfluencerProfile.scss";
import instagram from "../assets/Instagram.svg";
import youtube from "../assets/YouTube.svg";
import NextArrow from "../assets/NextArrowWhite.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setUpInfluencerBankDetails,
  setUpInfluerncerProfile,
} from "../../actions/influencersAction";
import { useNavigate } from "react-router";

const InfluencerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user || {});
  const { isUpdated } = useSelector((state) => state.influencer || false);
  const [isTokenStored, setIsTokenStored] = useState(false);

  const [showPopup, setShowPopup] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [profileName, setProfileName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [email, setEmail] = useState(user?.email || "");
  const [location, setLocation] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");

  useEffect(() => {
    openPopup();
  }, []);

  useEffect(() => {
    if (isUpdated) {
      closePopup();
    }
  }, [isUpdated]);

  const openPopup = () => {
    setShowPopup(true);
    setCurrentStep(1);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const openInstaProfile = () => {
    console.log("currentStep", currentStep);
    setCurrentStep(5);
  };

  const showStep = (step) => {
    return currentStep === step ? "block" : "none";
  };

  const nextStep = () => {
    console.log(currentStep);
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      closePopup();
    }
  };

  const handleCategoryChange = (event) => {
    const newValue = event.target.value;
    setSelectedCategory((prevState) => [...prevState, newValue]);
  };

  const handleBankDetailsSubmit = () => {
    dispatch(
      setUpInfluerncerProfile({
        name: profileName,
        categories: selectedCategory,
        location: location,
        youtubeLink: youtubeProfile,
      })
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenStored = queryParams.get("isTokenStored");
    setIsTokenStored(tokenStored === "true");
  }, []);

  const handleInstagramConnect = () => {
    window.location.href =
      "https://www.facebook.com/v18.0/dialog/oauth?client_id=352288047403158&redirect_uri=http://localhost:5000/outbound_api/instagram-business/callback&scope=pages_show_list,business_management,instagram_basic,instagram_manage_insights,public_profile&response_type=code&state=Ro4W04G4ZN33tsSf6lG1710256419885";
  };

  return (
    <div className={`auto-open-popup ${showPopup ? "show" : ""}`}>
      {showPopup && (
        <div className="influencer-pop-up">
          <div className="container-pop-up">
            <div style={{ display: showStep(1) }} className="steps-pop-up">
              <div className="setup-profile">
                <h2 className="heading">Let’s setup your Profile Quickly!</h2>
                <div className="connect-with-socialmedia">
                  <button
                    className="connect-instagram button"
                    onClick={openInstaProfile}
                  >
                    Connect with Instagram
                    <img src={instagram} alt="instagram" />
                  </button>
                  <button className="connect-youtube button">
                    Connect with Youtube
                    <img src={youtube} alt="youtube" />
                  </button>
                </div>

                {/* <div className="fill-details">
                  <h3 className="input-heading">Basic Info.</h3>
                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Name"
                        onChange={(e) => setProfileName(e.target.value)}
                      />
                    </div>

                    <div class="custom-input-container">
                      <label for="category" class="custom-field-input-label">
                        Select Your Brand Category
                      </label>

                      <select
                        class="custom-profile-input-field"
                        type="text"
                        placeholder="Select a Category"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select a Category</option>
                        <option value="Apparel & Footwear">
                          Apparel & Footwear
                        </option>
                        <option value="Beauty & Wellness">
                          Beauty & Wellness
                        </option>
                        <option value="Food & Beverage">Food & Beverage</option>
                        <option value="Home & Living">Home & Living</option>
                        <option value="Electronics & Appliances">
                          Electronics & Appliances
                        </option>
                        <option value="Travel & Tourism">
                          Travel & Tourism
                        </option>
                        <option value="Education & Training">
                          Education & Training
                        </option>
                        <option value="Professional Services">
                          Professional Services
                        </option>
                        <option value="Healthcare & Wellness">
                          Healthcare & Wellness
                        </option>
                        <option value="Entertainment & Media">
                          Entertainment & Media
                        </option>
                        <option value="India-Specific Categories">
                          India-Specific Categories
                        </option>
                        <option value="Traditional Wear">
                          Traditional Wear
                        </option>
                        <option value="Handicrafts & Home Decor">
                          Handicrafts & Home Decor
                        </option>
                        <option value="Ayurvedic & Natural Products">
                          Ayurvedic & Natural Products
                        </option>
                        <option value="Street Food & Local Cuisine">
                          Street Food & Local Cuisine
                        </option>
                        <option value="Festival & Occasion Wear">
                          Festival & Occasion Wear
                        </option>
                        <option value="Bollywood & Entertainment">
                          Bollywood & Entertainment
                        </option>
                        <option value="Cricket & Sports">
                          Cricket & Sports
                        </option>
                        <option value="Spiritual & Religious Products">
                          Spiritual & Religious Products
                        </option>
                        <option value="Educational Resources">
                          Educational Resources
                        </option>
                        <option value="Rural & Agricultural Products">
                          Rural & Agricultural Products
                        </option>
                      </select>
                    </div>
                  </div>

                  <h3 className="input-heading">Contact Info.</h3>

                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your E-mail Address
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Email"
                        value={email}
                        readOnly
                      />
                    </div>

                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your Location
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Location"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>

                  <h3 className="input-heading">Socials</h3>

                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Instagram Profile Link
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Instagram"
                        onChange={(e) => setInstagramProfile(e.target.value)}
                      />
                    </div>

                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Youtube Profile Link
                        <span className="optional"> (Optional)</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Youtube"
                        onChange={(e) => setYoutubeProfile(e.target.value)}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>


            <div style={{ display: showStep(5) }} className="steps-pop-up">
              <div className="setup-profile">
                <h2 className="heading">Let’s setup your Profile Quickly!</h2>

                <div className="connect-with-socialmedia">
                  <button className="profile-instagram button">
                    InfluencerInstaID
                    <img src={instagram} alt="instagram" />
                  </button>
                </div>

                <div className="fill-details instaProfile">
                  <h3 className="input-heading">Basic Info.</h3>
                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Name"
                      />
                    </div>

                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your Content Genre
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Genre"
                      />
                    </div>
                  </div>

                  <h3 className="input-heading">Contact Info.</h3>

                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your E-mail Address
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Email"
                      />
                    </div>

                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Your Location
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Location"
                      />
                    </div>
                  </div>

                  <h3 className="input-heading">Socials</h3>

                  <div className="input-field">
                    <div className="input-container">
                      <label htmlFor="name" className="input-label">
                        Youtube Profile Link
                        <span className="optional"> (Optional)</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder="Youtube"
                      />
                    </div>
                  </div>

                  <div className="footer-container">
                    <span className="clearAll">Clear all</span>
                    <div className="footer-buttons">
                      <button className="save">Save</button>
                      <button className="next">
                        Next
                        <img src={NextArrow} alt="next" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfluencerProfile;
