import React from "react";
import "../Assets/css/index.css";
import aboutimg from ".././Assets/Img/bannerbg.jpeg";
import Mission from ".././Assets/Img/mission.png";
import Vision from ".././Assets/Img/vision.png";
import Goal from ".././Assets/Img/goal.png";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";

function About() {
  const missionvision = [
    {
      title: "Our Mission",
      img: Mission, // Replace with an actual img or image
      description:
        "To empower brands and influencers by creating a streamlined platform that fosters trust, facilitates meaningful collaborations, and maximizes the value of their resources, enabling them to achieve measurable success together.",
    },
    {
      title: "Our Vision",
      img: Vision, // Replace with an actual img or image
      description:
        "To be the global leader in influencer marketing, revolutionizing the way brands and creators collaborate by building a seamless, transparent, and inclusive ecosystem that drives growth and innovation",
    },
    {
      title: "Our Goal",
      img: Goal, // Replace with an actual img or image
      description:
        "To connect 1 million brands and influencers worldwide by 2030, fostering impactful collaborations through cutting-edge technology, data-driven insights, and a commitment to creating value for all stakeholders",
    },
  ];
  return (
    <>
      <div>
        <div className="about-hero-section">
          <div className="overlay-text">
            <h1>Connecting Brands & Influencers Seamlessly</h1>
            <hr className="underline" />
          </div>
        </div>
      </div>
      <div
        className="container-about-section my-5"
        style={{ marginLeft: "3%", marginRight: "3%" }}
      >
        <div className="row align-items-center">
          {/* Left Side: Text Content */}
          <div className="col-xs-12 col-sm-12 col-md-6">
            <h2 className="text-success">About us</h2>
            <p className="text-justify">
              The story of InSocialOrbit began during Harsh's time at MICA,
              where he explored the challenges in media and influencer
              marketing. He noticed that brands and influencers faced
              significant hurdles, such as finding reliable partners, dealing
              with fake followers, and overcoming language barriers. The process
              lacked a streamlined approach to connecting the right resources.
              Motivated to solve these issues, Harsh envisioned a platform that
              simplifies collaboration between brands and influencers.
              InSocialOrbit was created as a space where resources could be
              utilized efficiently. Brands can find influencers based on
              specific needs, set up campaigns, and measure results with ease.
              Influencers, on the other hand, gain access to genuine
              opportunities to showcase their talent. Today, InSocialOrbit
              empowers businesses and creators to grow together, making the most
              of their resources while fostering trust and meaningful
              collaborations.
            </p>
          </div>

          {/* Right Side: Image */}

          <div className="col-xs-12 col-sm-12 col-md-6">
            <div className="about-image-wrapper">
              <img
                src={aboutimg} // Replace with your actual image
                alt="About us"
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>
        <div className="container-mission-vission my-5 w-100 d-flex justify-content-center">
          <div className="row d-flex justify-content-around">
            {missionvision.map((item, index) => (
              <div
                key={index}
                className=" col-xl-4 col-xs-12 col-md-3 col-sm-6 d-flex"
              >
                <div className="card-body position-relative rounded-3">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="card-img-top"
                    style={{
                      height: "390px",
                      objectFit: "fill",
                      width: "90%",
                      marginLeft: "0%",
                    }}
                  />
                  <h5 className="about card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                    {item.title}
                  </h5>
                  <p className="About card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
