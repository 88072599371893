import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentDetails.scss";
import BackArrow from "../assets/BackArrow.svg";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import BankDetails from "./BankDetails";
import UPIDetails from "./UPIDetails";
import { useDispatch, useSelector } from "react-redux";
import { setUpInfluencerBankDetails } from "../../actions/influencersAction";
import { SETUP_INFLUENCER_BANK_DETAILS_RESET } from "../../constants/influencersConstants";

const PaymentDetails = ({ onBackButtonClick }) => {

  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bank_name: "",
    account_no: "",
    confirmAccountNumber: "",
    ifsc_code: "",
    branch_name: "",
  });

  const [upiDetails, setUpiDetails] = useState({
    upi_id: "",
  });

  const dispatch=useDispatch();

  const [bankAccountVisible, setBankAccountVisible] = useState(false);
  const [upiHandleVisible, setUpiHandleVisible] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const navigate = useNavigate();

  const {isUpdated}=useSelector(state=>state.influencer);

  const handleBackButtonClick = () => {
    navigate("/paymenthome");
  };

  useEffect(() => {
    if(isUpdated){
      navigate("/payment");
      dispatch({type:SETUP_INFLUENCER_BANK_DETAILS_RESET})
    }
  }, [isUpdated]);

  const handleBankAccountClick = () => {
    setBankAccountVisible(true);
    setUpiHandleVisible(false);
    setShowButtons(true);
  };

  const handleUpiHandleClick = () => {
    setUpiHandleVisible(true);
    setBankAccountVisible(false);
    setShowButtons(true);
  };

  const handleSaveClick = () => {
    console.log("getting bank details",upiDetails)
    if (bankAccountVisible) {
      dispatch(setUpInfluencerBankDetails(bankDetails));
    } else if (upiHandleVisible) {
      console.log(upiDetails);
      dispatch(setUpInfluencerBankDetails(upiDetails));
    }
  };

  const handleNextClick = () => {
    navigate("/payment");
  };

  return (
    <div className="payment-details">
      <button onClick={handleBackButtonClick}>
        <img src={BackArrow} alt="" />
        Back
      </button>
      <div className="bank-details" onClick={handleBankAccountClick}>
        {bankAccountVisible ? (
          <BankDetails bankDetails={bankDetails} setBankDetails={setBankDetails} />
        ) : (
          <>
            <AddCircleOutlineRoundedIcon
              className="hoverable-icon"
              style={{ fontSize: 55, color: "#E4688A" }}
            />
            <p>Add Bank Account</p>
          </>
        )}
      </div>
      <div className="separator">
        <hr /> <span>OR</span> <hr />
      </div>

      <div className="UPI-details" onClick={handleUpiHandleClick}>
        {upiHandleVisible ? (
          <UPIDetails  upiDetails={upiDetails} setUpiDetails={setUpiDetails}/>
        ) : (
          <>
            <AddCircleOutlineRoundedIcon
              className="hoverable-icon"
              style={{ fontSize: 55, color: "#E4688A" }}
            />
            <p>Add UPI Handle</p>
          </>
        )}
      </div>

      {showButtons && (
        <div className="action-buttons">
          <button className="save" onClick={handleSaveClick}>
            Save
          </button>
          <button className="next" onClick={handleNextClick}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
