import React from "react";
import loginimg from "../Assets/Img/loginsideimg.png";
import { useNavigate } from "react-router-dom";


function SignUp() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-signup p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-12 col-md-6 col-sm-12 left">
              <img src={loginimg} alt="login-side-img" className="img" />
            </div>

            {/* Right Side (Login and Signup Buttons) */}
            <div className="col-12 col-md-6 col-sm-12 right">
              <h2>Welcome to InSocial Orbit</h2>
              <button
                type="button"
                className="btn btn-outline-success btn-lg mb-2"
                onClick={() => navigate("/signup-with-socials")} // Navigate to PhoneEmail page
              >
                Sign up as a Brand{" "}
              </button>

              <button
                type="button"
                className="btn btn-outline-success btn-lg"
                onClick={() => navigate("/signup-with-socials")} // Navigate to PhoneEmail page
              >
                Sign up as an Influencer{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
