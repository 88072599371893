import { useState } from "react";
import "../Assets/css/index.css";
import { FaTrash } from "react-icons/fa";

const initialNotifications = [
  {
    id: 1,
    icon: "₹",
    message: "Payment of INR 5,000 received from Puma on 23rd Dec 2023",
    timestamp: "Wed, 1:20 PM",
    type: "payment",
  },
  {
    id: 2,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "Tue, 1:20 PM",
    type: "campaign",
  },
  {
    id: 3,
    icon: "✓",
    message: "Flipkart Approved your Collaboration Request for BBD Sale",
    timestamp: "26th Jan, 1:20 PM",
    type: "approval",
  },
  {
    id: 4,
    icon: "👁",
    message: "The Souled Store Viewed Your Profile",
    timestamp: "24th Jan, 1:20 PM",
    type: "view",
  },
  {
    id: 5,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "26th Jan, 1:20 PM",
    type: "campaign",
  },
  {
    id: 6,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "26th Jan, 1:20 PM",
    type: "campaign",
  },
  {
    id: 7,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "26th Jan, 1:20 PM",
    type: "campaign",
  },
  {
    id: 8,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "26th Jan, 1:20 PM",
    type: "campaign",
  },
  {
    id: 9,
    icon: "📢",
    message: "Puma Posted a New campaign: Republic Day",
    timestamp: "26th Jan, 1:20 PM",
    type: "campaign",
  },
];

function Notifications() {
  const [notifications, setNotifications] = useState(initialNotifications);
  const [filter, setFilter] = useState("all");
  const [isOpen, setIsOpen] = useState(true);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  // Filter logic
  const filteredNotifications = notifications.filter((notification) => {
    if (filter === "all") return true;
    if (filter === "unread") return !notification.read;
    if (filter === "read") return notification.read;
    return true;
  });

  // Toggle individual notification selection
  const handleSelect = (id) => {
    setSelectedNotifications((prev) =>
      prev.includes(id) ? prev.filter((nid) => nid !== id) : [...prev, id]
    );
  };

  // Select/Deselect all notifications
  const handleSelectAll = () => {
    if (selectedNotifications.length === filteredNotifications.length) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(filteredNotifications.map((n) => n.id));
    }
  };

  // **Delete selected notifications**
  const handleDelete = () => {
    if (selectedNotifications.length === 0) return;
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedNotifications.length} notifications?`
    );
    if (confirmDelete) {
      setNotifications((prev) =>
        prev.filter(
          (notification) => !selectedNotifications.includes(notification.id)
        )
      );
      setSelectedNotifications([]); // Clear selection after deleting
    }
  };

  if (!isOpen) return null;

  return (
    <div className="notifications-container">
      {/* Header */}
      <div className="notifications-header">
        <h2>Notifications</h2>
        <button className="close-button" onClick={() => setIsOpen(false)}>
          {/* <FaTimes /> */}×
        </button>
      </div>

      {/* Actions: Select & Delete */}
      <div className="actions-container">
        {/* Filters */}
        <div className="notifications-filters">
          {["all", "unread", "read"].map((type) => (
            <button
              key={type}
              className={`filter-btn ${filter === type ? "active" : ""}`}
              onClick={() => setFilter(type)}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>

        {/* Select Mode Toggle */}
        {filteredNotifications.length > 0 && (
          <button
            className="select-button"
            onClick={() => setSelectMode(!selectMode)}
          >
            {selectMode ? "Cancel" : "Select"}
          </button>
        )}
      </div>

      {/* Select All & Delete */}
      {selectMode && filteredNotifications.length > 0 && (
        <div className="selection-bar">
          <div className="checkbox-container">
            <input
              id="squarecheckbox"
              type="checkbox"
              checked={
                selectedNotifications.length === filteredNotifications.length
              }
              onChange={handleSelectAll}
            />
            <label>Select All</label>
          </div>
          {selectedNotifications.length > 0 && (
            <button className="delete-button" onClick={handleDelete}>
              <FaTrash /> Delete
            </button>
          )}
        </div>
      )}

      {/* Notifications List */}
      <div className="notifications-list">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div key={notification.id} className="notification-item">
              {selectMode && (
                <input
                  id="squarecheckbox"
                  type="checkbox"
                  checked={selectedNotifications.includes(notification.id)}
                  onChange={() => handleSelect(notification.id)}
                />
              )}
              <div className={`notification-icon ${notification.type}`}>
                {notification.icon}
              </div>
              <div className="notification-content">
                <p>{notification.message}</p>
                <span className="notification-time">
                  {notification.timestamp}
                </span>
              </div>
              {/* <button className="more-options">⋮</button> */}
            </div>
          ))
        ) : (
          <p className="no-notifications">No notifications available</p>
        )}

        {filteredNotifications.map((notification) => (
          <div key={notification.id} className="notification-item">
            <div className={`notification-icon ${notification.type}`}>
              {notification.icon}
            </div>
            <div className="notification-content">
              <p>{notification.message}</p>
              <span className="notification-time">
                {notification.timestamp}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Notifications;
