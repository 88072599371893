import React, { useState } from "react";
import { Edit, Mail, Phone, MapPin } from "lucide-react";
import { Modal, Button, Form, Dropdown, DropdownButton } from "react-bootstrap";
import Profile from "../Assets/Img/ProfileLogo.png";

import instagram from "../Assets/Img/instagram.png";
import youtube from "../Assets/Img/youtube.png";
import facebook from "../Assets/Img/facebook.png";
import LinkedIn from "../Assets/Img/LinkedIn.png";
import setupprofileimg from "../Assets/Img/setupprofileimg.svg";
import "../Assets/css/index.css";

export default function MyProfile() {
  // State to manage modal visibility for both profile and setup modals
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showProfileSetupModal, setShowProfileSetupModal] = useState(false);

  const [step, setStep] = useState(1);

  // State for profile details
  const [selectedStates, setSelectedStates] = useState([]);
  const states = ["Maharashtra", "Gujarat", "Karnataka"];

  const [profileDetails, setProfileDetails] = useState({
    logo: Profile,
    name: "Puma",
    category: "Fashion",
    flat: "Ahmedabad",
    area: "sola",
    pin: "",
  });

  // State for about brand and location details
  const [aboutBrand, setAboutBrand] = useState(
    "Puma is a global sports brand that creates high-quality products for athletes and lifestyle enthusiasts. Puma combines performance and innovation."
  );
  const [locationDetails, setLocationDetails] = useState({
    address: "19, Nas Villas, Bandra east, Mumbai, 560098",
    city: "Mumbai",
    state: "Maharashtra",
    pin: "560098",
  });

  // Profile Setup State
  const [profileSetupDetails, setProfileSetupDetails] = useState({
    logo: Profile,
    name: "Puma",
    category: "Fashion",
    flat: "",
    area: "",
    street: "",
    state: "Maharashtra",
    pin: "",
    website: "",
    socialLinks: [{ platform: "Instagram", url: "" }],
    bio: "",
  });
  const cities = ["Mumbai", "Ahmedabad", "Bangalore"];
  const handleCheckboxChange = (value, type) => {
    if (type === "category") {
      setSelectedCategories((prev) =>
        prev.includes(value)
          ? prev.filter((v) => v !== value)
          : [...prev, value]
      );
    } else {
      setSelectedStates((prev) =>
        prev.includes(value)
          ? prev.filter((v) => v !== value)
          : [...prev, value]
      );
    }
  };

  // Toggle modal visibility for Edit Profile
  const handleCloseEditProfileModal = () => setShowEditProfileModal(false);
  const handleShowEditProfileModal = () => setShowEditProfileModal(true);

  // Toggle modal visibility for Profile Setup
  const handleShowProfileSetupModal = () => setShowProfileSetupModal(true);
  const handleCloseProfileSetupModal = () => {
    setShowProfileSetupModal(false);
    setStep(1); // Reset the step to 1
  };

  // Handle logo upload for profile setup
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileSetupDetails((prevState) => ({
          ...prevState,
          logo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle profile edit form submission
  const handleProfileSave = () => {
    setShowEditProfileModal(false);
  };

  // Handle form submission for profile setup
  const handleSaveProfileSetup = () => {
    // Logic to save the profile setup
    console.log("Profile setup saved:", profileSetupDetails);
    setShowProfileSetupModal(false);
  };

  // Handle step change in profile setup
  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // Handle form input changes for profile setup
  const handleInputChange = (e, field) => {
    setProfileSetupDetails({
      ...profileSetupDetails,
      [field]: e.target.value,
    });
  };

  // Handle social media link changes
  const handleSocialLinkChange = (e, index) => {
    const newSocialLinks = [...profileSetupDetails.socialLinks];
    newSocialLinks[index].url = e.target.value;
    setProfileSetupDetails({
      ...profileSetupDetails,
      socialLinks: newSocialLinks,
    });
  };

  // Add a new social media link input field
  const handleAddSocialLink = () => {
    setProfileSetupDetails({
      ...profileSetupDetails,
      socialLinks: [
        ...profileSetupDetails.socialLinks,
        { platform: "New Platform", url: "" },
      ],
    });
  };
  const handleOpenProfileSetupModal = () => {
    setShowEditProfileModal(false);
    setShowProfileSetupModal(true);
  };
  const categories = ["Fashion", "Sports", "Lifestyle", "Technology", "Beauty"];
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");

  // Ensure category is an array
  // const selectedCategories = Array.isArray(profileDetails.category)
  //   ? profileDetails.category
  //   : [];

  // const handleCheckboxChange = (category) => {
  //   setProfileDetails((prevState) => ({
  //     ...prevState,
  //     category: selectedCategories.includes(category)
  //       ? selectedCategories.filter((c) => c !== category) // Remove if already selected
  //       : [...selectedCategories, category], // Add if not selected
  //   }));
  // };

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open

  // const handleCheckboxChange = (value, type) => {
  //   if (type === "category") {
  //     setSelectedCategories((prev) =>
  //       prev.includes(value)
  //         ? prev.filter((v) => v !== value)
  //         : [...prev, value]
  //     );
  //   } else {
  //     setSelectedStates((prev) =>
  //       prev.includes(value)
  //         ? prev.filter((v) => v !== value)
  //         : [...prev, value]
  //     );
  //   }
  // };
  const [showNextModal, setShowNextModal] = useState(false);
  // Function to close the Edit Profile modal and open the Next modal
  const handleOpenNextModal = () => {
    setShowEditProfileModal(false);
    setShowNextModal(true);
  };

  // Function to close the Next modal
  const handleCloseNextModal = () => {
    setShowNextModal(false);
  };
  const [activeSection, setActiveSection] = useState("profile"); // "profile" or "editDetails"
  const handleProfilePicClick = () => {
    setActiveSection("profile");
  };

  const handleEditDetailsClick = () => {
    setActiveSection("editDetails");
  };
  const [tempProfileDetails, setTempProfileDetails] = useState(profileDetails); // Temporary state for changes

  const handleSaveProfile = () => {
    setProfileDetails(tempProfileDetails); // Save temp values to actual profile
    handleCloseEditProfileModal(); // Close modal
  };

  return (
    <div className="MyProfile">
      <div className="container-fluid">
        <div className="CardBox mx-auto">
          {/* Brand Header */}
          <div className="ProfileBanner d-flex justify-content-between align-items-center position-relative">
            <div className="PofileEditLogo">
              <img
                src={profileDetails.logo}
                alt="Puma Logo"
                className="img-fluid"
              />
              <div>
                <h1 className="h5">{profileDetails.name}</h1>
                <p className="text-muted">{profileDetails.category}</p>
              </div>
            </div>

            <button
              className="btn btn-link position-absolute top-0 end-0 text-muted"
              onClick={handleShowEditProfileModal}
            >
              <Edit className="h4 w-4" />
            </button>
          </div>

          <div className="ProfileDetails">
            {/* About Section */}
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <h2 className="h6">About Brand</h2>
                <button
                  className="btn btn-link text-muted"
                  onClick={handleShowEditProfileModal}
                >
                  <Edit className="h4 w-4" />
                </button>
              </div>
              <p className="text-muted">{aboutBrand}</p>
            </div>

            {/* Contact Section */}
            <div className="mb-4">
              <h2 className="h6">Contact</h2>
              <div className="list-unstyled">
                <div className="d-flex align-items-center mb-2">
                  <Mail className="me-2" />
                  <a href="mailto:Tonystark@gmail.com" className="text-muted">
                    Tonystark@gmail.com
                  </a>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Phone className="me-2" />
                  <a href="tel:+91 78932 56664" className="text-muted">
                    +91 78932 56664
                  </a>
                </div>
              </div>
            </div>

            {/* Location Section */}
            <div className="mb-4">
              <h2 className="h6">Location</h2>
              <div className="d-flex align-items-start">
                <MapPin className="me-2" />
                <p className="text-muted">{locationDetails.address}</p>
                <button
                  className="btn btn-link text-muted ms-2"
                  onClick={handleShowEditProfileModal}
                >
                  <Edit className="h4 w-4" />
                </button>
              </div>
            </div>

            {/* Social Links Section */}
            <div>
              <h2 className="h6">Social Links</h2>
              <div className="d-flex flex-wrap gap-3">
                <a
                  href="https://instagram.com/Puma.com"
                  className="d-flex align-items-center text-muted"
                >
                  <img src={instagram} className="img-fluid" />
                  &nbsp;&nbsp;
                  <span>Instagram/Puma.com</span>
                </a>
                <a
                  href="https://youtube.com/Puma.com"
                  className="d-flex align-items-center text-muted"
                >
                  <img src={youtube} className="img-fluid" />
                  &nbsp;&nbsp;
                  <span>Youtube/Puma.com</span>
                </a>
                <a
                  href="https://facebook.com/Puma.com"
                  className="d-flex align-items-center text-muted"
                >
                  <img src={facebook} className="img-fluid" />
                  &nbsp;&nbsp;
                  <span>Facebook/Puma.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          className="btn btn-primary mt-5"
          onClick={handleShowProfileSetupModal}
        >
          Set Up Your Profile
        </button>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        className="ModalBox"
        show={showEditProfileModal}
        onHide={handleCloseEditProfileModal}
      >
        <Modal.Body>
          {/* Close Button in the Top-Right Corner */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              onClick={handleCloseEditProfileModal}
            ></button>
          </div>
          <div className="TitleTag">Let’s setup your Profile!</div>

          <Form>
            {/* Logo Upload Section */}
            <Form.Group controlId="formLogo" className="text-center">
              <div className="d-flex flex-column align-items-center">
                <label htmlFor="formLogoUpload" style={{ cursor: "pointer" }}>
                  {profileDetails.logo ? (
                    <img
                      src={profileDetails.logo}
                      alt="Logo Preview"
                      className="rounded-circle"
                      style={{
                        width: "110px",
                        height: "110px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      className="rounded-circle d-flex align-items-center justify-content-center"
                      style={{
                        width: "150px",
                        height: "150px",
                        backgroundColor: "#f0f0f0",
                        textAlign: "center",
                      }}
                    >
                      No Image
                    </div>
                  )}
                </label>
                <Form.Control
                  id="formLogoUpload"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = () => {
                        setProfileDetails((prevState) => ({
                          ...prevState,
                          logo: reader.result,
                        }));
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  className="d-none"
                />
              </div>
            </Form.Group>

            {/* Two-Column Layout */}
            <div className="row">
              {/* Brand Name */}
              <div className="col-md-6">
                <Form.Group controlId="formName">
                  <Form.Label>Your Brand's Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={profileDetails.name}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>

              {/* Phone Number */}
              <div className="col-md-6">
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={profileDetails.phone}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        phone: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>

              {/* Email ID */}
              <div className="col-md-6">
                <Form.Group controlId="formEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    value={profileDetails.email}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>

              {/* Brand Category Dropdown */}
              <div className="col-md-6">
                <Form.Group controlId="formCategory">
                  <Form.Label>Select Your Brand's Category</Form.Label>
                  <Dropdown
                    show={openDropdown === "category"}
                    onToggle={(isOpen) =>
                      setOpenDropdown(isOpen ? "category" : null)
                    }
                    className="w-100"
                  >
                    <Dropdown.Toggle
                      variant="light"
                      className="w-100 drop text-start"
                    >
                      {selectedCategories.length > 0
                        ? selectedCategories.join(", ")
                        : "Select Categories"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 p-2">
                      {categories.map((category, index) => (
                        <div key={index} className="dropdown-item">
                          <Form.Check
                            type="checkbox"
                            label={category}
                            checked={selectedCategories.includes(category)}
                            onChange={() =>
                              handleCheckboxChange(category, "category")
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>

              {/* State Dropdown */}
              <div className="col-md-6">
                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Dropdown
                    show={openDropdown === "state"}
                    onToggle={(isOpen) =>
                      setOpenDropdown(isOpen ? "state" : null)
                    }
                    className="w-100"
                  >
                    <Dropdown.Toggle
                      variant="light"
                      className="w-100 drop text-start"
                    >
                      {selectedStates.length > 0
                        ? selectedStates[0]
                        : "Select State"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 p-2">
                      {states.map((state, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => setSelectedStates([state])} // Allow only one state selection
                        >
                          {state}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>

              {/* City Dropdown */}
              <div className="col-md-6">
                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Dropdown
                    show={openDropdown === "city"}
                    onToggle={(isOpen) =>
                      setOpenDropdown(isOpen ? "city" : null)
                    }
                    className="w-100"
                  >
                    <Dropdown.Toggle
                      variant="light"
                      className="w-100 drop text-start"
                    >
                      {selectedCity || "Select City"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 p-2">
                      {cities.map((city, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => setSelectedCity(city)}
                        >
                          {city}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>

              {/* Address */}
              <div className="col-md-6">
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={profileDetails.address}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        address: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>

              {/* PIN Code */}
              <div className="col-md-6">
                <Form.Group controlId="formPin">
                  <Form.Label>PIN Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={profileDetails.pin}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        pin: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </div>
            {/* Brand Bio (Full Width) */}
            <div className="col-md-12 mt-3">
              <Form.Group controlId="formBio">
                <Form.Label>Brand’s Bio</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={profileSetupDetails.bio}
                  onChange={(e) =>
                    setProfileSetupDetails({
                      ...profileSetupDetails,
                      bio: e.target.value,
                    })
                  }
                  placeholder="Tell us about your brand..."
                />
              </Form.Group>
            </div>
            {/* Buttons */}
            <div className="d-flex justify-content-between mt-4 mb-3">
              <button type="button" className="btn profile-clear-btn">
                Clear All
              </button>
              <div>
                <button
                  type="button"
                  className="profile btn btn-outline-success mx-2"
                  onClick={handleSaveProfile}
                >
                  Save
                </button>
                <button
                  type="button"
                  className=" profile btn btn-success"
                  onClick={handleOpenNextModal}
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Profile Setup Modal */}
      <Modal
        className="ModalBox"
        // show={showProfileSetupModal}
        // onHide={handleCloseProfileSetupModal}
        show={showNextModal}
        onHide={handleCloseNextModal}
      >
        <Modal.Body>
          {/* Close Button in the Top-Right Corner */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              onClick={handleCloseNextModal}
            ></button>
          </div>
          <div className="TitleTag">Let’s setup your Profile!</div>
          <Form>
            <Form.Group controlId="formWebsite">
              <Form.Label>Your Brand’s Website (Optional)</Form.Label>
              <Form.Control
                type="text"
                value={profileSetupDetails.website}
                onChange={(e) => handleInputChange(e, "website")}
              />
            </Form.Group>

            <h5>Brand’s Social Media Links</h5>
            {profileSetupDetails.socialLinks.map((link, index) => (
              <Form.Group
                key={index}
                controlId={`formSocialLink${index}`}
                className="position-relative"
              >
                <Form.Label>{link.platform}</Form.Label>
                <div className="row align-items-center">
                  {/* Instagram Field */}
                  <div className="col-md-4">
                    <Form.Group controlId="formInstagram">
                      <div className="input-container">
                        <img
                          src={instagram}
                          alt="Instagram"
                          className="social-icon"
                        />
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="social-input"
                        />
                      </div>
                    </Form.Group>
                  </div>

                  {/* YouTube Field */}
                  <div className="col-md-4">
                    <Form.Group controlId="formInstagram">
                      <div className="input-container">
                        <img
                          src={youtube}
                          alt="Instagram"
                          className="social-icon"
                        />
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="social-input"
                        />
                      </div>
                    </Form.Group>
                  </div>

                  {/* Facebook Field */}
                  <div className="col-md-4">
                    <Form.Group controlId="formInstagram">
                      <div className="input-container">
                        <img
                          src={facebook}
                          alt="Instagram"
                          className="social-icon"
                        />
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="social-input"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 mt-3">
                    <Form.Group controlId="formInstagram">
                      <div className="input-container">
                        <img
                          src={LinkedIn}
                          alt="Instagram"
                          className="social-icon"
                        />
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="social-input"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            ))}
          </Form>

          <div className="NaxtPrive">
            {step > 1 && (
              <button className="Previous" onClick={handlePreviousStep}>
                Previous
              </button>
            )}
            &nbsp;&nbsp;
            {step < 2 && (
              <button className="Next" onClick={handleNextStep}>
                Next
              </button>
            )}
            &nbsp;&nbsp;
            {step === 2 && (
              <button className="Finish" onClick={handleSaveProfileSetup}>
                Finish
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
