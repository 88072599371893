import React from "react";
import "./auth.css";
import loginimg from "../Assets/Img/loginsideimg.png";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate(); // Hook to navigate between routes

  return (
    <>
      <div className="container-login p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-12 col-md-6 col-sm-12 col-xs-12 left">
              <img src={loginimg} alt="loginimg" className="img" />
            </div>

            {/* Right Side (Login and Signup Buttons) */}
            <div className="col-12 col-md-6 col-sm-12 col-xs-12 right">
              <h2>Welcome to InSocial Orbit</h2>
              <button
                type="button"
                className="btn btn-outline-success btn-lg mb-2"
                onClick={() => navigate("/phone-email")} // Navigate to SignupPage with login action
              >
                Login
              </button>

              <button
                type="button"
                className="btn btn-outline-success btn-lg"
                onClick={() => navigate("/signUp")} // Navigate to SignupPage with signup action
              >
                Signup
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
