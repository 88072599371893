import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./InsocialOrbit/Main/Home";
import About from "./InsocialOrbit/Main/About";
import Contact from "./InsocialOrbit/Main/Contact";
import PrivacyPolicy from "./InsocialOrbit/Main/PrivacyPolicy";
import TermsAndConditions from "./InsocialOrbit/Main/TermsAndConditions";
import UserAgreement from "./InsocialOrbit/Main/UserAgreement";
import CookiePolicy from "./InsocialOrbit/Main/CookiePolicy";

import Brand from "./InsocialOrbit/Main/Brand"; // Import the Brand component
import Influencer from "./InsocialOrbit/Main/Influencer"; // Import the Influencer component

import Header from "./InsocialOrbit/Main/Layout/Header";
import Footer from "./InsocialOrbit/Main/Layout/Footer";
import Navbar from "./InsocialOrbit/Layout/Navbar";
import Notification from "./InsocialOrbit/Main/Notification";
import Sidebar from "./InsocialOrbit/Layout/Sidebar";

import Login from "./InsocialOrbit/Auth/Login";
import LoginWithPhoneOrEmail from "./InsocialOrbit/Auth/LoginWithPhoneOrEmail";
import LoginWithPhone from "./InsocialOrbit/Auth/LoginWithPhone";
import LoginWithEmail from "./InsocialOrbit/Auth/LoginWithEmail";
import OtpVerification from "./InsocialOrbit/Auth/OtpVerification";
import SignUp from "./InsocialOrbit/Auth/SignUp";
import SignUpWith from "./InsocialOrbit/Auth/SignUpWith";

import { RoutesLink } from "./InsocialOrbit/Common/RoutesLink";
import CopyRights from "./InsocialOrbit/Main/CopyRIght";

import BrandHome from "./InsocialOrbit/Brand/BrandHome";
import Campaign from "./InsocialOrbit/Brand/Campaign/Campaign";
import Insights from "./InsocialOrbit/Brand/Insights/Insights";
import Message from "./InsocialOrbit/Brand/Message/Message";

import InfluencerDetail from "./InsocialOrbit/Influencer/InfluencerDetail";
import InfluencerStats from "./InsocialOrbit/Influencer/InfluencerStats";
import InstaAcoordian from "./InsocialOrbit/Main/InstaAcoordian";
import PaymentDashboard from "./InsocialOrbit/Influencer/PaymentDashboard";
import PaymentMethod from "./InsocialOrbit/Influencer/PaymentMethod";
import MyProfile from "./InsocialOrbit/Common/MyProfile";
import { GlobalContext } from "./utils/GlobalContext";
import CampaignCreate from "./InsocialOrbit/Brand/Campaign/CampaignCreate";
import CampaignDetails from "./InsocialOrbit/Brand/Campaign/CampaignDetails";

const componentsMap = {
  Home,
  About,
  Brand,
  Influencer,
  Login,
  LoginWithPhoneOrEmail,
  LoginWithPhone,
  LoginWithEmail,
  OtpVerification,
  SignUp,
  SignUpWith,
  Contact,
  PrivacyPolicy,
  Notification,
  TermsAndConditions,
  CopyRights,
  UserAgreement,
  CookiePolicy,
  BrandHome,
  Campaign,
  Insights,
  Message,
  InfluencerDetail,
  InfluencerStats,
  InstaAcoordian,
  CampaignDetails,
  PaymentDashboard,
  PaymentMethod,
  MyProfile,
  CampaignCreate,
};

// Private Layout (Sidebar and Navbar)
const PrivateLayout = ({ children }) => (
  <div className="row g-0">
    <div className="MainScreen">
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <div className="ChieldComponent">{children}</div>
      </div>
    </div>
  </div>
);

// Public Layout (Header and Footer)
const PublicLayout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);

function App() {
  const { isLogdin, setisLogdin } = useContext(GlobalContext); // Access context values

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      setisLogdin(true);
    } else {
      setisLogdin(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {RoutesLink.map((route) => {
          const Component = componentsMap[route.component];

          // Private route handling: only accessible if user is logged in
          if (route.isPrivate && !isLogdin) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<Navigate to="/login" />}
              />
            );
          }

          // Public route handling: show the route only if the user is not logged in for login-related paths
          if (!route.isPrivate && route.path === "/login" && isLogdin) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<Navigate to="/brand-home" />} // Redirect logged-in users to a brand home or dashboard
              />
            );
          }

          // For other routes, decide the layout and render the component
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.isPrivate ? (
                  <PrivateLayout>
                    <Component />
                  </PrivateLayout>
                ) : (
                  <PublicLayout>
                    <Component />
                  </PublicLayout>
                )
              }
            />
          );
        })}

        {/* Catch-all redirect to login if trying to access private route without authToken */}
        <Route path="/private/*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
