import { SEND_CONTRACT_TO_INFLUENCER_FAIL, SEND_CONTRACT_TO_INFLUENCER_REQUEST, SEND_CONTRACT_TO_INFLUENCER_RESET, SEND_CONTRACT_TO_INFLUENCER_SUCCESS, SEND_PAYMENT_TO_INFLUENCER_FAIL, SEND_PAYMENT_TO_INFLUENCER_REQUEST, SEND_PAYMENT_TO_INFLUENCER_RESET, SEND_PAYMENT_TO_INFLUENCER_SUCCESS } from "../constants/brandConstants";
import { SEND_CONTRACT_TO_BRAND_FAIL, SEND_CONTRACT_TO_BRAND_REQUEST, SEND_CONTRACT_TO_BRAND_RESET, SEND_CONTRACT_TO_BRAND_SUCCESS } from "../constants/influencersConstants";

export const sendContractReducer = (state = { }, action) => {
    switch (action.type) {
        case SEND_CONTRACT_TO_INFLUENCER_REQUEST:
        case SEND_CONTRACT_TO_BRAND_REQUEST:
        return {
            ...state,
            loading: true,
            isContractSent:false,
        };
        case SEND_CONTRACT_TO_INFLUENCER_SUCCESS:
        case SEND_CONTRACT_TO_BRAND_SUCCESS:
        return {
            ...state,
            loading: false,
            isContractSent: true,
        };
        case SEND_CONTRACT_TO_INFLUENCER_FAIL:
        case SEND_CONTRACT_TO_BRAND_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
            isContractSent: false,
        };
        case SEND_CONTRACT_TO_INFLUENCER_RESET:
        case SEND_CONTRACT_TO_BRAND_RESET:
        return {
            ...state,
            isContractSent: false,
        };
        default:
        return state;
    }
};

export const sendPaymentReducer = (state = { }, action) => {
    switch (action.type) {
        case SEND_PAYMENT_TO_INFLUENCER_REQUEST:
        return {
            ...state,
            loading: true,
            isPaymentSent:false,
        };
        case SEND_PAYMENT_TO_INFLUENCER_SUCCESS:
        return {
            ...state,
            loading: false,
            isPaymentSent: true,
        };
        case SEND_PAYMENT_TO_INFLUENCER_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
            isPaymentSent: false,
        };
        case SEND_PAYMENT_TO_INFLUENCER_RESET:
        return {
            ...state,
            isPaymentSent: false,
        };
        default:
        return state;
    }
}