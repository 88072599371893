import React from "react";
import Imgs from "../Assets/Img/Imgs.png";
import Users from "../Assets/Img/users.png";
import Heart from "../Assets/Img/Heart.png";
import HeartDark from "../Assets/Img/HeartDark.png";
import { useNavigate } from "react-router-dom";
import '../Assets/css/influencer.css'

const InfluencerCardList = ({ toggleFavorite, favorites, influencers }) => {
  const navigate = useNavigate()

  return (
    <div className="InfluancerCardView">
      <div className="influencer-list">
        {influencers.map((influencers) => (
          <div key={influencers.id} className="influencer-card" onClick={() => navigate("/influencerstats")}>
            <div className="InfluencerProfile">
              <img
                src={Imgs}
                alt={influencers.name}
                className="influencer-image"
              />
              <h4>{influencers.name}</h4>
            </div>
            <div className="InfluencerGenre">{influencers.category}</div>

            <div className="engagement">
              <span>{influencers.engagement}</span>
            </div>

            <div className="InfluencerFollowes">
              <span className="audience-icon">
                <img src={Users} className="img-fluid" />
              </span>{" "}
              {influencers.audience}
            </div>

            <div className="InfluencerEngagement">
              <p>Male: {influencers.genderStats.male}</p>
              <p>Female: {influencers.genderStats.female}</p>
              <p>Others: {influencers.genderStats.others}</p>
            </div>
            <div className="Likes">
              <img
                src={favorites.has(influencers.id) ? HeartDark : Heart}
                className="img-fluid"
                onClick={() => toggleFavorite(influencers.id)}
                alt="Favorite"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfluencerCardList;
