import React from 'react'
import '../Assets/css/influencer.css';

import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from "@mui/material";
import BackVector from '../Assets/Img/BackVector.svg';
import Mailbox from '../Assets/Img/mailbox.svg';
import LocationSVG from '../Assets/Img/LocationSVG.svg';
import Sampleimg from '../Assets/Img/sampleimg.png';
import InstagramIcon from '../Assets/Img/instagramICON.svg';
import YoutubeButton from '../Assets/Img/youtubeButton.svg';

import InstaAcoordian from '../Main/InstaAcoordian';

import YoutubeAccordian from '../Main/YoutubeAccordian';


function InfluencerStats() {
    const navigate = useNavigate();
    return (
        <div className="content">
            <button onClick={() => navigate("/brand-home")}>
                <img src={BackVector} alt="back" />
                Back
            </button>
            <Box className="stat-item">
                <div
                    className="contact-container"
                >
                    <Grid container>
                        {/* Contact Section */}
                        <Grid item xs={12} sm={4} md={4}>
                            <Box className="contact">
                                <Typography variant="h6">Contact</Typography>
                                <Box className="contact-details">
                                    <Box className="sub-div" display="flex" alignItems="center">
                                        <img src={Mailbox} alt="mail" />
                                        <small> johnmorgan@gmail.com</small>
                                    </Box>
                                    <Box className="sub-div" display="flex" alignItems="center">
                                        <img src={LocationSVG} alt="location" />
                                        <small> Andheri west, Mumbai</small>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Profile Section */}
                        <Grid item xs={12} sm={4} md={4}>
                            <Box className="profile" textAlign="center">
                                <Typography variant="h5">Cardi B</Typography>
                                <Box className="image-box" mt={2}>
                                    <img
                                        src={Sampleimg}
                                        alt="img"
                                        style={{ width: "100%", borderRadius: "8px" }}
                                    />
                                </Box>
                                <Typography variant="body2">Fashion</Typography>
                            </Box>
                        </Grid>

                        {/* Socials Section */}
                        <Grid item xs={12} sm={4} md={4}>
                            <Box className="contact">
                                <Typography variant="h6">Socials</Typography>
                                <Box className="contact-details">
                                    <Box className="sub-div" display="flex" alignItems="center">
                                        <img src={InstagramIcon} alt="instagram" />
                                        <small>john_morgan</small>
                                    </Box>
                                    <Box className="sub-div" display="flex" alignItems="center">
                                        <img
                                            src={YoutubeButton}
                                            alt="youtube"
                                            className="youtube"
                                        />
                                        <small> www.youtube.com/@realtalkclipp</small>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <InstaAcoordian />
                <YoutubeAccordian />
            </Box>
        </div>
    );
}

export default InfluencerStats
