import React from "react";
import "./PaymentHome.scss";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/Search.svg";
import HomeImg from "../assets/payment-home-img.png";

const Payment = () => {
  const navigate = useNavigate();

  const handleSearch = (event) => {
    console.log(event.target.value);
  };

  const handleButtonClick = () => {
    navigate("/paymentdetails");
  };

  return (
    <div className="payment-home-container">
      <div className="payment-header">
        <div className="payment-header-left">
          <span className="payment-heading">Payments</span>
          <span className="payment-subheading">
            Manage your billing and payment details
          </span>
        </div>
        <div className="payment-header-right">
          <div className="search">
            <img src={SearchIcon} alt="Search Icon" className="search-icon" />
            <input
              className="search-bar"
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div className="download">
            <img
              src={SearchIcon}
              alt="download Icon"
              className="download-icon"
            />
            <span className="download-text">Download PDF Report</span>
          </div>
        </div>
      </div>
      <div className="payment-body">
        <div className="payment-image">
          <img src={HomeImg} alt="" className="payment-home-img" />
        </div>
        <h3>Unlock faster payouts!</h3>
        <p>
          Adding your payment method lets you receive collaboration earnings
          from brands within hours. Don't miss out on timely payments!
        </p>
        <button className="add-payment-button" onClick={handleButtonClick}>
          Add Payment Method
        </button>
      </div>
    </div>
  );
};

export default Payment;
