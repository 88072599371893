import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap"; // Bootstrap dropdown
import "../Assets/css/layout.css";
import Profile from "../Assets/Img/Profile.png";
import notification from "../Assets/Img/notification.png";

function Navbar() {
  const today = new Date();
  const navigate = useNavigate();
  const location = useLocation();

  // Format the date and day
  const options = { weekday: "long", day: "numeric", month: "short" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Define page titles
  const pageTitles = {
    "/": "Home",
    "/brand-home": "Home",
    "/payment": "Payment",
    "/message": "Message",
    "/notifications": "Notifications",
    "/myprofile": "My Profile",
    "/settings": "Settings",
    "/messages/chat": "Chat",
    "/campaign": "Campaign",
    "/insights": "Insights",
    "/campaign-create": "campaign-create",
    "/influencerstats": "Influencerstats",
  };

  // Extract pathname and split it into parts
  const pathSegments = location.pathname.split("/").filter(Boolean); // Remove empty elements
  const mainPage = `/${pathSegments[0] || ""}`; // Main Page
  const childPage =
    pathSegments.length > 1 ? `/${pathSegments.join("/")}` : null; // Child Page

  // Get Titles
  const mainTitle = pageTitles[mainPage] || "Navbar";
  const childTitle =
    childPage && pageTitles[childPage] ? ` / ${pageTitles[childPage]}` : "";

  const confirmLogout = () => {
    setShowLogoutModal(false);
    localStorage.clear();
    navigate("/");
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        {/* Dynamic Page Title */}
        <h3 className="navbar-brand">
          {mainTitle}
          <span className="child-page">{childTitle}</span>
        </h3>

        <div className="collapse navbar-collapse justify-content-end">
          <form className="d-flex align-items-center">
            <h6 className="nav-date">{formattedDate}</h6>

            {/* Notification Icon */}
            <Link to="/notifications">
              <div className="NavbarNotificationImage">
                <img src={notification} className="img-fluid" alt="New Icon" />
              </div>
            </Link>

            {/* Profile Dropdown */}
            <div
              className="ProfileIcon"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <img src={Profile} className="img-fluid" alt="Profile Icon" />
              {showDropdown && (
                <div className="user-dropdown-menu">
                  <ul>
                    <li onClick={() => navigate("/myprofile")}>Profile</li>
                    <li onClick={() => navigate("/settings")}>Settings</li>
                    <li onClick={() => setShowLogoutModal(true)}>Logout</li>
                  </ul>
                </div>
              )}

              {/* Logout Confirmation Modal */}
              <Modal
                show={showLogoutModal}
                onHide={() => setShowLogoutModal(false)}
                centered
              >
                <Modal.Body className="text-center">
                  <h5>Leaving so soon?</h5>
                  <h5>Don't forget to save your progress before you go!</h5>
                  <div className="mt-4">
                    <Button
                      variant="danger"
                      onClick={confirmLogout}
                      className="me-2"
                      style={{ backgroundColor: "rgba(107, 142, 118, 1)" }}
                    >
                      Logout
                    </Button>
                    <Button
                      variant="btn-secondary"
                      onClick={() => setShowLogoutModal(false)}
                      style={{ border: "1px solid gray" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
