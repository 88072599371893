// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", serif;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 !important;
}

input:focus,
select:focus,
textarea:focus,
.form-control:focus {
    box-shadow: 0 0 0 !important;
    outline: none;
}

ul,
li {
    list-style: none;
    padding: 0;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6b8e76;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

a,
button {
    cursor: pointer;
}

ul,
li {
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,6BAA6B;;AAEjC;;AAEA;;;;;;;IAOI,oBAAoB;AACxB;;AAEA;;;;IAII,4BAA4B;IAC5B,aAAa;AACjB;;AAEA;;IAEI,gBAAgB;IAChB,UAAU;AACd;;AAEA,UAAU;AACV;IACI,UAAU;IACV,WAAW;AACf;;AAEA,UAAU;AACV;IACI,mBAAmB;AACvB;;AAEA,WAAW;AACX;IACI,mBAAmB;AACvB;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\nbody {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: \"Poppins\", serif;\n\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np {\n    margin: 0 !important;\n}\n\ninput:focus,\nselect:focus,\ntextarea:focus,\n.form-control:focus {\n    box-shadow: 0 0 0 !important;\n    outline: none;\n}\n\nul,\nli {\n    list-style: none;\n    padding: 0;\n}\n\n/* width */\n::-webkit-scrollbar {\n    width: 0px;\n    height: 0px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #6b8e76;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\na,\nbutton {\n    cursor: pointer;\n}\n\nul,\nli {\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
