// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upi-handle {
  height: 20rem;
  background: var(--Bg, #f9f5f6);
  width: 100%;
  padding: 0 1rem;
}
.upi-handle h2 {
  margin: 0.8rem 1rem;
  font-size: 1.5rem;
  color: #c03b5a;
  font-family: poppins;
}
.upi-handle .upi-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 1rem;
}
.upi-handle .upi-box input {
  width: 20rem;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #6B4750;
  color: #6B4750;
  font-family: poppins;
  outline: none;
}
.upi-handle .upi-box input:active .upi-handle .upi-box input:focus {
  outline: none;
}
.upi-handle .upi-box button {
  color: #c03b5a;
  font-family: poppins;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Payment/UPIDetails.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,eAAA;AACJ;AAGI;EACI,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AADR;AAIM;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AAFR;AAIQ;EACI,YAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EAEA,cAAA;EACA,oBAAA;EACA,aAAA;AAHZ;AAKc;EAEE,aAAA;AAJhB;AAQQ;EACI,cAAA;EACA,oBAAA;EACA,0BAAA;AANZ","sourcesContent":[".upi-handle{\n    height: 20rem;\n    background: var(--Bg, #f9f5f6);\n    width: 100%;\n    padding: 0 1rem;\n  \n\n\n    h2 {\n        margin:0.8rem 1rem ;\n        font-size: 1.5rem;\n        color: #c03b5a;\n        font-family: poppins;\n      }\n\n      .upi-box {\n        display: flex;\n        flex-direction: row;\n        gap: 10px;\n        margin: 0 1rem;\n\n        input {\n            width: 20rem;\n            border-radius: 10px;\n            padding: 8px;\n            border: 2px solid #6B4750;\n\n            color: #6B4750;\n            font-family: poppins;\n            outline: none;\n    \n              &:active\n              &:focus {\n                outline: none;\n              }\n        }\n\n        button {\n            color: #c03b5a;\n            font-family: poppins;\n            text-decoration: underline;\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
