export const SEND_CONTRACT_TO_INFLUENCER_REQUEST = "SEND_CONTRACT_TO_INFLUENCER_REQUEST";
export const SEND_CONTRACT_TO_INFLUENCER_SUCCESS = "SEND_CONTRACT_TO_INFLUENCER_SUCCESS";
export const SEND_CONTRACT_TO_INFLUENCER_FAIL = "SEND_CONTRACT_TO_INFLUENCER_FAIL";
export const SEND_CONTRACT_TO_INFLUENCER_RESET = "SEND_CONTRACT_TO_INFLUENCER_RESET";

export const GET_CONTRACTS_REQUEST = "GET_CONTRACTS_REQUEST";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_FAIL = "GET_CONTRACTS_FAIL";

export const SEND_PAYMENT_TO_INFLUENCER_REQUEST = "SEND_PAYMENT_TO_INFLUENCER_REQUEST";
export const SEND_PAYMENT_TO_INFLUENCER_SUCCESS = "SEND_PAYMENT_TO_INFLUENCER_SUCCESS";
export const SEND_PAYMENT_TO_INFLUENCER_FAIL = "SEND_PAYMENT_TO_INFLUENCER_FAIL";
export const SEND_PAYMENT_TO_INFLUENCER_RESET = "SEND_PAYMENT_TO_INFLUENCER_RESET";