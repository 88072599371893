import React, { useEffect, useState } from "react";
import "../Assets/css/brand.css";
import InfluencerCardList from "../Common/InfluencerCardList";
import Search from "../Assets/Img/search.png";
import Heart from "../Assets/Img/Heart.png";
import HeartDark from "../Assets/Img/HeartDark.png";
import Filter from "../Assets/Img/Filter.png";
import Sort from "../Assets/Img/sort.png";
import SortDown from "../Assets/Img/sort.png"; // Add a descending icon if needed
import View from "../Assets/Img/View.png";
import tableicon from "../Assets/Img/tableicon.png";
import InfluencerGridList from "../Common/InfluencerGridList";
import axiosMain from "./../http/axiosMain";
import users from "../Assets/Img/users.png";
import ProfileImg from "../Assets/Img/Imgs.png";
import { useNavigate } from "react-router-dom";

// const initialData = [
//   {
//     id: 1,
//     name: "John Morgan",
//     category: "Photography",
//     engagement: "4%",
//     audience: "120k",
//     genderStats: { male: "63%", female: "30%", others: "7%" },
//     image: "https://via.placeholder.com/50",
//   },
//   {
//     id: 2,
//     name: "Alice Smith",
//     category: "Fashion",
//     engagement: "5%",
//     audience: "90k",
//     genderStats: { male: "40%", female: "50%", others: "10%" },
//     image: "https://via.placeholder.com/50",
//   },
//   {
//     id: 3,
//     name: "Tom Brown",
//     category: "Travel",
//     engagement: "3%",
//     audience: "110k",
//     genderStats: { male: "70%", female: "20%", others: "10%" },
//     image: "https://via.placeholder.com/50",
//   },
//   {
//     id: 4,
//     name: "Emma Wilson",
//     category: "Lifestyle",
//     engagement: "6%",
//     audience: "150k",
//     genderStats: { male: "50%", female: "45%", others: "5%" },
//     image: "https://via.placeholder.com/50",
//   },
//   {
//     id: 5,
//     name: "Liam Johnson",
//     category: "Gaming",
//     engagement: "7%",
//     audience: "80k",
//     genderStats: { male: "80%", female: "15%", others: "5%" },
//     image: "https://via.placeholder.com/50",
//   },
// ];

function BrandHome() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});

  const navigate = useNavigate();
  const toggleFavorite = (id) => {
    const url = "/api/v1/liked-influencer";
    const payload = { influencer_id: id };

    axiosMain
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("API Response:", response.data);

          setFavorites((prevFavorites) => {
            const updatedFavorites = new Set(prevFavorites);
            if (updatedFavorites.has(id)) {
              updatedFavorites.delete(id);
            } else {
              updatedFavorites.add(id);
            }

            // Save updated favorites to local storage
            localStorage.setItem(
              "favoriteInfluencers",
              JSON.stringify([...updatedFavorites])
            );

            return updatedFavorites;
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Retrieve favorites from local storage on component mount
  useEffect(() => {
    const storedFavorites = JSON.parse(
      localStorage.getItem("favoriteInfluencers")
    );
    if (storedFavorites) {
      setFavorites(new Set(storedFavorites));
    }
  }, []);

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [totalInfluencers, setTotalInfluencers] = useState(
    filteredData?.length
  );
  const [selectedTab, setSelectedTab] = useState("home");
  const [favorites, setFavorites] = useState(new Set());
  const [sortParams, setSortParams] = useState({
    isEngagementSortedAsc: false,
    isEngagementSortedDesc: false,
    isFollowersSortedAsc: false,
    isFollowersSortedDesc: false,
  });
  const handleSortClick = (field) => {
    setSortParams((prevState) => {
      const currentAsc = prevState[`is${field}SortedAsc`];
      const currentDesc = prevState[`is${field}SortedDesc`];

      return {
        isEngagementSortedAsc:
          field === "Engagement" ? !currentAsc && !currentDesc : false,
        isEngagementSortedDesc: field === "Engagement" ? currentAsc : false,
        isFollowersSortedAsc:
          field === "Followers" ? !currentAsc && !currentDesc : false,
        isFollowersSortedDesc: field === "Followers" ? currentAsc : false,
      };
    });
  };

  const [selectedEngagementFilters, setSelectedEngagementFilters] = useState(
    new Set()
  );
  const [sortDirectionEngagement, setSortDirectionEngagement] = useState("asc");
  const [sortDirectionFollowers, setSortDirectionFollowers] = useState("asc");
  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
  };
  // const toggleFavorite = (id) => {
  //   const url = "/api/v1/liked-influencer";
  //   const payload = {
  //     influencer_id: id,
  //   };

  //   axiosMain
  //     .post(url, payload)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         console.log("API Response:", response.data);
  //         fetchInfluencers();
  //       } else {
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });

  //   // const updatedFavorites = new Set(favorites);
  //   // if (updatedFavorites.has(id)) {
  //   //   updatedFavorites.delete(id);
  //   // } else {
  //   //   updatedFavorites.add(id);
  //   // }
  //   // setFavorites(updatedFavorites);
  // };
  const showFavorites = () => {
    // Here you can filter the influencers by favorites
    const favoriteInfluencers = influencers.filter((influencer) =>
      favorites.has(influencer.id)
    );
    console.log(favoriteInfluencers); // This can be used to update the display of favorites
  };

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
  };
  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
    if (!isFilterPopupOpen) {
      setTotalInfluencers(influencers.length); // Show full count when filter opens
    }
  };

  const handleClearFilters = () => {
    setSelectedEngagementFilters(new Set());
    setFilteredData(influencers); // Reset data
    setTotalInfluencers(influencers.length); // Reset count
  };

  const [isLikedFilter, setIsLikedFilter] = useState([]);

  // const favoritesSort = () => {};

  useEffect(() => {
    const fetchData = async () => {
      if (!searchTerm) {
        fetchInfluencers();
        return;
      }

      setLoading(true);

      try {
        const response = await axiosMain.get(
          `/api/v1/search-influencer?search=${searchTerm}`
        );
        setInfluencers(response.data.influencers); // Assuming the response contains the data directly
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Debounce to avoid making too many requests on every keystroke
    const timer = setTimeout(() => fetchData(), 500);

    // Cleanup on searchTerm change
    return () => clearTimeout(timer);
  }, [searchTerm]);

  // const toggleFilterPopup = () => {
  //   setIsFilterPopupOpen(!isFilterPopupOpen);
  // };

  const handleEngagementFilterChange = (range) => {
    setSelectedEngagementFilters((prevFilters) => {
      const newFilters = new Set(prevFilters);
      if (newFilters.has(range)) {
        newFilters.delete(range);
      } else {
        newFilters.add(range);
      }
      return newFilters;
    });
  };

  const [grideView, setgrideView] = useState(false);
  const toggleView = () => {
    setgrideView(!grideView);
  };

  const handleApplyFilters = () => {
    let filteredData = [...influencers];

    // Apply engagement filters
    if (selectedEngagementFilters.size > 0) {
      filteredData = filteredData.filter((influencer) => {
        const engagement = influencer.engagement;

        // Only process if engagement is a number
        if (typeof engagement === "number") {
          // Check the engagement against selected filters
          return Array.from(selectedEngagementFilters).some((range) => {
            const [min, max] = range.split("-").map(Number);
            return engagement >= min && engagement <= max;
          });
        }

        return false;
      });
    }

    setInfluencers(filteredData); // Update the filtered data
  };

  const handleSortEngagement = () => {
    setInfluencers((prevData) => {
      // Make sure to clone the array to avoid mutating the original data
      const sortedData = [...prevData].sort((a, b) => {
        // Attempt to parse the engagement values as floats
        const engagementA = parseFloat(a.engagement);
        const engagementB = parseFloat(b.engagement);

        // Check if both are valid numbers
        if (isNaN(engagementA) && isNaN(engagementB)) {
          return 0; // Both are NaN, no change in their order
        }
        if (isNaN(engagementA)) {
          // If A is NaN, move it to the bottom or top based on the sort direction
          return sortDirectionEngagement === "asc" ? 1 : -1;
        }
        if (isNaN(engagementB)) {
          // If B is NaN, move it to the bottom or top based on the sort direction
          return sortDirectionEngagement === "asc" ? -1 : 1;
        }

        // If both are valid numbers, sort them numerically based on direction
        return sortDirectionEngagement === "asc"
          ? engagementA - engagementB
          : engagementB - engagementA;
      });

      return sortedData;
    });

    // Toggle sort direction after each sort
    setSortDirectionEngagement((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const handleSortFollowers = () => {
    setInfluencers((prevData) => {
      // Ensure prevData is an array before proceeding
      if (!Array.isArray(prevData)) {
        console.error("prevData is not an array:", prevData);
        return []; // Return an empty array if prevData isn't iterable
      }

      // Sorting followers (after cleaning up "k" and commas)
      const sortedData = [...prevData].sort((a, b) => {
        // Safeguard against undefined or invalid "audience" values
        const cleanAudienceA = a.audience
          ? parseInt(a.audience.replace("k", "").replace(",", "")) * 1000
          : 0; // Default to 0 if "audience" is undefined or invalid

        const cleanAudienceB = b.audience
          ? parseInt(b.audience.replace("k", "").replace(",", "")) * 1000
          : 0; // Default to 0 if "audience" is undefined or invalid

        return sortDirectionFollowers === "asc"
          ? cleanAudienceA - cleanAudienceB
          : cleanAudienceB - cleanAudienceA;
      });

      return sortedData;
    });
  };

  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchInfluencers = async () => {
    try {
      const response = await axiosMain.get(
        "/api/v1/influencers?isfollowerssortedasc=false&isfollowerssorteddesc=false&isengagementsortedasc=false&isengagementsorteddesc=false"
      );
      setInfluencers(response.data.influencers);
      setTotalInfluencers(response.data.influencers.length);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching influencers:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfluencers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatFollowers = (followers) => {
    if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M"; // Format as "10.5M"
    } else if (followers >= 1000) {
      return (followers / 1000).toFixed(1) + "k"; // Format as "16.3k"
    } else {
      return followers; // No conversion, just return the number
    }
  };
  const filterOptions = {
    home: {
      label: "Engagement",
      options: [
        { value: "2-4", label: "2% - 4%" },
        { value: "5-7", label: "5% - 7%" },
        { value: "8-10", label: "8% - 10%" },
      ],
    },
    menu1: {
      label: "Reach",
      options: [
        { value: "0 - 100k", label: "(0 - 100k)" },
        { value: "100k - 200k", label: "(100k - 200k)" },
        { value: "200k - 200k", label: "(200k - 300k)" },
        { value: "300k - 400k", label: "(300k - 400k)" },
        { value: "400k - 500k", label: "(400k - 500k)" },
        { value: "500k - 600k", label: "(500k - 600k)" },
        { value: "600k - 700k", label: "(600k - 700k)" },
        { value: "700k - 800k", label: "(700k - 800k)" },
        { value: "800k - 900k", label: "(800k - 900k)" },
        { value: "900k+", label: "900k+" },
      ],
    },
    menu2: {
      label: "Language",
      options: [
        { value: "english", label: "English" },
        { value: "Hindi", label: "Hindi" },
        { value: "Gujarati", label: "Gujarati" },
        { value: "Punjabi", label: "Punjabi" },
        { value: "Telugu", label: "Telugu" },
        { value: "Tamil", label: "Tamil" },
        { value: "Malayalam", label: "Malayalam" },
        { value: "Kannada", label: "Kannada" },
      ],
    },
    menu3: {
      label: "Followers",
      options: [
        { value: "10k-100k", label: "1k - 10k" },
        { value: "100k-300k", label: "100k-300k" },
        { value: "300k-500k", label: "300k-500k" },
        { value: "500k-700k", label: "500k-700k" },
        { value: "700k-900k", label: "700k-900k" },
        { value: "900k-1M", label: "900k-1M" },
        { value: "1M-3M", label: "1M-3M" },
        { value: "3M+", label: "3M+" },
      ],
    },
    menu4: {
      label: "Genre",
      options: [
        { value: "Art & Photography", label: "Art & Photography" },
        {
          value: "Business & Entrepreneurship",
          label: "Business & Entrepreneurship",
        },
        { value: "Entertainment", label: "Entertainment" },
        { value: "Education", label: "Education" },
        {
          value: "Fitness, Fashion & Beauty",
          label: "Fitness, Fashion & Beauty",
        },
        { value: "Lifestyle & Travel", label: "Lifestyle & Travel" },
        { value: "Tech & Gaming", label: "Tech & Gaming" },
        { value: "Others", label: "Others" },
      ],
    },
    menu5: {
      label: "Age",
      options: [
        { value: "13-17", label: "13-17" },
        { value: "18-24", label: "18-24" },
        { value: "25-34", label: "25-34" },
        { value: "35-44", label: "35-44" },
        { value: "45-54", label: "45-54" },
        { value: "55-64", label: "55-64" },
        { value: "65+", label: "65+" },
      ],
    },
    menu6: {
      label: "City",
      options: [
        { value: "Mumbai", label: "Mumbai" },
        { value: "Delhi", label: "Delhi" },
        { value: "Bangalore", label: "Bangalore" },
        { value: "Chennai", label: "Chennai" },
        { value: "Kolkata", label: "Kolkata" },
        { value: "Jaipur", label: "Jaipur" },
        { value: "Hyderabad", label: "Hyderabad" },
        { value: "Other Cities", label: "Other Cities" },
      ],
    },
  };
  const handleFilterChange = (category, value) => {
    console.log(`Filter Applied - Category: ${category}, Value: ${value}`);
    console.log();
    // Logic to update filter state (example)
  };
  const favoritesSort = () => {
    const favoriteInfluencers = influencers.filter((influencer) =>
      favorites.has(influencer.influencer_id)
    );
    setInfluencers(favoriteInfluencers);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="Title">Influencers</h2>
        <div className="SearchFilterSection">
          <div className="SearchInput">
            <img src={Search} className="img-fluid" alt="Search Icon" />
            <input
              type="text"
              className="form-control"
              placeholder="Search for Influencer, Genre"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <button onClick={() => favoritesSort()}>
            Favorites{" "}
            <img src={HeartDark} className="img-fluid" alt="Heart Icon" />
          </button>
          <button onClick={toggleFilterPopup}>
            Filters <img src={Filter} className="img-fluid" alt="Filter Icon" />
          </button>
        </div>
      </div>
      <div className="row g-0">
        <div className="TableBox">
          <div className="InfluancerCardView Shorting influencer-list">
            <div className="influencer-card">
              <div className="InfluencerProfile">Influencers</div>
              <div className="InfluencerGenre">Genre</div>
              <div className="InfluencerGenre">Reach</div>
              {/* <div className="InfluencerGenre">Cpp</div> */}

              <div
                className="engagement"
                onClick={() => handleSortEngagement()}
              >
                Engagement %{" "}
                <img
                  src={sortDirectionEngagement === "asc" ? Sort : SortDown}
                  className="img-fluid Sort"
                  alt="Sort Icon"
                />
              </div>

              <div className="InfluencerFollowes" onClick={handleSortFollowers}>
                Followers{" "}
                <img
                  src={sortDirectionFollowers === "asc" ? Sort : SortDown}
                  className="img-fluid Sort"
                  alt="Sort Icon"
                />
              </div>

              <div className="InfluencerEngagement">Audience Gender</div>
              <div className="Likes">
                <img
                  src={grideView ? tableicon : View}
                  className="img-fluid"
                  onClick={() => toggleView()}
                />
              </div>
            </div>
          </div>

          {grideView ? (
            <InfluencerGridList
              influencers={influencers}
              favorites={favorites} // Pass favorites as a prop
              toggleFavorite={toggleFavorite} // Pass toggleFavorite function as a prop
            />
          ) : (
            <div className="TableBox">
              <div className="InfluancerCardView">
                <div className="influencer-list">
                  {influencers.map((influencer) => (
                    <div
                      key={influencer.influencer_id}
                      className="influencer-card"
                    >
                      <div className="InfluencerProfile">
                        <img
                          // src={
                          //   influencer.influencer_profile_url ||
                          //   "path/to/placeholder-image.png"
                          // }
                          src={ProfileImg}
                          className="img-fluid"
                          alt={influencer.influencer_name}
                        />
                        <h4>{influencer.influencer_name}</h4>
                      </div>
                      <div className="InfluencerGenre">{influencer.genre}</div>
                      <div className="InfluencerGenre">
                        {formatFollowers(influencer.reach)}
                      </div>
                      <div className="engagement">
                        <span>{influencer.engagement}</span>
                      </div>

                      <div className="InfluencerFollowes">
                        <span className="audience-icon">
                          <img src={users} className="img-fluid" />
                        </span>
                        {formatFollowers(influencer?.followers)}
                      </div>

                      <div className="InfluencerEngagement">
                        <p>Male: {influencer.male}</p>
                        <p>Female: {influencer.female}</p>
                        <p>Others: {influencer.other}</p>
                      </div>
                      <div className="Likes">
                        <img
                          src={
                            favorites.has(influencer.influencer_id)
                              ? HeartDark
                              : Heart
                          }
                          alt="Favorite Icon"
                          className="img-fluid"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents navigation when clicking the heart
                            toggleFavorite(influencer.influencer_id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isFilterPopupOpen && (
        <div className="filter-popup">
          <div className="filter-popup-content">
            <div className="FilterHeader">
              <li className="active">
                <h6>Filter</h6>
              </li>
              <div className="clear-btn">
                <span>Clear all</span>
              </div>
              <i
                className="fa fa-times"
                onClick={() => setIsFilterPopupOpen(false)}
              ></i>
            </div>

            <div className="Filters">
              <ul className="nav nav-pills">
                {Object.keys(filterOptions).map((key) => (
                  <li
                    key={key}
                    className={selectedTab === key ? "active bg-color" : ""}
                  >
                    <a onClick={() => handleTabChange(key)}>
                      {filterOptions[key].label}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="filter-options">
                {/* Conditional rendering of checkboxes based on selected tab */}
                {selectedTab === "home" && (
                  <>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="engagement-2-4"
                        onChange={() => handleEngagementFilterChange("2-4")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="engagement-2-4"
                      >
                        2% - 4%
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="engagement-5-7"
                        onChange={() => handleEngagementFilterChange("5-7")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="engagement-5-7"
                      >
                        5% - 7%
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="engagement-8-10"
                        onChange={() => handleEngagementFilterChange("8-10")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="engagement-8-10"
                      >
                        8% - 10%
                      </label>
                    </div>
                    {/* More engagement ranges */}
                  </>
                )}

                {/* Placeholder for other tabs (Reach, Language, etc.) */}
                {selectedTab === "menu1" && <div>Reach Filters</div>}
                {selectedTab === "menu2" && <div>Language Filters</div>}
                {selectedTab === "menu3" && <div>Followers Filters</div>}
                {selectedTab === "menu4" && <div>Genre Filters</div>}
                {selectedTab === "menu5" && <div>Age Filters</div>}
                {selectedTab === "menu6" && <div>City Filters</div>}

                <div>
                  <div className="BtnsGrp">
                    <span>{totalInfluencers} Influencers</span>
                    <button
                      type="button"
                      className="btn btn-apply"
                      onClick={() => handleApplyFilters()}
                      style={{ backgroundColor: "#6b8e76", color: "white" }}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandHome;
