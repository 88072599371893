import React, { useEffect, useState } from 'react';
import './ConnectWithSocialMedia.scss';
import instagram from '../assets/Instagram.svg';
import youtube from '../assets/YouTube.svg';

function ConnectWithSocialMedia() {

  const [showPopup, setShowPopup] = useState(true);
  const [isTokenStored, setIsTokenStored] = useState(false);
  console.log('isTokenStored', isTokenStored);

  useEffect(() => {
    openPopup();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('queryParams', queryParams);
    const tokenStored = queryParams.get('isTokenStored');
    setIsTokenStored(tokenStored === 'true');
  }, []);

  const openPopup = () => {
    setShowPopup(true);
  };

  const handleInstagramConnect = () => {
    window.location.href = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=352288047403158&redirect_uri=http://localhost:5000/outbound_api/instagram-business/callback&scope=pages_show_list,business_management,instagram_basic,instagram_manage_insights,public_profile&response_type=code&state=Ro4W04G4ZN33tsSf6lG1710256419885';
  };

  return (
    <div className={`connect-auto-open-popup ${showPopup ? 'show' : ''}`}>
      {showPopup && (
        <div className='influencer-pop-up'>
          <div className='container-pop-up'>
            <div className='steps-pop-up'>
              <div className='setup-profile'>
                <h2 className='heading'>Let’s setup your Profile Quickly!</h2>
                {isTokenStored ? (
                  <div className='auth-provided-message'>
                    Authentication already provided.
                  </div>
                ) : (
                  <div className='connect-with-socialmedia'>
                    <button className='connect-instagram button' onClick={handleInstagramConnect}>
                      Connect with Instagram
                      <img src={instagram} alt='instagram' />
                    </button>
                    <button className='connect-youtube button'>
                      Connect with Youtube
                      <img src={youtube} alt='youtube' />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConnectWithSocialMedia;
