import React from "react";
import profile from "../Assets/Img/Rectangle 890.png";
import verify from "../Assets/Img/verify.png";
import instagram from "../Assets/Img/instagram.png";
import youtube from "../Assets/Img/youtube.png";
import facebook from "../Assets/Img/facebook.png";
import HeartDark from "../Assets/Img/HeartDark.png";
import Heart from "../Assets/Img/Heart.png";
import { useNavigate } from "react-router-dom";

function InfluencerGridList({ influencers }) {
  const navigate = useNavigate()
  return (
    <div className="row g-3 TableBox" >
      {influencers.map((influencer) => (
        <div className="col-xl-3 col-lg-4 col-md-6 col-12" onClick={() => navigate("/influencerstats")}>
          <div className="InfluencerGridList">
            <div key={influencer.id} className="influencer-card">
              <div className="ImgBox">
                <div className="favorite-icon position-absolute top-0 start-0 m-2">
                  <img
                    src={Heart}
                    className="img-fluid"
                    // onClick={() => toggleFavorite(influencer.id)}
                    alt="Favorite"
                    style={{ cursor: "pointer", width: "35px", height: "35px" }} // Adjusted width and height to 30px
                  />
                </div>
                <img src={profile} className="img-fluid" alt="Profile" />
                <div className="Genuine">
                  {influencer.genuine_percentage}
                  <span className="Persant">%</span>
                  <h6>Genuine</h6>
                </div>
              </div>

              <div className="details">
                <div className="Name">
                  <h6>
                    {influencer.influencer_name}
                    <img src={verify} className="img-fluid" alt="Verified" />
                  </h6>

                  <span>{influencer.genre}</span>
                </div>
                <div className="SocialPresence">
                  <h5>Social Presence</h5>
                  <div className="Dats">
                    <div>
                      <span>Reach</span>
                      <strong>{influencer?.reach}</strong>
                    </div>
                    <div>
                      <span>Engage</span>
                      <strong>{influencer.engagement}</strong>
                    </div>
                    <div>
                      <span>CPP</span>
                      <strong>{influencer?.cpp}</strong>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Followers</h5>
                <div className="Dats flex gap-4">
                  <div className="flex items-center gap-2">
                    <img src={instagram} alt="Instagram" className="w-6 h-6" />
                    <div>
                      <strong className="follower-text-lg">
                        {influencer?.instagram}
                        10k
                      </strong>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={youtube} alt="YouTube" className="w-6 h-6" />
                    <div>
                      <strong className="follower-text-lg">
                        {influencer?.youtube}
                        20k
                      </strong>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={facebook} alt="Facebook" className="w-6 h-6" />
                    <div>
                      <strong className="follower-text-lg">
                        {influencer?.facebook}
                        30k
                      </strong>
                    </div>
                  </div>
                </div>

                <hr />
                <h5>Audience</h5>
                <div className="Dats">
                  <div>
                    <span>Male</span>
                    <strong>{influencer?.male}</strong>
                  </div>
                  <div>
                    <span>Female</span>
                    <strong>{influencer?.female}</strong>
                  </div>
                  <div>
                    <span>Others</span>
                    <strong>{influencer?.other}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default InfluencerGridList;
