import { useEffect, useState } from "react";
import "./Campaign.css";
import CampaignDetails from "../../Assets/Img/Form.png";
import Audience from "../../Assets/Img/People.png";
import Deliverables from "../../Assets/Img/Social Media.png";
import Overview from "../../Assets/Img/solar_checklist-outline.png";
import Compensationimg from "../../Assets/Img/Compensationimg.png";
import TargetAudienceimg from "../../Assets/Img/TargetAudienceimg.png";
import ExpectedDeliverablesimg from "../../Assets/Img/ExpectedDeliverablesimg.png";
import fileicon from "../../Assets/Img/fileicon.png";
import loadingGIF from "../../Assets/Img/loadingGIF.gif";
import axiosMain from "../../http/axiosMain";
import { format, addWeeks, differenceInWeeks, parseISO } from "date-fns";

export default function CampaignForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [file, setFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [timelineData, setTimelineData] = useState([]);
  const [selectedAge, setSelectedAge] = useState(null);
  const [timeLine, setTimeLine] = useState([]);

  const [CampaignFormCreate, setCampaignFormCreate] = useState({
    campaign_title: "",
    campaign_obj: "",
    description: "",
    compensation_type: "",
    budget: "",
    file: "",
    state: "",
    city: "",
    gender: "",
    language: "",
    age_group: selectedAge,
    start_date: startDate,
    end_date: endDate,
    timeline: [],
    saved_as_draft: false,
    flag_update: false,
  });

  // Handle file selection
  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      // Set up the reader to convert the file to a base64 string
      reader.onloadend = () => {
        const base64File = reader.result; // This is the base64-encoded string
        setFile(base64File); // Store the base64 string or do whatever you need with it
        setCampaignFormCreate({ ...CampaignFormCreate, file: base64File });
      };

      // Read the file as a DataURL (base64-encoded string)
      reader.readAsDataURL(selectedFile);
    }
  };
  // Handle drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setCampaignFormCreate({
      ...CampaignFormCreate,
      [name]: value,
    });
  };

  const [contentTypes, setContentTypes] = useState([
    {
      platform: "Instagram",
      items: [
        { id: "reels", label: "Reels", count: 1, checked: false },
        { id: "posts", label: "Posts", count: 1, checked: false },
        { id: "story", label: "Story", count: 1, checked: false },
      ],
    },
    {
      platform: "YouTube",
      items: [
        { id: "shorts", label: "Shorts", count: 1, checked: false },
        { id: "video", label: "Video", count: 1, checked: false },
        { id: "liveStream", label: "Live Stream", count: 1, checked: false },
      ],
    },
  ]);

  const handleNextbtn = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevbtn = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const totalSteps = 4;

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const ageGroups = [
    "13-17",
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65+",
  ];

  const handleAgeSelect = (age) => {
    setSelectedAge(age);
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    // Prepare the timeline in the correct format
    const formattedTimeline = Object.keys(timeLine).map((weekIndex) => ({
      week: parseInt(weekIndex) + 1,
      Instagram: {
        reels: timeLine[weekIndex]?.Instagram?.reels || 0,
        posts: timeLine[weekIndex]?.Instagram?.posts || 0,
        story: timeLine[weekIndex]?.Instagram?.story || 0,
      },
      YouTube: {
        shorts: timeLine[weekIndex]?.YouTube?.shorts || 0,
        videos: timeLine[weekIndex]?.YouTube?.videos || 0,
        live_stream: timeLine[weekIndex]?.YouTube?.live_stream || 0,
      },
    }));

    // Create the full payload
    const payload = {
      ...CampaignFormCreate,
      start_date: startDate,
      end_date: endDate,
      timeline: JSON.stringify(timeLine, null, 2),
    };

    try {
      const response = await axiosMain.post(
        "/api/v1/create-campaign",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure this header is included for file uploads
          },
        }
      );
      console.log("Campaign created successfully:", response.data);
    } catch (error) {
      console.error("Error creating campaign:", error);
    }
  };
  const handleSaveDraft = async (e) => {
    e.preventDefault(); // Prevent form submission

    // Prepare the timeline in the correct format
    const formattedTimeline = Object.keys(timeLine).map((weekIndex) => ({
      week: parseInt(weekIndex) + 1,
      Instagram: {
        reels: timeLine[weekIndex]?.Instagram?.reels || 0,
        posts: timeLine[weekIndex]?.Instagram?.posts || 0,
        story: timeLine[weekIndex]?.Instagram?.story || 0,
      },
      YouTube: {
        shorts: timeLine[weekIndex]?.YouTube?.shorts || 0,
        videos: timeLine[weekIndex]?.YouTube?.videos || 0,
        live_stream: timeLine[weekIndex]?.YouTube?.live_stream || 0,
      },
    }));

    // Create the full payload
    const payload = {
      ...CampaignFormCreate,
      start_date: startDate,
      end_date: endDate,
      timeline: JSON.stringify(timeLine, null, 2),
      saved_as_draft: true,
    };

    try {
      const response = await axiosMain.post(
        "/api/v1/create-campaign",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure this header is included for file uploads
          },
        }
      );
      console.log("Campaign created successfully:", response.data);
    } catch (error) {
      console.error("Error creating campaign:", error);
    }
  };

  const generateTimeline = () => {
    const { start_date, end_date } = CampaignFormCreate;

    // Parse the start and end date strings to Date objects
    const startDate = parseISO(start_date);
    const endDate = parseISO(end_date);

    // Calculate the number of weeks between the two dates
    const numWeeks = differenceInWeeks(endDate, startDate) + 1; // +1 to include both start and end week

    const newTimeline = [];

    for (let week = 1; week <= numWeeks; week++) {
      // Calculate the start and end date of the current week
      const weekStartDate = addWeeks(startDate, week - 1);
      const weekEndDate = addWeeks(startDate, week);

      // Format the dates for display or other needs
      const weekStartFormatted = format(weekStartDate, "dd/MM/yyyy");
      const weekEndFormatted = format(weekEndDate, "dd/MM/yyyy");

      // Push the new week data into the timeline
      newTimeline.push({
        week,
        YouTube: {
          shorts: 1,
          videos: 0,
          live_stream: 1,
        },
        Instagram: {
          posts: 0,
          reels: 1,
          story: 1,
        },
        week_dates: `${weekStartFormatted} to ${weekEndFormatted}`,
      });
    }

    // Update the CampaignFormCreate state with the generated timeline
    setCampaignFormCreate((prevState) => ({
      ...prevState,
      timeline: newTimeline,
    }));
  };

  // Effect hook to trigger when start or end date changes
  useEffect(() => {
    if (CampaignFormCreate.start_date && CampaignFormCreate.end_date) {
      generateTimeline();
    }
  }, [CampaignFormCreate.start_date, CampaignFormCreate.end_date]);

  const calculateWeeks = (start, end) => {
    const startDt = new Date(start);
    const endDt = new Date(end);
    const diffTime = Math.abs(endDt - startDt);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.ceil(diffDays / 7);
  };

  const handleCountChange = (week, platform, activity, increment) => {
    setTimeLine((prev) => {
      const updated = [...prev];
      let weekData = updated.find((w) => w.week === week);

      if (!weekData) {
        // Create a new entry for the week if it doesn't exist
        weekData = { week, Instagram: {}, YouTube: {} };
        updated.push(weekData);
      }

      // Ensure the platform and activity exist
      if (!weekData[platform][activity]) {
        weekData[platform][activity] = 0;
      }

      // Increment or decrement the count
      weekData[platform][activity] += increment ? 1 : -1;

      // Ensure count doesn't go below zero
      if (weekData[platform][activity] < 0) {
        weekData[platform][activity] = 0;
      }

      return updated.sort((a, b) => a.week - b.week);
    });
  };

  const weeksCount =
    startDate && endDate ? calculateWeeks(startDate, endDate) : 0;

  console.log(timeLine, "timeLine");

  return (
    <div className="campaignCreate">
      <div className="campaign-container">
        {/* Progress Steps */}
        {/* Form Content */}
        <div className="form-content">
          <div className="progress-steps mb-5">
            <div className={`step ${currentStep >= 1 ? "active" : ""}`}>
              <div className="step-icon">
                <img src={CampaignDetails} className="img-fluid" />
              </div>
              <span>Campaign Details</span>
            </div>
            <div className={`step ${currentStep >= 2 ? "active" : ""}`}>
              <div className="step-icon">
                <img src={Audience} className="img-fluid" />
              </div>
              <span>Audience</span>
            </div>
            <div className={`step ${currentStep >= 3 ? "active" : ""}`}>
              <div className="step-icon">
                <img src={Deliverables} className="img-fluid" />
              </div>
              <span>Deliverables</span>
            </div>
            <div className={`step ${currentStep >= 4 ? "active" : ""}`}>
              <div className="step-icon">
                <img src={Overview} className="img-fluid" />
              </div>
              <span>Overview</span>
            </div>
          </div>

          {/* Step 1: Campaign Details */}
          {currentStep === 1 && (
            <div>
              <h2 className="Title">Campaign</h2>

              <div className="upload-section mb-4">
                <label
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                    color: "rgba(103, 103, 103, 1)",
                    fontSize: "24px",
                  }}
                >
                  Campaign Poster
                </label>

                <div
                  className="upload-box"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  style={{
                    border: "2px dashed rgba(103, 103, 103, 1)",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={() => document.getElementById("file-upload").click()} // Make the whole box clickable
                >
                  <input
                    id="file-upload"
                    type="file"
                    accept=".jpg,.png"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />

                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected Preview"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "250px",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <>
                      <img
                        src={fileicon}
                        alt="Upload Icon"
                        className="upload-icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginBottom: "10px",
                        }}
                      />
                      <p className="upload-text">
                        Drag & Drop or{" "}
                        <strong
                          style={{ textDecoration: "underline", color: "blue" }}
                        >
                          Browse
                        </strong>
                      </p>
                      <p className="upload-text">
                        Supported files:{" "}
                        <strong
                          style={{
                            color: "rgba(103, 103, 103, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          JPG, PNG
                        </strong>
                      </p>
                      <p className="upload-text">
                        File Size Not More Than
                        <strong
                          style={{
                            color: "rgba(103, 103, 103, 1)",
                            fontWeight: "inherit",
                          }}
                        >
                          5MB
                        </strong>
                      </p>
                    </>
                  )}
                </div>

                {file && (
                  <div className="file-preview">
                    <p>Selected file: {file.name}</p>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label>Campaign Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Give a catchy and suitable title"
                    name="campaign_title"
                    value={CampaignFormCreate.campaign_title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label>Objective</label>
                  <select
                    className="form-select"
                    value={CampaignFormCreate.campaign_obj}
                    name="campaign_obj"
                    onChange={handleChange}
                  >
                    <option value="Increase brand awareness">
                      Increase brand awareness
                    </option>
                    <option value="Drive website traffic">
                      Drive website traffic
                    </option>
                    <option
                      value="generate
                      leads"
                    >
                      generate leads
                    </option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  placeholder="Describe your campaign"
                  rows={6}
                  value={CampaignFormCreate.description}
                  name="description"
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="row">
                <h2 className="Title">Payment and Compensation</h2>
                <div className="form-group col-12 col-md-6">
                  <label>Compensation Type</label>
                  <select
                    className="form-select"
                    name="compensation_type"
                    value={CampaignFormCreate.compensation_type}
                    onChange={handleChange}
                  >
                    <option value="Fitness">Fitness</option>
                    <option value="Sports">Sports</option>
                    <option value="Games">Games</option>
                  </select>
                </div>

                <div className="form-group col-12 col-md-6">
                  <label>Budget for the campaign</label>
                  <select
                    className="form-select"
                    onChange={handleChange}
                    name="budget"
                    value={CampaignFormCreate.budget}
                  >
                    <option>Select Budget</option>
                    <option value="$100 - $200">$100 - $200</option>
                    <option value="$200 - $300">$200 - $300</option>
                    <option value="$300 - $400">$300 - $400</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          {/* Step 2: Audience */}
          {currentStep === 2 && (
            <div>
              <h2 className="Title target-audience">Target Audience</h2>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label>State</label>
                  <select
                    className="form-select"
                    name="state"
                    value={CampaignFormCreate.state}
                    onChange={handleChange}
                  >
                    <option>Select the State</option>
                    <option value="GJ">Gujarat</option>
                    <option value="MH">Maharastra</option>
                    <option value="RJ">Rajasthan</option>
                    {/* Add your states here */}
                  </select>
                </div>

                <div className="form-group col-12 col-md-6">
                  <label>City</label>
                  <select
                    className="form-select"
                    name="city"
                    value={CampaignFormCreate.city}
                    onChange={handleChange}
                  >
                    <option>Select the City</option>
                    <option value="Bhavnagar">Bhavnagar</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Surat">Surat</option>
                  </select>
                </div>

                <div className="form-group col-12 col-md-6">
                  <label>Gender</label>
                  <select
                    className="form-select"
                    name="gender"
                    value={CampaignFormCreate.gender}
                    onChange={handleChange}
                  >
                    <option>Select the Gender you are Targeting</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="form-group col-12 col-md-6">
                  <label>Language</label>
                  <select
                    className="form-select"
                    name="language"
                    value={CampaignFormCreate.language}
                    onChange={handleChange}
                  >
                    <option>Select the Language</option>
                    <option value="En">English</option>
                    <option value="es">Spanish</option>
                    <option value="Fr">French</option>
                  </select>
                </div>

                <div className="age-group-container">
                  <h2 className="age mb-2">Age Group</h2>
                  <div className="age-buttons">
                    {ageGroups.map((age) => (
                      <button
                        name="age"
                        key={age}
                        className={`age-button ${
                          selectedAge === age ? "selected" : ""
                        }`}
                        onClick={() => handleAgeSelect(age)}
                      >
                        {age}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Step 3: Deliverables */}
          {currentStep === 3 && (
            <div
              className="CampaignTimeLine
             row g-0"
            >
              <div className="row">
                <div className="col-md-6 form-group col-12">
                  <label>Campaign Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min={new Date().toISOString().split("T")[0]}
                    value={startDate}
                    name="start_date"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-md-6 form-group col-12">
                  <label>Campaign End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min={startDate || new Date().toISOString().split("T")[0]}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    name="end_date"
                    disabled={!startDate}
                  />
                </div>
              </div>

              {weeksCount > 0 && (
                <div>
                  <ul className="nav nav-tabs" id="weekTabs" role="tablist">
                    {[...Array(weeksCount)].map((_, i) => (
                      <li className="nav-item" role="presentation" key={i}>
                        <button
                          className={`nav-link ${i === 0 ? "active" : ""}`}
                          id={`week-${i + 1}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#week-${i + 1}`}
                          type="button"
                          role="tab"
                          aria-controls={`week-${i + 1}`}
                          aria-selected={i === 0}
                        >
                          Week {i + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  <div className="tab-content mt-3" id="weekTabsContent">
                    {[...Array(weeksCount)].map((_, i) => (
                      <div
                        className={`tab-pane fade ${
                          i === 0 ? "show active" : ""
                        }`}
                        id={`week-${i + 1}`}
                        role="tabpanel"
                        aria-labelledby={`week-${i + 1}-tab`}
                        key={i}
                      >
                        <div className="row">
                          <div className="col-md-6 text-center">
                            <h6 className="mb-4">Instagram</h6>
                            {["reels", "posts", "story"].map((activity) => (
                              <div
                                key={activity}
                                className="d-flex align-items-center mb-2"
                              >
                                <span className="me-2 text-capitalize">
                                  {activity}
                                </span>
                                <div className="Counter">
                                  <button
                                    className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() =>
                                      handleCountChange(
                                        i + 1,
                                        "Instagram",
                                        activity,
                                        false
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <span className="me-2">
                                    {timeLine.find((w) => w.week === i + 1)
                                      ?.Instagram[activity] || 0}
                                  </span>
                                  <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() =>
                                      handleCountChange(
                                        i + 1,
                                        "Instagram",
                                        activity,
                                        true
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="col-md-6  text-center">
                            <h6 className="mb-4">YouTube</h6>
                            {["shorts", "videos", "live_stream"].map(
                              (activity) => (
                                <div
                                  key={activity}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <span className="me-2 text-capitalize">
                                    {activity}
                                  </span>

                                  <div className="Counter">
                                    <button
                                      className="btn btn-outline-secondary btn-sm me-2"
                                      onClick={() =>
                                        handleCountChange(
                                          i + 1,
                                          "YouTube",
                                          activity,
                                          false
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <span className="me-2">
                                      {timeLine.find((w) => w.week === i + 1)
                                        ?.YouTube[activity] || 0}
                                    </span>
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      onClick={() =>
                                        handleCountChange(
                                          i + 1,
                                          "YouTube",
                                          activity,
                                          true
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="mt-4">
                <h5>Timeline JSON:</h5>
                <pre>{JSON.stringify(timeLine, null, 2)}</pre>
              </div>
            </div>
          )}

          {/* Step 4: Overview */}
          {currentStep === 4 && (
            <div>
              {/* Overview content goes here */}
              <div className="secret-santa p-4">
                {/* Header Section */}
                <div className="header-section mb-4">
                  <h2 className="Title detail-title">
                    {CampaignFormCreate.campaign_title}
                  </h2>
                  <p className="subtitle">Increasing Brand Awareness</p>
                </div>

                {/* Description Section */}
                <div className="description-section mb-4">
                  <p>{CampaignFormCreate.description}</p>
                </div>

                {/* Features Section */}
                <div className="features-section mb-4">
                  <div className="feature-item">
                    <span className="bullet">•</span>
                    <p>
                      Optional Twist: Introduce a "Mystery Matchmaker" element
                      where team leaders anonymously pair Santas, adding a touch
                      of playful intrigue.
                    </p>
                  </div>
                  <div className="feature-item">
                    <span className="bullet">•</span>
                    <p>
                      Wishlists & Budgets: Encourage employees to create
                      wishlists and set comfortable spending limits to ensure
                      everyone feels included.
                    </p>
                  </div>
                  <div className="feature-item">
                    <span className="bullet">•</span>
                    <p>
                      Gift-Giving Inspiration: Provide curated gift guides and
                      exclusive discounts from local businesses to support our
                      community.
                    </p>
                  </div>
                </div>

                {/* Compensation Section */}
                <div className="info-section mb-4">
                  <h2>Compensation</h2>
                  <div className="info-content">
                    <img
                      src={Compensationimg}
                      alt="compensation"
                      className="info-icon"
                    />
                    <p>Flat fee and Product • {CampaignFormCreate.budget}</p>
                  </div>
                </div>

                {/* Deliverables Section */}
                <div className="info-section mb-4">
                  <h2>Expected Deliverables</h2>
                  <div className="info-content">
                    <img
                      src={ExpectedDeliverablesimg}
                      alt="deliverables"
                      className="info-icon"
                    />
                    <p>
                      2 Posts & 1 Reel • From {startDate} to {endDate}
                    </p>
                  </div>
                </div>

                {/* Target Audience Section */}
                <div className="info-section mb-4">
                  <h2>Target Audience</h2>
                  <div className="info-content">
                    <img
                      src={TargetAudienceimg}
                      alt="target"
                      className="info-icon"
                    />
                    <div className="audience-info">
                      <p>
                        {selectedAge} Years old • {CampaignFormCreate.gender} •{" "}
                        {CampaignFormCreate.city},{CampaignFormCreate.state}
                      </p>
                      <div className="tags">
                        <span className="tag">En</span>
                        <span className="tag">tag</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Auto-publish Section */}
                <div className="publish-section">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="autoPublish"
                    />
                    <label className="form-check-label" htmlFor="autoPublish">
                      Automatically Publish on Schedule Date
                    </label>
                  </div>
                  <div className="button-group">
                    <button
                      className="btn btn-success"
                      onClick={(e) => handleSaveDraft(e)}
                    >
                      Save Draft
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Publish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Buttons */}
          <div className="navigation-buttons d-flex align-item-end mt-4 justify-content-end">
            {currentStep > 1 && (
              <button
                className="prev-button btn btn-secondary"
                onClick={handlePrevbtn}
                style={{ backgroundColor: "rgba(107, 142, 118, 1)" }}
              >
                Previous
              </button>
            )}

            <button
              className="next-button btn btn-primary"
              onClick={handleNextbtn}
              disabled={currentStep === totalSteps} // Disable if at last step
              style={{ backgroundColor: " rgba(107, 142, 118, 1)" }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
