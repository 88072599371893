import React, { createContext, useState, useEffect } from "react";

// Create the context
export const GlobalContext = createContext();

// Create the GlobalProvider component
export const GlobalProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLogdin, setisLogdin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token); // Restore token if it's saved in localStorage
    }
    // Optionally, you can load user data here from localStorage or an API if you store it
  }, []);

  // Function to login (set auth token and store in localStorage)
  const login = (token, userData) => {
    localStorage.setItem("authToken", token); // Store the token in localStorage
    setAuthToken(token); // Set auth token in state
    setUserData(userData); // Store user data (if you want to use it globally)
  };

  // Function to logout (remove auth token and user data)
  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null); // Clear the token
    setUserData(null); // Clear user data
  };

  // Global state that can be accessed by any component
  return (
    <GlobalContext.Provider
      value={{ authToken, userData, login, logout, isLogdin, setisLogdin }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
