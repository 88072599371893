import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LandingPage.scss";
import Whitebadge from "../assets/whitebadge.svg";
import UserIcon from "../assets/User.svg";
import Socialmedia from "../assets/socialmedia.gif";

const LandingPage = () => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);

  const handleBrandButtonClick = () => {
    navigate("/sign-up/brand");
  };

  const handleInfluencerButtonClick = () => {
    navigate("/sign-up/influencer");
  };

  const handleSignUpClick = () => {
    setShowButtons(true);
    document.querySelector(".main-content").classList.add("signup-mode");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="landing-page">
      <nav className="navbar">
        <div className="logo-container">
          <h2 className="logo-heading">InSocial Orbit</h2>
        </div>
        <div className="tabs-container">
          <span>Why InSocial Orbit?</span>
          <span>How it works</span>
          <span>Privacy Policy</span>
          <span>Contact Us</span>
        </div>
      </nav>
      <div className="wrap-container">
        <div className="main-content">
          <div className={`content-left ${showButtons ? "expanded" : ""}`}>
            <h1>Welcome to</h1>
            <h1>InSocial Orbit</h1>

            {showButtons ? (
              <div className="action-buttons">
                <button
                  className="brand-button"
                  onClick={handleBrandButtonClick}
                >
                  Sign up as a Brand <img src={Whitebadge} alt="img" />
                </button>

                <button
                  className="influencer-button"
                  onClick={handleInfluencerButtonClick}
                >
                  Sign up as an Influencer <img src={UserIcon} alt="user" />
                </button>
              </div>
            ) : (
              <div className="primary-action-buttons">
                {/* <button className="sign-up-button" onClick={handleSignUpClick}>
                  Sign up! Its Free!
                </button> */}

                <button className="login-button" onClick={handleLoginClick}>
                  Log In From Here
                </button>
              </div>
            )}
          </div>

          <div className="content-right">
            <div className="image">
              <img
                className="https-lottiefiles"
                alt="http lottiefiles"
                src={Socialmedia}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
