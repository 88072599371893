import React, { useEffect, useRef } from "react";
import "./Insights.css";
import { Grid } from "@mui/material";
import { PieChart, Pie, Cell } from "recharts";
import Reels from "../../Assets/Img/reel.svg";
import Man from "../../Assets/Img/man.png";
import Trophy from "../../Assets/Img/Trophy.svg";
import Woman from "../../Assets/Img/woman.png";
import YoutubeShorts from "../../Assets/Img/youtubeshorts.svg";
import YoutubeLive from "../../Assets/Img/youtubelive.svg";
import YoutubeVideos from "../../Assets/Img/youtubevideos.svg";
import Posts from "../../Assets/Img/postframe.svg";
import Story from "../../Assets/Img/instastory.svg";
import { useNavigate } from "react-router-dom";
import { createElement } from "react";
import { Chart } from "chart.js";
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components
ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const data = [
  { name: "Ongoing", value: 20 },
  { name: "Scheduled", value: 10 },
  { name: "Completed", value: 20 },
];
const COLORS = ["#D0FCD7", "#FDD5DC", "#B9F3FC"];
const Bardata = [
  { name: "Likes", value: 6000, fill: "#FDD5DC" },
  { name: "Comments", value: 8000, fill: "#B3EBE8" },
  { name: "Saves", value: 4000, fill: "#D0FCD7" },
  { name: "Shares", value: 3000, fill: "#B9F3FC" },
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return createElement(
    "text",
    {
      x: x,
      y: y,
      fill: "#6B4750",
      textAnchor: x > cx ? "start" : "end",
      dominantBaseline: "central",
    },
    `${(percent * 100).toFixed(0)}%`
  );
};

function Insights() {
  const navigate = useNavigate();
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  const handleEngagementClick = () => {
    navigate("/engagement");
  };
  const handleReachClick = () => {
    navigate("/reach");
  };

  const handleInstagramClick = () => {
    const platform = "Instagram";
    navigate("/youtube", { state: { platform } });
  };

  const handleYouTubeClick = () => {
    const platform = "YouTube";
    navigate("/youtube", { state: { platform } });
  };
  useEffect(() => {
    if (chartContainer && chartContainer.current && Bardata) {
      const ctx = chartContainer.current.getContext("2d");

      // Create the Chart instance if it doesn't exist
      if (!chartInstance.current) {
        chartInstance.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: Bardata.map((entry) => entry.name),
            datasets: [
              {
                label: "Reach",
                data: Bardata.map((entry) => entry.value),
                backgroundColor: Bardata.map((entry) => entry.fill),
                borderColor: Bardata.map((entry) => entry.fill),
                borderWidth: 2,
                barThickness: 25,
              },
            ],
          },
          options: {
            indexAxis: "y",
            elements: {
              bar: {
                borderWidth: 2,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            defaultFontFamily: "'Poppins', sans-serif",
            defaultFontColor: "brown",
          },
        });

        // Ensure chartContainer.current exists before adding event listener
        if (chartContainer.current) {
          // Example event listener (you can replace this with your own logic)
          chartContainer.current.addEventListener("click", () => {
            console.log("Chart container clicked!");
          });
        }
      } else {
        // Update the Chart data if it already exists
        chartInstance.current.data.labels = Bardata.map((entry) => entry.name);
        chartInstance.current.data.datasets[0].data = Bardata.map(
          (entry) => entry.value
        );
        chartInstance.current.data.datasets[0].backgroundColor = Bardata.map(
          (entry) => entry.fill
        );
        chartInstance.current.data.datasets[0].borderColor = Bardata.map(
          (entry) => entry.fill
        );

        chartInstance.current.update(); // Update the chart
      }
    }
  }, [Bardata]);

  return (
    <>
      <div className="State wrapping-box">
        <div className="wrapping-box-content">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="first-container"
          >
            <Grid item xs={12} sm={12} md={4}>
              <div className="left-container">
                <div className="options-content">
                  <span className="platformtitle">Total Campaigns Posted</span>
                  <span className="view-all">
                    {/* View All <img src={ArrowSVG} alt="arrow" /> */}
                  </span>
                </div>
                <div className="pie-chart">
                  <PieChart width={332} height={332}>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={100}
                      fill="#6B4750"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
                <div className="bottom-content">
                  <span className="number-of-campaigns">
                    Total 50 campaigns posted
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="right-container"
              >
                <Grid item xs={12} sm={12} md={6} className="social-corner">
                  <div className="ig-box" onClick={handleInstagramClick}>
                    <div className="text-content">
                      <span>Instagram</span>
                    </div>
                    <div className="socials-stats">
                      <div className="reels">
                        <img src={Reels} alt="reels" />
                        <p>12</p>
                        <small>Reels</small>
                      </div>
                      <div className="reels">
                        <img src={Story} alt="story" />
                        <p>12</p>
                        <small> Story</small>
                      </div>
                      <div className="reels">
                        <img src={Posts} alt="posts" />
                        <p>12</p>
                        <small>Posts</small>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} className="social-corner">
                  <div className="ig-box">
                    <div className="text-content">
                      <span>Total Engagement Score</span>
                    </div>
                    <div
                      className="number-content"
                      onClick={handleEngagementClick}
                    >
                      <p>6%</p>
                      <small onClick={handleEngagementClick}>
                        Audience Engaged
                      </small>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} className="social-corner">
                  <div className="ig-box" onClick={handleYouTubeClick}>
                    <div className="text-content">
                      <span>Youtube</span>
                    </div>
                    <div className="socials-stats">
                      <div className="reels">
                        <img src={YoutubeShorts} alt="reels" />
                        <p>12</p>
                        <small>Shorts</small>
                      </div>
                      <div className="reels">
                        <img src={YoutubeVideos} alt="story" />
                        <p>12</p>
                        <small> Videos</small>
                      </div>
                      <div className="reels">
                        <img src={YoutubeLive} alt="posts" />
                        <p>12</p>
                        <small>Live</small>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} className="social-corner">
                  <div className="ig-box">
                    <div className="text-content">
                      <span>Total Reach</span>
                    </div>
                    <div className="number-content" onClick={handleReachClick}>
                      <p>10,234</p>
                      <small onClick={handleReachClick}>Audience Reached</small>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ width: "100%", height: "95%" }}>
            <canvas
              ref={chartContainer}
              width={1300}
              height={320}
              style={{
                width: "100%",
                height: "90%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
            />
          </div>

          <div className="third-container mt-3">
            <div className="container-fluid MainBox p-0">
              <div className="campaign-performance-box Box">
                <div className="platformtitle">
                  <span>Top Performing campaigns</span>
                </div>
                <div className="campaign-names">
                  <div className="campaign-date">
                    <ul>
                      <li className="name">Secret Santa</li>
                    </ul>
                    <p>12th Dec</p>
                  </div>
                  <div className="campaign-date">
                    <ul>
                      <li className="name">Great Indian Sale</li>
                    </ul>
                    <p>3rd Oct</p>
                  </div>
                  <div className="campaign-date">
                    <ul>
                      <li className="name">Big Billion Days</li>
                    </ul>
                    <p>26th Aug</p>
                  </div>
                </div>
              </div>
              <div className="influencer-performance-box Box">
                <div className="platformtitle">
                  <span>Top Performing Influencers</span>
                </div>
                <div className="influencer-wrapper-container mt-3">
                  <div className="influencer-1">
                    <div className="nameofinfluencer">
                      <img src={Man} alt="nameofinfluencer" />
                      <p>Gaurav Taneja</p>
                    </div>
                    <div className="audience-stats">
                      <div className="d-flex">
                        <p>6K</p>
                        <span>
                          Audience <br />
                          Reached
                        </span>
                      </div>
                      <div className="d-flex">
                        <p>10%</p>
                        <span>
                          Audience
                          <br /> Engaged
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="trophy-image">
                    <img src={Trophy} alt="trophy" />
                  </div>
                  <div className="influencer-1">
                    <div className="nameofinfluencer">
                      <img src={Woman} alt="nameofinfluencer" />
                      <p>Cardi B</p>
                    </div>
                    <div className="audience-stats">
                      <div className="d-flex">
                        <p>4K</p>
                        <span>
                          Audience <br />
                          Reached
                        </span>
                      </div>
                      <div className="d-flex">
                        <p>8%</p>
                        <span>
                          Audience
                          <br /> Engaged
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Insights;
