export const RoutesLink = [
  {
    path: "/",
    name: "Home",
    component: "Home",
    isPrivate: false,
    role: "",
  },
  {
    path: "/about",
    name: "About",
    component: "About",
    isPrivate: false,
  },
  { path: "/brand", name: "Brand", component: "Brand", isPrivate: false },
  {
    path: "/influencer",
    name: "Influencer",
    component: "Influencer",
    isPrivate: false,
  },
  { path: "/login", name: "Login", component: "Login", isPrivate: false },
  {
    path: "/contact",
    name: "Contact Us",
    component: "Contact",
    isPrivate: false,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: "PrivacyPolicy",
    isPrivate: false,
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: "CookiePolicy",
    isPrivate: false,
  },
  {
    path: "/terms-condition",
    name: "Terms & Conditions",
    component: "TermsAndConditions",
    isPrivate: false,
  },
  {
    path: "/user-agreement",
    name: "User Agreement",
    component: "UserAgreement",
    isPrivate: false,
  },
  { path: "/policy", name: "Policy", component: "Policy", isPrivate: false },
  {
    path: "/copy-rights",
    name: "Copy Rights",
    component: "CopyRights",
    isPrivate: false,
  },
  {
    path: "/brand-home",
    name: "Brand Home",
    component: "BrandHome",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/campaign",
    name: "Campaign",
    component: "Campaign",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/campaign-create",
    name: "CampaignCreate",
    component: "CampaignCreate",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/campaign-details/:id",
    name: "CampaignDetails",
    component: "CampaignDetails",
    isPrivate: true,
    role: "brand",
  },

  {
    path: "/influencerstats",
    name: "InfluencerStats",
    component: "InfluencerStats",
    isPrivate: true,
  },

  {
    path: "/insights",
    name: "Insights",
    component: "Insights",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/message",
    name: "message",
    component: "Message",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/phone-email",
    name: "phoneemail",
    component: "LoginWithPhoneOrEmail",
    isPrivate: false,
  },
  {
    path: "/phone",
    name: "loginwithphoone",
    component: "LoginWithPhone",
    isPrivate: false,
  },
  {
    path: "/email",
    name: "loginwithemail",
    component: "LoginWithEmail",
    isPrivate: false,
  },
  {
    path: "/otp",
    name: "otpverification",
    component: "OtpVerification",
    isPrivate: false,
  },
  {
    path: "/signUp",
    name: "signup",
    component: "SignUp",
    isPrivate: false,
  },
  {
    path: "/signup-with-socials",
    name: "SignUpWith",
    component: "SignUpWith",
    isPrivate: false,
  },
  {
    path: "/influencerdetail",
    name: "influencerdetail",
    component: "InfluencerDetail",
    isPrivate: false,
  },
  {
    path: "/payment",
    name: "PaymentDashboard",
    component: "PaymentDashboard",
    isPrivate: true,
  },
  {
    path: "/payment-method",
    name: "PaymentMethod",
    component: "PaymentMethod",
    isPrivate: true,
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: "MyProfile",
    isPrivate: true,
    role: "brand",
  },
  {
    path: "/notifications",
    name: "Notification",
    component: "Notification",
    isPrivate: true,
    role: "brand",
  },
];
