import React, { useEffect, useState } from "react";
import "./CampaignOverview.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Default_request from "../assets/Default_request.svg";
import Default_review_request from "../assets/Default_review_request.svg";
import Hand from "../assets/Hand.svg";
import Mediainfo from "../assets/Mediainfo.svg";
import Audience from "../assets/Audience.svg";
import BackArrow from "../assets/BackArrow.svg";
import { Link } from "react-router-dom";
import Scheduled from "../assets/Scheduled.svg";
import Location from "../assets/Location.svg";
import Calender from "../assets/Calender.svg";
import Ongoing from "../assets/Ongoing.svg";
import "../CampaignDetails/CampaignDetails.scss";
import User from "../assets/User.png";
import Filter from "../assets/Filter.svg";
import Sort from "../assets/Sort.svg";
import People from "../assets/People.svg";
import Accept from "../assets/Accepted.svg";
import Reject from "../assets/RejectRed.svg";
import Draft from "../assets/Draft.svg";
import CompletedIcon from "../assets/Completed.svg";
import {
  acceptCampaignRequestByBrand,
  getCampaignRequestsForBrand,
} from "../../actions/campaignAction";
import "../CampaignDetails/CampaignDetails.scss";
import ContractPopup from "../Contract/ContractPopup";

function CampaignOverview() {
  const location = useLocation();
  const { campaign } = location.state || {};
  const { user } = useSelector((state) => state?.user || {});
  const { requests } = useSelector(
    (state) => state?.brandCampaignRequests || []
  );
  const { isRequestSuccess } = useSelector(
    (state) => state?.brandCampaignAcceptance || false
  );
  const { isContractSent } = useSelector((state) => state?.contract || false);

  useEffect(() => {
    if (isContractSent) {
      dispatch(getCampaignRequestsForBrand(campaign?.campaign_id));
    }
  }, [isContractSent]);

  const dispatch = useDispatch();

  const calculateCounts = (timeline) => {
    const counts = {
      Instagram: {},
      YouTube: {},
    };

    timeline.forEach((week) => {
      Object.keys(week).forEach((platform) => {
        if (typeof week[platform] === "object") {
          Object.keys(week[platform]).forEach((item) => {
            counts[platform][item] =
              (counts[platform][item] || 0) + week[platform][item];
          });
        }
      });
    });

    return counts;
  };

  let formattedInstagramCounts = "";
  let formattedYouTubeCounts = "";

  if (campaign?.timeline) {
    const platformCounts = calculateCounts(campaign?.timeline);
    const formatPlatformCounts = (platform) => {
      const items = Object.keys(platformCounts[platform]);
      const nonZeroItems = items.filter(
        (item) => platformCounts[platform][item] !== 0
      );
      return nonZeroItems
        .map((item) => `${platformCounts[platform][item]} ${item}`)
        .join(", ");
    };

    formattedInstagramCounts = formatPlatformCounts("Instagram");
    formattedYouTubeCounts = formatPlatformCounts("YouTube");
  }

  function formatDateRange(startDate, endDate) {
    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `From ${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const formatGenders = (genderJson) => {
    try {
      const genderArray = JSON.parse(genderJson);

      if (Array.isArray(genderArray)) {
        return genderArray.join(" & ");
      } else if (typeof genderArray === "string") {
        return genderArray;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error parsing gender JSON:", error);
      return "";
    }
  };

  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray = JSON.parse(languagesJson);

      return languagesArray.map((language, index) => (
        <span
          key={index}
          style={{
            width: "40px",
            height: "23px",
            borderRadius: "5px",
            border: "0.5px solid rgba(0, 0, 0, 0.5)",
            background: "#fff",
            color: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            padding: "5px",
            lineHeight: "normal",
            marginRight: "5px",
          }}
        >
          {language.slice(0, 3)}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  const getCurrentDate = () => new Date();

  const isOngoing = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    let isOngoing =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate >= new Date(campaign.start_date) &&
      currentDate <= new Date(campaign.end_date);

    return isOngoing;
  };

  const isCompleted = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    const completed =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate > new Date(campaign.end_date);

    return completed;
  };

  const isSavedDraft = (campaign) => campaign.issavedasdraft === 1;

  const isScheduledForLater = (campaign) => campaign.isautopublished === 1;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0)];

  const countInstagramTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.Instagram?.reels || 0) +
        (weekData.Instagram?.posts || 0) +
        (weekData.Instagram?.story || 0);
      return acc;
    }, 0);
  };

  const countYouTubeTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.YouTube?.videos || 0) + (weekData.YouTube?.live_stream || 0);
      return acc;
    }, 0);
  };

  const columnWidths = [15, 13, 10, 10, 15];

  const ageGroups = campaign?.age_group || [];

  let minAge = Infinity;
  let maxAge = -Infinity;

  ageGroups.forEach((ageRange) => {
    const [start, end] = ageRange.split("-");
    const startAge = parseInt(start, 10);
    const endAge = parseInt(end, 10);

    minAge = Math.min(minAge, startAge);
    maxAge = Math.max(maxAge, endAge);
  });

  const consolidatedAgeRange = `${minAge}-${maxAge}`;

  const handleRequest = (requestId, status, campaign_id) => {
    dispatch(
      acceptCampaignRequestByBrand({
        request_id: requestId,
        action: status,
        campaign_id: campaign_id,
      })
    );
  };

  const navigate = useNavigate();
  const openAcceptedReq = () => {
    navigate("/acceptedrequest", { state: { requests } });
  };

  let acceptedrequests = 0;
  let pendingrequests = 0;

  requests.map((request) => {
    if (request.request_status === "Accepted") {
      acceptedrequests++;
    }
    if (request.request_status === "Pending") {
      pendingrequests++;
    }
  });

  console.log("pendingrequests", pendingrequests);
  console.log("acceptedrequests", acceptedrequests);

  useEffect(() => {
    dispatch(getCampaignRequestsForBrand(campaign?.campaign_id));
  }, []);

  useEffect(() => {
    if (isRequestSuccess) {
      dispatch(getCampaignRequestsForBrand(campaign?.campaign_id));
    }
  }, [isRequestSuccess]);

  return (
    <div className="campaign-overview-details-brand">
      <Link to="/campaign" className="back-to-campaign-home-button">
        <img src={BackArrow} alt="back" />
        Back
      </Link>
      <div className="campaign-overview-header">
        <span className="campaign-details-header">Campaign Details</span>
      </div>
      <div className="campiagn-content">
        <span className="campaign-title">{campaign?.campaign_title}</span>
        <span className="campaign-obj">{campaign?.campaign_obj}</span>
        <span className="desc">{campaign?.description}</span>
        <span className="compensation">Compensation</span>
        <div className="compensation-info">
          <img src={Hand} alt="Hand" />
          <span>{campaign?.compensation_type}</span>
          <span>•</span>
          <span>{campaign?.budget}</span>
        </div>
        <span className="deliverables">Expected Deliverables</span>
        <div className="deliverables-info">
          <img src={Mediainfo} alt="Mediainfo" />
          <span>
            {formattedYouTubeCounts && `${formattedYouTubeCounts} &`}
            {formattedInstagramCounts}
          </span>
          {formattedYouTubeCounts || formattedInstagramCounts ? (
            <span>•</span>
          ) : null}
          <span>
            {campaign
              ? formatDateRange(campaign?.start_date, campaign?.end_date)
              : ""}
          </span>
        </div>
        <span className="target-audience">Target Audience</span>
        <div className="target-audience-info">
          <img src={Audience} alt="Audience" />
          <span>
            {campaign?.age_group &&
              campaign.age_group.length > 0 &&
              consolidatedAgeRange}
          </span>
          <span>
            {campaign?.age_group && campaign.age_group.length > 0 && `•`}
          </span>
          <span>{campaign ? formatGenders(campaign.gender) : ""}</span>
          <span>{campaign?.gender && campaign.gender.length > 0 && ``}</span>
          <span>{user?.area}</span>
          <span>
            {campaign?.language && campaign.language.length > 0 && ``}
          </span>
          <span>{campaign ? formatLanguages(campaign.language) : ""}</span>
        </div>
      </div>
      <div className="influencer-campaign-content">
        <div className="campaign-poster">
          <div className="campaign-status">
            <span>{campaign?.campaign_title}</span>
            {isOngoing(campaign) && (
              <div className="status">
                <img src={Ongoing} alt="Ongoing Icon" />
                <span className="ongoing">Ongoing</span>
              </div>
            )}
            {isCompleted(campaign) && (
              <div className="status">
                <img src={CompletedIcon} alt="Completed Icon" />
                <span className="completed">Completed</span>
              </div>
            )}
            {isSavedDraft(campaign) && (
              <div className="status">
                <img src={Draft} alt="Draft Icon" />
                <span className="draft">Saved as Draft</span>
              </div>
            )}
            {isScheduledForLater(campaign) && (
              <div className="status">
                <img src={Scheduled} alt="Draft Icon" />
                <span className="scheduled">Scheduled for later</span>
              </div>
            )}{" "}
          </div>
          <div className="aim">{campaign?.campaign_obj}</div>
          <div className="required-content">
            {campaign.timeline && (
              <div className="instagram-total">
                <span>{`${countInstagramTotal(
                  campaign.timeline
                )} from Insta `}</span>
              </div>
            )}
            <span>•</span>
            {campaign.timeline && (
              <div className="youtube-total">
                <span>{`${countYouTubeTotal(campaign.timeline)} from YT`}</span>
              </div>
            )}
            {campaign.language && <span>•</span>}
            {campaign.language && (
              <div className="languages">
                {formatLanguages(campaign.language)}
              </div>
            )}
          </div>
          <div className="line"></div>
          <div className="location-calendar-info">
            <div className="calender-info">
              <img src={Calender} alt="Calender Icon" />
              <span>
                {(campaign?.start_date && campaign?.end_date && (
                  <span>
                    {formatDateRange(campaign.start_date, campaign.end_date)}
                  </span>
                )) || <span className="missing-info">Date missing</span>}
              </span>{" "}
            </div>
            <div className="location-info">
              <img src={Location} alt="Calender Icon" />
              <span>
                {(user?.area && <span>{user?.area}</span>) || (
                  <span className="missing-info">Location missing</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="influencers-list">
          <div className="list-header">
            <span className="heading">
              Collaboration Requests for this Campaign {`(${requests.length})`}
            </span>
            <span className="sub-heading" onClick={openAcceptedReq}>
              View all Accepted Req.
            </span>
          </div>
          {requests.length === 0 ? (
            <div className="no-request">
              <img src={Default_request} alt="No Request" />
              <span className="no-request-title">
                Grow your reach with exciting collaborations!
              </span>
              <span className="no-request-text">
                Start discovering fantastic brand partnerships. New requests
                arrive regularly - check back soon!
              </span>
            </div>
          ) : requests && requests.length > 0 && pendingrequests > 0 ? (
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: `${columnWidths[0]}%` }}>Influencers</th>
                  <th style={{ width: `${columnWidths[1]}%` }}>Genre</th>
                  <th style={{ width: `${columnWidths[2]}%` }}>
                    Followers
                    <img src={Sort} alt="Sort Icon" className="sort-icon" />
                  </th>
                  <th
                    style={{ width: `${columnWidths[3]}%` }}
                    className="audience-gender"
                  >
                    Social Presence
                  </th>
                  <th style={{ width: `${columnWidths[4]}%` }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map(
                  (request, index) =>
                    request.request_status === "Pending" && (
                      <tr key={index}>
                        <td className="profile-info">
                          <div
                            className="profile-image"
                            style={{ backgroundImage: `url(${User})` }}
                          />
                          <span className="influencer-name">
                            {rows[0].name}
                          </span>
                        </td>
                        <td className="genre">{rows[0].calories}</td>
                        <td className="followers">
                          <div className="followers-image">
                            <img src={People} alt="People Icon" />
                            <span>{rows[0].carbs}</span>
                          </div>
                        </td>
                        <td>
                          <div className="social-presence">
                            <span className="content-percentages">
                              Reach: <span>10%</span>
                            </span>
                            <span className="content-percentages">
                              Engage:<span>60%</span>
                            </span>
                            <span className="content-percentages">
                              CPP: <span>30%</span>
                            </span>
                          </div>
                        </td>
                        <td>
                          {request.request_status === "Pending" && (
                            <div className="actions">
                              <div
                                className="accept"
                                onClick={() =>
                                  handleRequest(
                                    request.request_id,
                                    "Accepted",
                                    campaign?.campaign_id
                                  )
                                }
                              >
                                <span>Accept</span>
                                <img src={Accept} alt="Accept Icon" />
                              </div>
                              <div
                                className="reject"
                                onClick={() =>
                                  handleRequest(
                                    request.request_id,
                                    "Rejected",
                                    campaign?.campaign_id
                                  )
                                }
                              >
                                <span>Reject</span>
                                <img src={Reject} alt="Reject Icon" />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          ) : (
            <div className="view-accpted-request">
              <img src={Default_review_request} alt="No Request" />
              <span className="view-accpted-title">All requests reviewed!</span>
              <span className="view-accpted-text">
                You can view your accepted collaborations by clicking the button
                below. Stay tuned for new requests!
              </span>
              <div className="view-accepted-button" onClick={openAcceptedReq}>
                View Accepted Req.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignOverview;
