import React, { useState } from "react";
import "../../Assets/css/index.css";
import footercall from "../../Assets/Img/footercall.png";
import footermail from "../../Assets/Img/footermail.png";
import footerLogo from "../../Assets/Img/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <footer className="custom-footer">
        <div className="container">
          {/* <div className="MainContainerPadding"> */}
          <div className="row ">
            {/* Left Section */}
            <div className="col-xl-8 col-xs-12 col-md-12 col-sm-12 mb-4 footer-about">
              <div className="footer-logo mb-3">
                <img src={footerLogo} alt="InSocialOrbit Logo" />
              </div>
              <p className="description">
                At InSocialOrbit, we connect influencers and brands, enabling
                seamless collaboration through <br />
                profile syncing, showcasing achievements, and campaign metrics.
              </p>
              <div className="contact-info">
                <div className="contact-box-call mb-2">
                  <p className="m-0">
                    <img
                      src={footercall}
                      alt="Phone Icon"
                      className="contact-icon"
                    />
                    &nbsp;{" "}
                    <Link
                      style={{
                        color: " rgba(255, 255, 255, 1)",
                        textDecoration: "none",
                      }}
                      to="tel:+919426026611"
                    >
                      +91 94260 26611
                    </Link>
                  </p>
                </div>
                <div className="contact-box-mail">
                  <p className="m-0">
                    <Link
                      to="mailto:support@insocialorbit.com"
                      className="text-decoration-none"
                      style={{
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    >
                      <img
                        src={footermail}
                        alt="Email Icon"
                        className="contact-icon"
                      />{" "}
                      &nbsp; support@insocialorbit.com{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-xl-4 col-xs-12 col-md-6 col-sm-12 all-links">
              <div className="row">
                {/* Quick Links */}
                <div className="col-xl-4 col-xs-12 col-md-3 col-sm-6 footer-links">
                  <h5 className=" links mb-3">Quick Links</h5>
                  <ul className="list-unstyled-footer p-0">
                    <li>
                      <Link to="/" className="text-decoration-none text-white">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className="text-decoration-none text-white"
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        className="text-decoration-none text-white"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6 col-xs-12 col-md-6 col-sm-6 mb-4 footer-legal">
                  <h5 className="links mb-3">Legal & Company Info</h5>
                  <ul className="list-unstyled-footer p-0">
                    <li>
                      <Link
                        to="/privacy-policy"
                        className="text-decoration-none text-white"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-condition"
                        className="text-decoration-none text-white"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/copy-rights"
                        className="text-decoration-none text-white"
                      >
                        Copy Rights
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cookie-policy"
                        className="text-decoration-none text-white"
                      >
                        Cookie Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* Footer Bottom */}
          <div className="footer-bottom text-center mt-2">
            <p className="m-0">Copyright by 2024 InSocial Orbit</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
