export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";

export const SET_TARGET_AUDIENCE_REQUEST = "SET_TARGET_AUDIENCE_REQUEST";
export const SET_TARGET_AUDIENCE_SUCCESS = "SET_TARGET_AUDIENCE_SUCCESS";
export const SET_TARGET_AUDIENCE_FAIL = "SET_TARGET_AUDIENCE_FAIL";

export const SET_CAMPAIGN_DATES_REQUEST = "SET_CAMPAIGN_DATES_REQUEST";
export const SET_CAMPAIGN_DATES_SUCCESS = "SET_CAMPAIGN_DATES_SUCCESS";
export const SET_CAMPAIGN_DATES_FAIL = "SET_CAMPAIGN_DATES_FAIL";

export const CAMPAIGN_OVERVIEW_REQUEST = "CAMPAIGN_OVERVIEW_REQUEST";
export const CAMPAIGN_OVERVIEW_SUCCESS = "CAMPAIGN_OVERVIEW_SUCCESS";
export const CAMPAIGN_OVERVIEW_FAIL = "CAMPAIGN_OVERVIEW_FAIL";

export const STORE_CAMPAIGN_ID_IN_REDUX = 'STORE_CAMPAIGN_ID_IN_REDUX';

export const SET_PUBLISH_STATUS_REQUEST = "SET_PUBLISH_STATUS_REQUEST";
export const SET_PUBLISH_STATUS_SUCCESS = "SET_PUBLISH_STATUS_SUCCESS";
export const SET_PUBLISH_STATUS_FAIL = "SET_PUBLISH_STATUS_FAIL";

export const GET_ALL_BRAND_CAMPAIGNS_REQUEST = "GET_ALL_BRAND_CAMPAIGNS_REQUEST";
export const GET_ALL_BRAND_CAMPAIGNS_SUCCESS = "GET_ALL_BRAND_CAMPAIGNS_SUCCESS";
export const GET_ALL_BRAND_CAMPAIGNS_FAIL = "GET_ALL_BRAND_CAMPAIGNS_FAIL";

export const SET_CAMPAIGN_ID_IN_REDUX = 'SET_EMAIL_IN_REDUX';

export const DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";

export const GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST = "GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST";
export const GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS = "GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS";
export const GET_CAMPAIGNS_FOR_INFLUENCER_FAIL = "GET_CAMPAIGNS_FOR_INFLUENCER_FAIL";

export const MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER = "MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER";
export const MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER = "MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER";
export const MAKE_CAMPAIGN_FAIL_BY_INFLUENCER = "MAKE_CAMPAIGN_FAIL_BY_INFLUENCER";
export const MAKE_CAMPAIGN_RESET_BY_INFLUENCER = "MAKE_CAMPAIGN_RESET_BY_INFLUENCER";

export const ACCEPT_CAMPAIGN_REQUEST_BY_BRAND = "ACCEPT_CAMPAIGN_REQUEST_BY_BRAND";
export const ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND = "ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND";
export const ACCEPT_CAMPAIGN_FAIL_BY_BRAND = "ACCEPT_CAMPAIGN_FAIL_BY_BRAND";

export const GET_CAMPAIGN_REQUESTS_FOR_BRAND = "GET_CAMPAIGN_REQUESTS_FOR_BRAND";
export const GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND = "GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND";
export const GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND = "GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND";

export const SAVE_CAMPAIGN_REQUEST = "SAVE_CAMPAIGN_REQUEST";
export const SAVE_CAMPAIGN_SUCCESS = "SAVE_CAMPAIGN_SUCCESS";
export const SAVE_CAMPAIGN_FAIL = "SAVE_CAMPAIGN_FAIL";
export const SAVE_CAMPAIGN_RESET = "SAVE_CAMPAIGN_RESET";

export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";

export const UPDATE_TARGET_AUDIENCE_REQUEST = "UPDATE_TARGET_AUDIENCE_REQUEST";
export const UPDATE_TARGET_AUDIENCE_SUCCESS = "UPDATE_TARGET_AUDIENCE_SUCCESS";
export const UPDATE_TARGET_AUDIENCE_FAIL = "UPDATE_TARGET_AUDIENCE_FAIL";

export const UPDATE_CAMPAIGN_DATES_REQUEST = "UPDATE_CAMPAIGN_DATES_REQUEST";
export const UPDATE_CAMPAIGN_DATES_SUCCESS = "UPDATE_CAMPAIGN_DATES_SUCCESS";
export const UPDATE_CAMPAIGN_DATES_FAIL = "UPDATE_CAMPAIGN_DATES_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";


