import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import './BankDetialsPopUp.scss';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BankDetialsPopUp = ({ open, onClose }) => {

  const {user}=useSelector(state=>state.user || {});
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="95%"
      maxHeight="95%"
    >
      <DialogContent>
        <div className="bankDetials-popup">
          <div className="bankDetials-popup-header">
            <span>Bank Details</span>
          </div>
          <div className="bankDetails-container">
            <div className="accout-holder-name">
                <div>
                    <span className="label">Account holder name</span><br/>
                    <span className="value">{user?.account_holder_name}</span>
                </div>
                <div>
                    <span className="label">Bank name</span><br/>
                    <span className="value">{user?.bank_name}</span>
                </div>
            </div>
            <div className="accout-holder-number">
                <div>
                    <span className="label">Account Number</span><br/>
                    <span className="value">{user?.account_no}</span>
                </div>
                <div>
                    <span className="label">IFSC code (India)</span><br/>
                    <span className="value">{user?.ifsc_code}</span>
                </div>
            </div>
          </div>
          <div className="btn">
            <button  className="edit-btn" onClick={() => navigate("/paymentverification") }>Edit</button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BankDetialsPopUp;
