import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "../Assets/css/layout.css";
import Logo from "../Assets/Img/logo.svg";
import Home from "../Assets/Img/Home.png";
import Campaign from "../Assets/Img/Campaign.png";
import Insights from "../Assets/Img/Insight.png";
import Message from "../Assets/Img/Message.png";

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar is closed initially on small screens
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);

  // Handle window resize to show/hide the toggle button
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 400);
    };

    window.addEventListener("resize", handleResize);

    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* Toggle button for small screens */}
      {isSmallScreen && (
        <button
          id="menu-toggle"
          className={`btn btn-secondary toggle-button ${
            isSidebarOpen ? "open" : "closed"
          }`}
          onClick={handleToggle}
        >
          <i
            id="toggleIcon"
            className={`fa ${
              isSidebarOpen ? "fa-angle-double-up" : "fa-angle-double-down"
            }`}
          ></i>
        </button>
      )}

      {/* Sidebar */}
      <div
        id="wrapper"
        className={`Sidebar ${isSidebarOpen && isSmallScreen ? "toggled" : ""}`}
      >
        {/* Logo */}
        <Link className="navbar-brand" to="/brand-home">
          <img src={Logo} className="img-fluid" alt="Logo" />
        </Link>

        {/* Sidebar Links with Icons */}
        <ul>
          <li>
            <Link to="/brand-home">
              <img src={Home} alt="Home" className="icon" />
              Home
            </Link>
          </li>
          <li>
            <Link to="/campaign">
              <img src={Campaign} alt="Campaign" className="icon" />
              Campaign
            </Link>
          </li>
          <li>
            <Link to="/insights">
              <img src={Insights} alt="Insights" className="icon" />
              Insights
            </Link>
          </li>
          <li>
            <Link to="/message">
              <img src={Message} alt="Message" className="icon" />
              Message
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
