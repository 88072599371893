import axios from 'axios';
import {SEND_CONTRACT_TO_INFLUENCER_REQUEST,SEND_CONTRACT_TO_INFLUENCER_SUCCESS,SEND_CONTRACT_TO_INFLUENCER_FAIL, SEND_PAYMENT_TO_INFLUENCER_REQUEST, SEND_PAYMENT_TO_INFLUENCER_SUCCESS, SEND_PAYMENT_TO_INFLUENCER_FAIL} from "../constants/brandConstants";

export const sendContractToInfluencer = (contractData) => async (dispatch) => {
    console.log("data gettin is this",contractData);
    dispatch({ type: SEND_CONTRACT_TO_INFLUENCER_REQUEST });
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/send-contract`, contractData, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        dispatch({ type: SEND_CONTRACT_TO_INFLUENCER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: SEND_CONTRACT_TO_INFLUENCER_FAIL, payload: error.response.data.error });
    }
}

export const sendPaymentToInfluencer = (paymentData) => async (dispatch) => {
    dispatch({ type: SEND_PAYMENT_TO_INFLUENCER_REQUEST });
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/send-payment`, paymentData, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        dispatch({ type: SEND_PAYMENT_TO_INFLUENCER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: SEND_PAYMENT_TO_INFLUENCER_FAIL, payload: error.response.data.error });
    }
}