import React, { useState } from "react";
import "./Message.css"; // Import the CSS file
import messageuser1 from "../../Assets/Img/message1.png"; // Import the profile picture
import messageuser2 from "../../Assets/Img/message2.png"; // Import the profile picture
import messagesend from "../../Assets/Img/messagesend.png";

export default function ChatInterface() {
  const [messages, setMessages] = useState([
    {
      id: 1,
      content: "Next time you'll be awake at this hour why not now",
      sender: "Suzana Colin",
      timestamp: "Sat 5:10 AM",
      isCurrentUser: false,
      senderProfilePic: messageuser1, // Correctly assign the profile pic path
    },
    {
      id: 2,
      content:
        "Didn't I tell you not to put your phone on charge just because it's the weekend?",
      sender: "Suzana Colin",
      timestamp: "Sat 5:10 AM",
      isCurrentUser: false,
      senderProfilePic: messageuser2, // Add profile pic here as well
    },
    {
      id: 3,
      content: "I woke up calm, put it on the charger, and slept early.",
      sender: "User",
      timestamp: "Sat 5:10 AM",
      isCurrentUser: true,
    },
    {
      id: 4,
      content: "While you win in love, you continue to win in...",
      sender: "User",
      timestamp: "Sat 5:10 AM",
      isCurrentUser: true,
    },
  ]);

  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const timestamp = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Add the new message with a new timestamp
    setMessages((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        content: newMessage,
        sender: "User",
        timestamp,
        isCurrentUser: true, // mark the message as sent by the current user
      },
    ]);

    setNewMessage(""); // Clear the input field
  };

  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="sidebar-search">
          <i className="fas fa-search"></i>
          <input type="text" placeholder="Search for Brands" />
        </div>

        <div className="sidebar-users">
          {Array.from({ length: 8 }).map((_, i) => (
            <div key={i} className="sidebar-user-item">
              <div className="sidebar-user-avatar">
                <img src={messageuser1} alt="User" />
              </div>
              <div className="sidebar-user-info">
                <div>
                  <h3>Christina Ker</h3>
                  <p>Thank a lot for your good recommendation...</p>
                </div>
                <div className="sidebar-user-time">
                  <span>Feb 15</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="main-chat-area">
        <div className="chat-header">
          <div className="chat-header-avatar">
            <img src={messageuser1} alt="User" />
          </div>
          <div className="chat-header-info">
            <h2>Suzana Colin</h2>
            <p>@suzana3356</p>
          </div>
        </div>

        <div className="chat-messages">
          {messages.map((message, index) => {
            const isReceiver = !message.isCurrentUser;
            const isLastReceiverMessage =
              isReceiver &&
              (index === messages.length - 1 ||
                messages[index + 1].isCurrentUser);

            return (
              <div
                key={message.id}
                style={{
                  display: "flex",
                  justifyContent: message.isCurrentUser
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                <div
                  className={`chat-message ${
                    message.isCurrentUser
                      ? "chat-message-user"
                      : "chat-message-other"
                  }`}
                  style={{
                    display: "flex", // Make the message and avatar align horizontally
                    alignItems: "center", // Vertically center the content
                  }}
                >
                  {/* Only show the avatar for the receiver's last message */}
                  {isReceiver && isLastReceiverMessage && (
                    <div
                      className="chat-message-avatar"
                      style={{ marginRight: "8px" }}
                    >
                      <img
                        src={message.senderProfilePic}
                        alt={message.sender}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  )}

                  <div className="chat-message-text">
                    <p>{message.content}</p>
                    {/* Show the receiver's timestamp only for the last message */}
                    {isReceiver && isLastReceiverMessage && (
                      <span className="chat-message-timestamp">
                        {message.timestamp}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="chat-footer">
          <div className="input-container">
            <input
              type="text"
              placeholder="Start a new message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <img
              src={messagesend} // Replace with your image path
              alt="Send"
              className="send-icon"
              onClick={handleSendMessage} // On click, call the send function
            />
          </div>
        </div>
      </div>
    </div>
  );
}
