import React from "react";
import "./UPIDetails.scss";
const UpiHandle = ({ onVerify, upiDetails, setUpiDetails }) => {

  const handleVerify = () => {
    onVerify();
  };

  const handleInputChange = (field, value) => {
    setUpiDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="upi-handle">
      <h2>UPI Handle</h2>
      <div className="upi-box">
        <input type="text" placeholder="Enter UPI Handle" value={upiDetails.upi_id}
            onChange={(e) => handleInputChange("upi_id", e.target.value)} />
        <button onClick={handleVerify}>Verify</button>
      </div>
    </div>
  );
};

export default UpiHandle;
