// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-details-container {
  height: -moz-fit-content;
  height: fit-content;
  background: var(--Bg, #f9f5f6);
  width: 100%;
  padding: 0 1rem;
}
.bank-details-container h2 {
  margin: 0.8rem 1rem;
  font-size: 1.5rem;
  color: #c03b5a;
  font-family: poppins;
}
.bank-details-container .labels {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.bank-details-container .labels .input-container {
  margin: 0.5rem 1rem;
}
.bank-details-container .labels .input-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #6B4750;
  font-family: poppins;
}
.bank-details-container .labels .input-container label span {
  opacity: 0.7;
}
.bank-details-container .labels .input-container input {
  width: 95%;
  padding: 8px;
  border: 1px solid #6B4750;
  border-radius: 5px;
  color: #6B4750;
  font-family: poppins;
  outline: none;
}
.bank-details-container .labels .input-container input:active .bank-details-container .labels .input-container input:focus {
  outline: none;
}
.bank-details-container .labels .input-container .error {
  border-color: #c03b5a;
}
.bank-details-container .labels .input-container .error-message {
  color: #c03b5a;
  font-size: 0.8rem;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Payment/BankDetails.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EAAA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,eAAA;AACJ;AAGI;EACE,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AADN;AAKI;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AAHN;AAMI;EACE,mBAAA;AAJN;AAMM;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;AAJR;AAMU;EACE,YAAA;AAJZ;AASM;EACE,UAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;EACA,aAAA;AAPR;AASU;EAEE,aAAA;AARZ;AAYM;EACE,qBAAA;AAVR;AAaM;EACE,cAAA;EACA,iBAAA;EACA,eAAA;AAXR","sourcesContent":[".bank-details-container {\n    height: fit-content;\n    background: var(--Bg, #f9f5f6);\n    width: 100%;\n    padding: 0 1rem;\n\n    \n  \n    h2 {\n      margin:0.8rem 1rem ;\n      font-size: 1.5rem;\n      color: #c03b5a;\n      font-family: poppins;\n    }\n\n\n    .labels {\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n      gap: 10px;\n    \n  \n    .input-container {\n      margin:0.5rem 1rem;\n  \n      label {\n        display: block;\n        font-weight: bold;\n        margin-bottom: 5px;\n        color: #6B4750;\n        font-family: poppins;\n\n          span {\n            opacity: 0.7;\n          }\n          \n      }\n  \n      input {\n        width: 95%;\n        padding: 8px;\n        border: 1px solid #6B4750;\n        border-radius: 5px;\n        color: #6B4750;\n        font-family: poppins;\n        outline: none;\n\n          &:active\n          &:focus {\n            outline: none;\n          }\n      }\n  \n      .error {\n        border-color: #c03b5a;\n      }\n  \n      .error-message {\n        color: #c03b5a;\n        font-size: 0.8rem;\n        margin-top: 5px;\n      }\n    }\n  }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
