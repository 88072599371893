import React, { useState } from "react";
import "./BankDetails.scss";

const BankDetails = ({ bankDetails, setBankDetails }) => {
  const [accountNumber, setAccountNumber] = useState(bankDetails.accountNumber);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [isAccountNumberMatch, setIsAccountNumberMatch] = useState(true);

  const handleConfirmAccountNumberChange = (event) => {
    const confirmNumber = event.target.value;
    setConfirmAccountNumber(confirmNumber);
    setIsAccountNumberMatch(confirmNumber === accountNumber);
  };

  const handleInputChange = (field, value) => {
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="bank-details-container">
      <h2>Bank Details</h2>
      <div className="labels">
        <div className="input-container">
          <label htmlFor="accountHolder">Account Holder Name</label>
          <input
            type="text"
            id="accountHolder"
            placeholder="Enter account holder name"
            value={bankDetails.account_holder_name}
            onChange={(e) => handleInputChange("account_holder_name", e.target.value)}
          />
        </div>
        <div className="input-container">
          <label htmlFor="bankName">Bank Name</label>
          <input
            type="text"
            id="bankName"
            placeholder="Enter bank name"
            value={bankDetails.bank_name}
            onChange={(e) => handleInputChange("bank_name", e.target.value)}
          />
        </div>
        <div className="input-container">
          <label htmlFor="accountNumber">Account Number</label>
          <input
            type="text"
            id="accountNumber"
            placeholder="Enter account number"
            value={accountNumber}
            onChange={(event) => {
              setAccountNumber(event.target.value);
              handleInputChange("account_no", event.target.value);
            }}
          />
        </div>
        <div className="input-container">
          <label htmlFor="confirmAccountNumber">Confirm Account Number</label>
          <input
            type="text"
            id="confirmAccountNumber"
            placeholder="Confirm account number"
            value={confirmAccountNumber}
            onChange={handleConfirmAccountNumberChange}
            className={isAccountNumberMatch ? "" : "error"}
          />
          {!isAccountNumberMatch && (
            <p className="error-message">Account numbers do not match</p>
          )}
        </div>
        <div className="input-container">
          <label htmlFor="ifscCode">
            IFSC Code<span>(India)</span>
          </label>
          <input
            type="text"
            id="ifscCode"
            placeholder="Enter IFSC code"
            value={bankDetails.ifscCode}
            onChange={(e) => handleInputChange("ifsc_code", e.target.value)}
          />
        </div>
        <div className="input-container">
          <label htmlFor="branch">
            Branch Name<span>(Optional)</span>
          </label>
          <input
            type="text"
            id="branch"
            placeholder="Enter branch name"
            value={bankDetails.branch_name}
            onChange={(e) => handleInputChange("branch_name", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
