import React, { useEffect, useState } from "react";
import Compensationimg from "../../Assets/Img/Compensationimg.png";
import ExpectedDeliverablesimg from "../../Assets/Img/ExpectedDeliverablesimg.png";
import TargetAudienceimg from "../../Assets/Img/TargetAudienceimg.png";
import { useParams } from "react-router-dom";
import axiosMain from "../../http/axiosMain";

function CampaignDetails() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedAge, setSelectedAge] = useState(null);
  const { id } = useParams();
  const [CampaignFormCreate, setCampaignFormCreate] = useState(null);
  const fetchCampaign = async () => {
    try {
      const response = await axiosMain.get("/api/v1/campaigns", {
        params: {
          campaign_id: id,
        },
      });
      setCampaignFormCreate(response.data);
    } catch (error) {
      console.error("Error fetching campaign:", error);
    }
  };
  useEffect(() => {
    fetchCampaign();
  }, []);

  return (
    <div>
      <div>
        {/* Overview content goes here */}
        <div className="secret-santa p-4">
          {/* Header Section */}
          <div className="header-section mb-4">
            <h2 className="Title detail-title">
              {CampaignFormCreate?.campaign_title}
            </h2>
            <p className="subtitle">Increasing Brand Awareness</p>
          </div>

          {/* Description Section */}
          <div className="description-section mb-4">
            <p>{CampaignFormCreate?.description}</p>
          </div>

          {/* Features Section */}
          <div className="features-section mb-4">
            <div className="feature-item">
              <span className="bullet">•</span>
              <p>
                Optional Twist: Introduce a "Mystery Matchmaker" element where
                team leaders anonymously pair Santas, adding a touch of playful
                intrigue.
              </p>
            </div>
            <div className="feature-item">
              <span className="bullet">•</span>
              <p>
                Wishlists & Budgets: Encourage employees to create wishlists and
                set comfortable spending limits to ensure everyone feels
                included.
              </p>
            </div>
            <div className="feature-item">
              <span className="bullet">•</span>
              <p>
                Gift-Giving Inspiration: Provide curated gift guides and
                exclusive discounts from local businesses to support our
                community.
              </p>
            </div>
          </div>

          {/* Compensation Section */}
          <div className="info-section mb-4">
            <h2>Compensation</h2>
            <div className="info-content">
              <img
                src={Compensationimg}
                alt="compensation"
                className="info-icon"
              />
              <p>Flat fee and Product • {CampaignFormCreate?.budget}</p>
            </div>
          </div>

          {/* Deliverables Section */}
          <div className="info-section mb-4">
            <h2>Expected Deliverables</h2>
            <div className="info-content">
              <img
                src={ExpectedDeliverablesimg}
                alt="deliverables"
                className="info-icon"
              />
              <p>
                2 Posts & 1 Reel • From {startDate} to {endDate}
              </p>
            </div>
          </div>

          {/* Target Audience Section */}
          <div className="info-section mb-4">
            <h2>Target Audience</h2>
            <div className="info-content">
              <img src={TargetAudienceimg} alt="target" className="info-icon" />
              <div className="audience-info">
                <p>
                  {selectedAge} Years old • {CampaignFormCreate?.gender} •{" "}
                  {CampaignFormCreate?.city},{CampaignFormCreate?.state}
                </p>
                <div className="tags">
                  <span className="tag">En</span>
                  <span className="tag">tag</span>
                </div>
              </div>
            </div>
          </div>

          {/* Auto-publish Section */}
          <div className="publish-section">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="autoPublish"
              />
              <label className="form-check-label" htmlFor="autoPublish">
                Automatically Publish on Schedule Date
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetails;
