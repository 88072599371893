import React, { useEffect, useState } from "react";
import "./SendPayment.scss";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { getInfluencerBankDetails } from "../../actions/influencersAction";
import Nextarrow from "../assets/Nextarrow.svg";
import { sendPaymentToInfluencer } from "../../actions/brandAction";

function SendPayment({ open, onClose, request }) {
  const dispatch = useDispatch();

  const { bankDetails } = useSelector((state) => state.influencer || {});

  const {isPaymentSent}=useSelector((state)=>state.payment || false);

  useEffect(() => {
    if(isPaymentSent){
      onClose();
    }
  }, [isPaymentSent]);

  const [sendPaymentDetails, setsendPaymentDetails] = useState({
    payment_ss: "",
    request_id: request?.request_id,
  });

  useEffect(() => {
    dispatch(getInfluencerBankDetails(request?.influencer_id));
  }, [request]);

  const handleInputChange = (field, value) => {
    setsendPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSendPayment = () => {
    console.log("payment detials is : ",sendPaymentDetails);
    dispatch(sendPaymentToInfluencer(sendPaymentDetails));
  };
  return (
   <>
    <Dialog open={open} onClose={onClose} maxWidth="95%" maxHeight="95%">
      <DialogContent>
        <div className="sendPayment-popup">
          <div className="sendPayment-popup-header">
            <span>Bank Details</span>
          </div>
          <div className="sendPayment-container">
            <div className="accout-holder-name">
              <div>
                <span className="label">Account holder name</span>
                <br />
                <span className="value">
                  {bankDetails?.account_holder_name}
                </span>
              </div>
              <div>
                <span className="label">Bank name</span>
                <br />
                <span className="value">{bankDetails?.bank_name}</span>
              </div>
            </div>
            <div className="accout-holder-number">
              <div>
                <span className="label">Account Number</span>
                <br />
                <span className="value">{bankDetails?.account_no}</span>
              </div>
              <div>
                <span className="label">IFSC code (India)</span>
                <br />
                <span className="value">{bankDetails?.ifsc_code}</span>
              </div>
            </div>
          </div>
          <div className="payment-proof">
            <span className="title">Payment Proof</span>
            <div className="file-input">
              <label htmlFor="proof">
                {" "}
                Transaction Receipt or Screenshot of the Sent Payment{" "}
              </label>

              <div className="image-input-box">
                <input
                  type="file"
                  id="proof"
                  accept=".jpg, .png"
                  onChange={(e) =>
                    handleInputChange("payment_ss", e.target.files[0])
                  }
                />

                <span>
                  Drag & Drop or <span className="browse">Browse</span>
                </span>
                <small>
                  Supported files: <span className="jpg-png">JPG, PNG</span>
                </small>
                <small>File size should not exceed 1MB</small>
              </div>
            </div>
          </div>
          <div className={`btn ${!sendPaymentDetails.payment_ss && "disabled"}`}>
            <button
              className="edit-btn"
              onClick={() => handleSendPayment()}
              disabled={!sendPaymentDetails.payment_ss}
            >
              Send
              <img src={Nextarrow} alt="Nextarrow Icon" />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
   </>
  );
}

export default SendPayment;
