import React, { useEffect, useState } from "react";
import "./Verification.scss";
import { useDispatch, useSelector } from "react-redux";
import { setUpInfluencerBankDetails } from "../../actions/influencersAction";
import { useNavigate } from "react-router-dom";
import { SETUP_INFLUENCER_BANK_DETAILS_RESET } from "../../constants/influencersConstants";
import { loadUser } from "../../actions/userAction";

const BankDetails = () => {
  const [isAccountNumberMatch, setIsAccountNumberMatch] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user || {});
  const { isUpdated } = useSelector((state) => state.influencer);

  const [bankDetails, setBankDetails] = useState({
    account_holder_name: user?.account_holder_name || "",
    bank_name: user?.bank_name || "",
    account_no: user?.account_no || "",
    confirmAccountNumber: "",
    ifsc_code: user?.ifsc_code || "",
    branch_name: user?.branch_name || "",
  });

  const [upiDetails, setUpiDetails] = useState({ upi_id: user?.upi_id||"" });

  const handleInputChange = (field, value) => {
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (isUpdated) {
      navigate("/payment");
      dispatch({ type: SETUP_INFLUENCER_BANK_DETAILS_RESET });
    }
  }, [isUpdated, dispatch, navigate]);

  const handleConfirmAccountNumberChange = (event) => {
    const confirmNumber = event.target.value;
    handleInputChange("confirmAccountNumber", confirmNumber);
    setIsAccountNumberMatch(confirmNumber === bankDetails.account_no);
  };

  const handleSaveClick = () => {
    console.log(upiDetails);
    if(user?.upi_id){
      dispatch(setUpInfluencerBankDetails(upiDetails));
      dispatch(loadUser());
    }
    else
    {
      dispatch(setUpInfluencerBankDetails(bankDetails));
    }
  };

  return (
    <div className="verification-container">
      {user?.upi_id ? (
        <>
          <h2>UPI Handle</h2>
          <div className="upi-box">
            <input
              type="text"
              placeholder="Enter UPI Handle"
              value={upiDetails.upi_id}
              onChange={(e) =>
                setUpiDetails({ ...upiDetails, upi_id: e.target.value })
              }
            />
            <button className="verify">Verify</button>
          </div>
        </>
      ) : (
        <>
          <h2>Bank Details</h2>
          <div className="labels">
            <div className="input-container">
              <label htmlFor="accountHolder">Account Holder Name</label>
              <input
                type="text"
                id="accountHolder"
                value={bankDetails.account_holder_name}
                placeholder="Enter account holder name"
                onChange={(event) =>
                  handleInputChange("account_holder_name", event.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                id="bankName"
                placeholder="Enter bank name"
                value={bankDetails.bank_name}
                onChange={(event) =>
                  handleInputChange("bank_name", event.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                type="text"
                id="accountNumber"
                placeholder="Enter account number"
                value={bankDetails.account_no}
                onChange={(event) =>
                  handleInputChange("account_no", event.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label htmlFor="confirmAccountNumber">Confirm Account Number</label>
              <input
                type="text"
                id="confirmAccountNumber"
                placeholder="Confirm account number"
                value={bankDetails.confirmAccountNumber}
                onChange={handleConfirmAccountNumberChange}
                className={isAccountNumberMatch ? "" : "error"}
              />
              {!isAccountNumberMatch && (
                <p className="error-message">Account numbers do not match</p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="ifscCode">
                IFSC Code<span>(India)</span>
              </label>
              <input
                type="text"
                id="ifscCode"
                placeholder="Enter IFSC code"
                value={bankDetails.ifsc_code}
                onChange={(event) =>
                  handleInputChange("ifsc_code", event.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label htmlFor="branch">
                Branch Name<span>(Optional)</span>
              </label>
              <input
                type="text"
                id="branch"
                placeholder="Enter branch name"
                onChange={(event) =>
                  handleInputChange("branch_name", event.target.value)
                }
              />
            </div>
          </div>
        </>
      )}
      <div className="action-buttons">
            <button className="save" onClick={handleSaveClick}>
              Save
            </button>
          </div>
    </div>
  );
};

export default BankDetails;
