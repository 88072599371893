import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

function Sales() {
  const [response, setResponse] = useState(false); // State to hold response
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleButtonClick = () => {
    // Make a GET request to the provided URL
    window.location.href = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=352288047403158&redirect_uri=http://localhost:5000/outbound_api/instagram-business/callback&scope=pages_show_list,business_management,instagram_basic,instagram_manage_insights,public_profile&response_type=code&state=BmnGekamEwA1CTyarYH1710153837793';
  };

  console.log("response vakue ius",response)

  // Function to handle response and navigate
  const handleResponse = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    
    console.log("urlParams value is",urlParams)
    console.log("status value is",status)

    if (status === 'true') {
      setResponse(true);
    }
  };

  // Call handleResponse when component mounts
  React.useEffect(() => {
    handleResponse();
  }, []);

  // Call handleResponse when response changes
  React.useEffect(() => {
    if(response) {
      navigate('/home'); // Navigate to /home if response is true
    }
  }, [response]);

  return (
    <div>
      <div>Sales</div>
      <button onClick={handleButtonClick}>Authenticate with Facebook</button>
    </div>
  );
}

export default Sales;
