import axios from "axios";

// Get the base URL from the .env file
const baseURL = process.env.REACT_APP_BACKEND_URL;

console.log("Base URL:", baseURL); // Debug to ensure the base URL is read correctly

// Retrieve the token from localStorage
const token = localStorage.getItem("authToken");

// Create an Axios instance
const axiosMain = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
  withCredentials: true, // Allow cookies to be sent in requests
});

export default axiosMain;
